import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import DieticianOrders from "../Dieticians/DieticiansOrders";

const PhysiotherapistOrders = () => {
  return (
    <>
      <DieticianOrders partner="Physiotherapist" />
    </>
  );
};
export default PhysiotherapistOrders;
