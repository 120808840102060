import React, { useCallback, useEffect, useState } from "react";
import {
  Grid,
  Card,
  Typography,
  IconButton,
  CircularProgress,
  TextField,
  Button,
} from "@mui/material";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import BiotechIcon from "@mui/icons-material/Biotech";
import Spinner from "../../shared/Spinner";
import debounce from "lodash.debounce";

const PathologyLabs = ({ selectedItem, setSelectedLab, setDefaultTab }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [labs, setLabs] = useState([]); // Stores all labs
  const [tests, setTests] = useState([]); // Stores searched tests
  const [loading, setLoading] = useState(false);
  const [cartLoading, setCartLoading] = useState(null);

  // Fetch all labs initially
  const fetchLabs = async () => {
    setLoading(true);
    try {
      const response = await AxiosInstance.get(`/test-labs/get-medical-labs`);
      setLabs(response || []);
    } catch (err) {
      console.error("Error fetching labs:", err);
      setLabs([]);
    } finally {
      setLoading(false);
    }
  };

  // Fetch tests based on search input
  const fetchTests = async (query) => {
    if (query.length < 3) return;

    setLoading(true);
    try {
      const response = await AxiosInstance.get(`/test-labs/get-medical-test`, {
        params: { search: query },
      });
      setTests(response || []);
    } catch (err) {
      console.error("Error fetching tests:", err);
      setTests([]);
    } finally {
      setLoading(false);
    }
  };

  // Debounced search function
  const debouncedFetchTests = useCallback(debounce(fetchTests, 500), []);

  // Load all labs on mount
  useEffect(() => {
    fetchLabs();
  }, []);

  // Fetch tests when user types
  useEffect(() => {
    if (searchQuery.length >= 3) {
      debouncedFetchTests(searchQuery);
    } else {
      setTests([]); // Clear tests when search is empty
    }
  }, [searchQuery]);

  // Function to add a test to the cart
  const handleAddToCart = async (labId, testId, isPreventiveTest, labName) => {
    setCartLoading(testId); // Show loader only for the clicked button

    try {
      const response = await AxiosInstance.post(
        `test-labs/add-to-cart-test/${labId}/${testId}/${isPreventiveTest}`
      );
      console.log("Added to cart:", response); // You can replace this with a better UI feedback
    } catch (err) {
      console.error("Error adding to cart:", err);
    } finally {
      setCartLoading(null); // Hide loader after API call
      setSelectedLab(labName);
      setDefaultTab(1);
    }
  };

  // Group tests by labName
  const groupedTests = tests.reduce((acc, test) => {
    if (!acc[test.labName]) acc[test.labName] = [];
    acc[test.labName].push(test);
    return acc;
  }, {});

  const labColors = ["orange", "green", "rgb(227, 242, 253)"];
  
  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <TextField
            label="Search Tests"
            variant="outlined"
            fullWidth
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{ marginBottom: "20px" }}
          />
          {searchQuery.length >= 3 ? (
            // Show test results if searching
            tests.length > 0 ? (
              <>
                {Object.keys(groupedTests).map((lab, index) => (
                  <Card
                    key={lab}
                    style={{
                      // backgroundColor: labColors[index % labColors.length],
                      padding: "15px",
                      marginBottom: "20px",
                      border: `1px solid ${
                        labColors[index % labColors.length]
                      }`,
                      width: "100%",
                    }}
                  >
                    <Typography
                      variant="h5"
                      style={{
                        fontWeight: "bold",
                        color: labColors[index % labColors.length],
                      }}
                    >
                      {lab}
                    </Typography>
                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                      {groupedTests[lab].map((test) => (
                        <Grid item xs={12} sm={6} md={4} key={test.testId}>
                          <Card
                            style={{
                              padding: "16px",
                              borderRadius: "8px",
                              boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              textAlign: "center",
                              border: `1px solid ${
                                labColors[index % labColors.length]
                              }`,
                            }}
                          >
                            <Typography variant="h6">{test.labName}</Typography>
                            <Typography
                              variant="body1"
                              style={{ marginTop: "8px", fontWeight: "bold" }}
                            >
                              {test.testName}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              style={{ marginTop: "4px" }}
                            >
                              ₹ {test.testPrice}
                            </Typography>
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              style={{ marginTop: "12px", minWidth: "120px" }}
                              onClick={() =>
                                handleAddToCart(
                                  test.labId,
                                  test.testId,
                                  false,
                                  test.labName
                                )
                              }
                              disabled={cartLoading === test.testId}
                            >
                              {cartLoading === test.testId ? (
                                <CircularProgress size={20} color="inherit" />
                              ) : (
                                "Add to Cart"
                              )}
                            </Button>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  </Card>
                ))}
              </>
            ) : (
              <Typography
                align="center"
                variant="h6"
                style={{
                  marginTop: "20px",
                  fontSize: "18px",
                  color: "gray",
                  width: "100%",
                }}
              >
                No tests found.
              </Typography>
            )
          ) : // Show all labs if no search query
          labs.length > 0 ? (
            <>
              <Typography
                align="start"
                variant="subtitle1"
                style={{
                  marginBottom: "20px",
                  width: "100%",
                }}
              >
                <b>Note:</b> We have {labs.length} partner labs who can provide
                you with a home collection service in your area. Please see
                below and book with your preferred lab.
              </Typography>
              <Grid container spacing={2}>
                {labs.map((lab, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setSelectedLab(lab.labName);
                        setDefaultTab(0);
                      }}
                    >
                      <IconButton>
                        <BiotechIcon />
                      </IconButton>
                      <Typography variant="h6" style={{ marginLeft: "10px" }}>
                        {lab.labName}
                      </Typography>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </>
          ) : (
            <Typography
              align="center"
              variant="h6"
              style={{
                marginTop: "20px",
                fontSize: "18px",
                color: "gray",
                width: "100%",
              }}
            >
              No Lab found in your Pincode Location.
            </Typography>
          )}
        </>
      )}
    </>
  );
};

export default PathologyLabs;
