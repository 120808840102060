import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import Spinner from "../../shared/Spinner";
import { MdCheckCircle } from "react-icons/md";

const Calendar = ({ selectedOrder, onSubmitProposal, partnerType }) => {
  const today = dayjs();
  const [selectedDate, setSelectedDate] = useState(today);
  const [selectedTime, setSelectedTime] = useState("");
  const [slots, setSlots] = useState([]);
  const [loading, seLoading] = useState(false);
  const [selectedSessionId, setSelectedSessionId] = useState(null);
  const [proposalBooked, setProposalBooked] = useState(false);
  const [proposalloading, setproposalLoading] = useState(false);
  useEffect(() => {
    if (selectedOrder) {
      seLoading(true);
      AxiosInstance.get(
        `consultation/get-consultant-session/${selectedOrder.dietitianId}`
      )
        .then((response) => {
          console.log("API Response:", response);
          if (response != null && response) {
            setSlots(response);
          } else {
            setSlots([]);
          }
          seLoading(false);
        })
        .catch((err) => {
          seLoading(false);
          console.error("Error fetching sessions:", err);
        });
    }
  }, [selectedOrder]);
  const handleDateChange = (direction) => {
    if (direction === "next") {
      setSelectedDate(selectedDate.add(1, "day"));
    } else if (direction === "prev" && !selectedDate.isSame(today, "day")) {
      setSelectedDate(selectedDate.subtract(1, "day"));
    }
  };

  const handleTimeSelect = (slot) => {
    setSelectedTime(`${slot.startTime} - ${slot.endTime}`);
    setSelectedSessionId(slot.id); // Save the session ID
  };

  // Filter slots by selected date and time
  const filteredSlots = slots.filter((slot) => {
    const slotDate = dayjs(slot.scheduleDate);
    const slotStartTime = dayjs(`${slot.scheduleDate}T${slot.startTime}`);
    const now = dayjs();

    // Only show slots for the selected date and time that are in the future
    return (
      slotDate.isSame(selectedDate, "day") &&
      slotStartTime.isAfter(now) &&
      slot.status === "Available"
    );
  });

  const handleProposalAppointment = () => {
    setproposalLoading(true);
    AxiosInstance.post(
      `consultation/proposal-slot-book/${selectedOrder.orderId}/${selectedSessionId}`
    )
      .then((response) => {
        setproposalLoading(false);
        setProposalBooked(true);
        onSubmitProposal();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  if (proposalBooked) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
        mt={3}
      >
        <div>
          <div>
            <MdCheckCircle size={50} color="green" />
          </div>
          {/* <Typography variant="h6" sx={{ color: "green" }}>
            You have successfully submitted your answers for the Questionnaire
            to the Nutritionist.
          </Typography> */}
          <Typography variant="h5" color="success.main" fontWeight="bold">
            Your proposal Call is booked successfully!
          </Typography>
        </div>
      </Box>
    );
  }
  return (
    <div>
      {selectedOrder ? (
        loading ? (
          <Spinner />
        ) : (
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box
              width="100%"
              textAlign="center"
              bgcolor="#f3f4f6"
              p={2}
              borderRadius="8px"
              mb={3}
            >
              <Typography
                variant="h5"
                fontWeight="bold"
                color="#1976d2"
                gutterBottom
              >
                {selectedOrder.dietitianName} Calendar
              </Typography>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              mb={3}
            >
              <IconButton
                onClick={() => handleDateChange("prev")}
                disabled={selectedDate.isSame(today, "day")}
              >
                <ArrowBackIosIcon />
              </IconButton>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={selectedDate}
                  minDate={today}
                  onChange={(newDate) => setSelectedDate(newDate)}
                  format="DD/MM/YYYY"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
              <IconButton onClick={() => handleDateChange("next")}>
                <ArrowForwardIosIcon />
              </IconButton>
            </Box>
            {/* Time Slot Selection */}
            {filteredSlots.length > 0 && (
              <Typography variant="h6" color="textSecondary" className="mb-3">
                Available Time Slots
              </Typography>
            )}
            {filteredSlots.length > 0 ? (
              <Grid container spacing={1} justifyContent="center" mb={3}>
                {filteredSlots.map((slot) => (
                  <Grid item key={slot.id}>
                    <Button
                      variant={
                        selectedTime === `${slot.startTime} - ${slot.endTime}`
                          ? "contained"
                          : "outlined"
                      }
                      color={
                        selectedTime === `${slot.startTime} - ${slot.endTime}`
                          ? "primary"
                          : "default"
                      }
                      onClick={() => handleTimeSelect(slot)}
                    >
                      {dayjs(slot.startTime, "HH:mm").format("hh:mm A")}
                      {/* -{" "}
                      {dayjs(slot.endTime, "HH:mm").format("hh:mm A")} */}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Box mt={3} textAlign="center">
                <Typography variant="h6" color="error" fontWeight="bold">
                  No sessions available for{" "}
                  {selectedDate.format("dddd, DD/MM/YYYY")}
                </Typography>
              </Box>
            )}
            {/* Book Appointment Button */}
            {selectedTime && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleProposalAppointment}
                disabled={proposalloading}
              >
                {proposalloading ? "Booking..." : "Book Appointment"}
              </Button>
            )}
          </Box>
        )
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box
            width="100%"
            textAlign="center"
            bgcolor="#f3f4f6"
            p={2}
            borderRadius="8px"
            mb={3}
          >
            <Typography
              variant="h5"
              fontWeight="bold"
              color="#1976d2"
              gutterBottom
            >
              This Calendar is for booking particular {partnerType} proposal
              call.
            </Typography>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default Calendar;
