import React, { useState, useEffect } from "react";
import DieticianOnlineConsultation from "../Dieticians/DieticianOnlineConsultation";

const PhysiotherapistOnlineConsultation = () => {
  return (
    <>
      <DieticianOnlineConsultation partner="Physiotherapist" />
    </>
  );
};

export default PhysiotherapistOnlineConsultation;
