import React, { useEffect, useState } from "react";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  CardText,
} from "reactstrap";

const LabScreen = () => {
  return (
    <>
      <h2 style={{ textAlign: "center", marginBottom: "10px" }}>
        Welcome Lab Brand Partner
      </h2>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <Card
          style={{
            width: "40rem",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          }}
        >
          <img src="/img/lab2.jpeg" alt="Lab" />
        </Card>
        <Card
          style={{
            width: "40rem",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          }}
        >
          <img src="/img/lab3.jpeg" alt="Lab" />
        </Card>
      </div>
    </>
  );
};
export default LabScreen;
