import React from "react";
import { Card } from "reactstrap";

const PsychologistHome = () => {
  return (
    <>
      <h2 style={{ textAlign: "center", marginBottom: "10px" }}>
        Welcome Psychologist Brand Partner
      </h2>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <Card
          style={{
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            border: "1px solid black",
            minHeight: "35rem",
          }}
        >
          <img
            src="/img/Psychologist1.jpg"
            alt="Lab"
            style={{ width: "30rem", padding: "10px", height: "100%" }}
          />
        </Card>
        <Card
          style={{
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            border: "1px solid black",
          }}
        >
          <img
            src="/img/Psychiologist2.jpg"
            alt="Lab"
            style={{ width: "30rem", padding: "10px", height: "100%" }}
          />
        </Card>
      </div>
    </>
  );
};
export default PsychologistHome;
