import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import Spinner from "../../shared/Spinner";
import { Phone } from "@mui/icons-material";
import PersonIcon from "@mui/icons-material/Person";
import OutgoingCall from "./OutgoingCall.mp3";
import {
  ConsoleLogger,
  DefaultDeviceController,
  DefaultMeetingSession,
  LogLevel,
  MeetingSessionConfiguration,
  MeetingSessionStatusCode,
} from "amazon-chime-sdk-js";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import { createMeetingAndAttendee } from "./ChimeMetting/api";

const logger = new ConsoleLogger("lsh_chime_logger", LogLevel.INFO);
const deviceController = new DefaultDeviceController(logger);

const PatientOrders = ({ onReviewClick }) => {
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [callModalOpen, setCallModalOpen] = useState(false);
  const [audioInstance, setAudioInstance] = useState(null);
  const [currentPage, setCurrentPage] = useState(0); // Current page index
  const [openModal, setOpenModal] = useState(false);
  const [scheduledTime, setScheduledTime] = useState("");
  const rowsPerPage = 10; // Number of rows per page

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // Function to handle page change
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  // Slicing data to display only the records for the current page
  const paginatedOrders = orders.slice(
    currentPage * rowsPerPage,
    currentPage * rowsPerPage + rowsPerPage
  );

  useEffect(() => {
    const fetchOrders = () => {
      const userDetails = sessionStorage.getItem("userDetails");
      if (!userDetails) return; // Handle missing userDetails

      const parsedData = JSON.parse(userDetails);
      const userType = parsedData?.userType;

      let doctorOrders = [];
      let dietitianOrders = [];

      setIsLoading(true);

      // Fetch doctor orders
      AxiosInstance.get(`doctor/orderDetails/${userType}`)
        .then((response) => {
          if (response) {
            doctorOrders = response;
          }
        })
        .catch((err) => {
          console.log("Error fetching doctor orders: ", err);
        })
        .finally(() => {
          // Fetch dietitian orders after doctor orders
          AxiosInstance.get(`consultation/orderDetails/${userType}`)
            .then((response) => {
              if (response) {
                dietitianOrders = response;
              }
            })
            .catch((err) => {
              console.log("Error fetching dietitian orders: ", err);
            })
            .finally(() => {
              // Combine the two sets of orders
              const combinedOrders = [...doctorOrders, ...dietitianOrders];
              setOrders(combinedOrders);
              setIsLoading(false);
            });
        });
    };

    fetchOrders();
  }, []);
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(":");
    const hourInt = parseInt(hours, 10);
    const period = hourInt >= 12 ? "PM" : "AM";
    const formattedHour = hourInt % 12 || 12; // Convert 0 hour to 12
    return `${formattedHour}:${minutes} ${period}`;
  };

  // const openCallModal = () => {
  //   const audio = new Audio(OutgoingCall);
  //   audio.loop = true; // Keep looping while the modal is open
  //   audio.play().catch((err) => console.error("Audio playback failed:", err));

  //   // Store the Audio instance in state
  //   setAudioInstance(audio);

  //   setCallModalOpen(true);
  // };

  const handleCallCloseModal = () => {
    if (audioInstance) {
      audioInstance.pause();
      audioInstance.currentTime = 0; // Reset playback to the start
    }
    setCallModalOpen(false);
  };

  const isWithinAppointmentTime = (
    appointmentDate,
    appointmentTimeStartTime,
    appointmentTimeEndTime
  ) => {
    const currentDate = new Date();
    const startDateTime = new Date(
      `${appointmentDate}T${appointmentTimeStartTime}`
    );
    const endDateTime = new Date(
      `${appointmentDate}T${appointmentTimeEndTime}`
    );
    // Subtract 30 minutes from startDateTime
    const thirtyMinutesBeforeStart = new Date(
      startDateTime.getTime() - 30 * 60 * 1000
    );
    return (
      currentDate >= thirtyMinutesBeforeStart && currentDate <= endDateTime
    );
  };

  // join and start the meeting
  const joinMeeting = async (order) => {
    console.log("meeting ", order);

    const orderString = encodeURIComponent(JSON.stringify(order));

    const userType = "Patient";

    // Create the meeting URL with both order and userType
    const meetingUrl = `/meeting?order=${orderString}&userType=${encodeURIComponent(
      userType
    )}`;

    // Open the meeting URL in a new tab
    window.open(meetingUrl, "_blank");
  };
  const handlePhoneClick = (order) => {
    // const currentDate = new Date();
    // const startDateTime = new Date(
    //   `${order.appointmentDate}T${order.appointmentTimeStartTime}`
    // );
    // const thirtyMinutesBeforeStart = new Date(
    //   startDateTime.getTime() - 30 * 60 * 1000
    // );

    // if (currentDate < thirtyMinutesBeforeStart) {
    //   setScheduledTime(order.appointmentTimeStartTime);
    //   setOpenModal(true); // Open the modal instead of alert
    // } else {
    //   joinMeeting(order);
    // }
    const currentDate = new Date();
    const startDateTime = new Date(
      `${order.appointmentDate}T${order.appointmentTimeStartTime}`
    );
    const endDateTime = new Date(
      `${order.appointmentDate}T${order.appointmentTimeEndTime}`
    );
    const thirtyMinutesBeforeStart = new Date(
      startDateTime.getTime() - 30 * 60 * 1000
    );

    // If user clicks between 30 min before start time and start time -> Show Modal
    if (
      currentDate >= thirtyMinutesBeforeStart &&
      currentDate < startDateTime
    ) {
      setScheduledTime(order.appointmentTimeStartTime);
      setOpenModal(true);
    }
    // If user clicks at the right time (Start Time - End Time) -> Join Meeting
    else if (currentDate >= startDateTime && currentDate <= endDateTime) {
      joinMeeting(order);
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3 style={{ margin: 0 }}>My Orders</h3>
        {/* <div
    style={{
      border: "1px solid lightgrey",
      padding: "15px",
      display: "flex",
      alignItems: "center",
      width: "25%",
    }}
  >
    <b>Date :</b>
    <span style={{ marginLeft: "10px" }}>
      <input
        type="date"
        className="form-control"
        id="dateInput"
        value={selectedDate}
        onChange={handleDateChange}
      />
    </span>
  </div> */}
      </div>
      <p style={{ marginTop: "1%" }}>
        <b>This screen will show you your orders from Uncurl: Health</b>
      </p>
      {isLoading ? (
        <Spinner />
      ) : orders?.length > 0 ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: "2%",
              gap: 2,
              flexWrap: "wrap",
              rowGap: 0,
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "bold", color: "#3f51b5", lineHeight: 1.5 }}
            >
              QR = Questions Raised
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", color: "#3f51b5", lineHeight: 1.5 }}
            >
              RR = Response Received
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", color: "#3f51b5", lineHeight: 1.5 }}
            >
              QRS = Questionnaire Response Submitted
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", color: "#3f51b5", lineHeight: 1.5 }}
            >
              P&P = Protocol and Proposal
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", color: "#3f51b5", lineHeight: 1.5 }}
            >
              PCB = Proposal Call Booked
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", color: "#3f51b5", lineHeight: 1.5 }}
            >
              PQR = Protocol Questions Raised
            </Typography>
          </Box>
          <TableContainer style={{ border: "1px solid black" }}>
            <Table sx={{ whiteSpace: "nowrap" }}>
              <TableRow>
                <TableCell
                  style={{
                    backgroundColor: " rgb(83, 83, 233)",
                    color: "white",
                    border: "1px solid lightgrey",
                    fontSize: "18px",
                    textAlign: "center",
                  }}
                >
                  <p style={{ fontSize: "18px" }}>
                    <b>LSH Order ID </b>
                  </p>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: " rgb(83, 83, 233)",
                    color: "white",
                    border: "1px solid lightgrey",
                    fontSize: "18px",
                    textAlign: "center",
                  }}
                >
                  <p style={{ fontSize: "18px" }}>
                    <b>Date</b>
                  </p>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "rgb(83, 83, 233)",
                    color: "white",
                    border: "1px solid lightgrey",
                    fontSize: "18px",
                    textAlign: "center",
                  }}
                >
                  <p style={{ fontSize: "18px" }}>
                    <b>LRID</b>
                  </p>
                </TableCell>

                <TableCell
                  style={{
                    backgroundColor: "rgb(83, 83, 233)",
                    color: "white",
                    border: "1px solid lightgrey",
                    fontSize: "18px",
                    textAlign: "center",
                  }}
                >
                  <p style={{ fontSize: "18px" }}>
                    <b>Service Type</b>
                  </p>
                </TableCell>

                <TableCell
                  style={{
                    backgroundColor: "rgb(83, 83, 233)",
                    color: "white",
                    border: "1px solid lightgrey",
                    fontSize: "18px",
                    textAlign: "center",
                  }}
                >
                  <p style={{ fontSize: "18px" }}>
                    <b>Status</b>
                  </p>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "rgb(83, 83, 233)",
                    color: "white",
                    border: "1px solid lightgrey",
                    fontSize: "18px",
                    textAlign: "center",
                  }}
                >
                  <p style={{ fontSize: "18px" }}>
                    <b>Appointment Date</b>
                  </p>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "rgb(83, 83, 233)",
                    color: "white",
                    border: "1px solid lightgrey",
                    fontSize: "18px",
                    textAlign: "center",
                  }}
                >
                  <p style={{ fontSize: "18px" }}>
                    <b>Appointment Time</b>
                  </p>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "rgb(83, 83, 233)",
                    color: "white",
                    border: "1px solid lightgrey",
                    fontSize: "18px",
                    textAlign: "center",
                  }}
                >
                  <p style={{ fontSize: "18px" }}>
                    <b>Call</b>
                  </p>
                </TableCell>
              </TableRow>
              <TableBody>
                {paginatedOrders?.map((orders, index) => (
                  <TableRow
                    key={index}
                    // onClick={() => handleRowClick(orders, index)}
                    // style={{
                    //   cursor: "pointer",
                    //   backgroundColor:
                    //     clickedRowIndex === index ? "red" : "whitesmoke",
                    //   transition: "background-color 0.3s ease",
                    // }}
                    // onMouseEnter={(e) => {
                    //   if (clickedRowIndex !== index)
                    //     e.currentTarget.style.backgroundColor = "red";
                    // }}
                    // onMouseLeave={(e) => {
                    //   if (clickedRowIndex !== index)
                    //     e.currentTarget.style.backgroundColor = "whitesmoke";
                    // }}
                  >
                    <TableCell
                      style={{
                        fontWeight: 600,
                        border: "1px solid lightgrey",
                        fontSize: "18px",
                        textAlign: "center",
                        padding: "8px",
                      }}
                    >
                      {orders.orderId}
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: 600,
                        border: "1px solid lightgrey",
                        fontSize: "18px",
                        textAlign: "center",
                        padding: "8px",
                      }}
                    >
                      {orders.createdOn ? formatDate(orders.createdOn) : ""}
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: 600,
                        border: "1px solid lightgrey",
                        fontSize: "18px",
                        textAlign: "center",
                        padding: "8px",
                      }}
                    >
                      {orders.preventiveTestReportView?.lrId || "N/A"}
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: 600,
                        border: "1px solid lightgrey",
                        fontSize: "18px",
                        textAlign: "center",
                        padding: "8px",
                      }}
                    >
                      {orders.serviceType}
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: 600,
                        border: "1px solid lightgrey",
                        fontSize: "18px",
                        textAlign: "center",
                        padding: "8px",
                      }}
                    >
                      {orders.statusForPatient === "Received" ||
                      orders.statusForPatient === "RR" ||
                      orders.statusForPatient === "Questionnaire Received" ||
                      orders.statusForPatient === "Protocol Received" ||
                      orders.statusForPatient === "P&P Received" ||
                      orders.statusForPatient === "Diet Plan Received" ||
                      orders.statusForPatient === "PRR" ? (
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Button
                            variant="contained"
                            style={{
                              boxShadow: "0px 0px 10px 0px red",
                            }}
                            className="doctor-review-button"
                            onMouseEnter={(e) => {
                              e.currentTarget.style.boxShadow =
                                "0px 0px 20px 5px red";
                            }}
                            onMouseLeave={(e) => {
                              e.currentTarget.style.boxShadow =
                                "0px 0px 10px 0px red";
                            }}
                            onClick={() => onReviewClick(orders)}
                          >
                            {orders.statusForPatient}
                          </Button>
                          {/* <Phone
                            style={{
                              marginLeft: "10px",
                              cursor: "pointer",
                              color: "blue",
                            }}
                            onClick={openCallModal}
                          /> */}
                        </Box>
                      ) : orders.statusForPatient === "Appointment Booked" ||
                        orders.statusForPatient === "Appointment Confirmed " ||
                        orders.statusForPatient === "New Appointment" ||
                        orders.statusForPatient === "PCB" ? (
                        <>
                          {orders.statusForPatient}
                          {/* (
                          {formatDate(orders.appointmentDate)} /
                          {orders.appointmentTimeStartTime} -
                          {orders.appointmentTimeEndTime}) */}
                          {/* {orders.statusForPatient ===
                            "Appointment Confirmed " && (
                            <>
                              <Phone
                                style={{
                                  marginLeft: "10px",
                                  cursor: "pointer",
                                  color: "blue",
                                }}
                                onClick={() => joinMeeting(orders)}
                              />
                            </>
                          )} */}
                        </>
                      ) : (
                        orders.statusForPatient
                      )}
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: 600,
                        border: "1px solid lightgrey",
                        fontSize: "18px",
                        textAlign: "center",
                        padding: "8px",
                      }}
                    >
                      {formatDate(orders.appointmentDate)}
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: 600,
                        border: "1px solid lightgrey",
                        fontSize: "18px",
                        textAlign: "center",
                        padding: "8px",
                      }}
                    >
                      {orders.appointmentTimeStartTime} -
                      {orders.appointmentTimeEndTime}
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: 600,
                        border: "1px solid lightgrey",
                        fontSize: "18px",
                        textAlign: "center",
                        padding: "8px",
                      }}
                    >
                      {(orders.statusForPatient === "Appointment Confirmed " ||
                        orders.statusForPatient === "PCB" ||
                        orders.statusForPatient === "QRS") &&
                        isWithinAppointmentTime(
                          orders.appointmentDate,
                          orders.appointmentTimeStartTime,
                          orders.appointmentTimeEndTime
                        ) && (
                          <>
                            <PhoneInTalkIcon
                              sx={{
                                fontSize: "30px",
                                color: "#4caf50", // Green for accepting calls
                                animation: "pulse 1.5s infinite",
                              }}
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => handlePhoneClick(orders)}
                            />
                            {/* <Phone /> */}
                          </>
                        )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5]}
            component="div"
            count={orders.length}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onPageChange={handleChangePage}
          />
          <Modal open={callModalOpen} onClose={handleCallCloseModal}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                padding: "30px",
                backgroundColor: "#fff",
                borderRadius: "15px",
                boxShadow: 24,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                width: "50%", // adjust as needed
              }}
            >
              <PersonIcon
                sx={{
                  fontSize: "40px",
                  color: "#4caf50", // Green icon to symbolize health/diet
                  marginBottom: "15px",
                }}
              />
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", color: "#333" }}
              >
                Calling Dietitian...
              </Typography>
              <CircularProgress sx={{ marginTop: "20px", color: "#4caf50" }} />
            </Box>
          </Modal>
          {/* MUI Modal */}
          <Modal open={openModal} onClose={handleCloseModal}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                borderRadius: "10px",
                textAlign: "center",
              }}
            >
              <Typography variant="h6" sx={{ mb: 2 }}>
                Your call is scheduled at {scheduledTime}
              </Typography>
              <Typography variant="body2" sx={{ mb: 3 }}>
                Please join at the scheduled time.
              </Typography>
              <Button variant="contained" onClick={handleCloseModal}>
                Okay
              </Button>
            </Box>
          </Modal>
        </>
      ) : (
        <>
          <div style={{ marginTop: "7%", marginLeft: "35%" }}>
            <span>
              <h4 style={{ marginBottom: "2%", marginLeft: "17px" }}>
                No Orders Available!
              </h4>
              <img
                src="/img/no-order.jpeg"
                alt="Lab"
                style={{ width: "25%" }}
              />
            </span>
          </div>
        </>
      )}
    </>
  );
};

export default PatientOrders;
