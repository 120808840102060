import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import { Box, Typography } from "@mui/material";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import Spinner from "../../shared/Spinner";
import DoctorCard from "../../components/table_wrapper_component/DoctorCard";

const DoctorCareServices = () => {
  const [DoctorLoading, setDoctorLoading] = useState(false);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [DoctorData, setDoctorData] = useState([]);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("");
  const [modal, setModal] = useState(false);
  const [appointmentmodal, setappointmentmodal] = useState(false);
  const toggle = () => setModal(!modal);

  const handleDoctorSelect = (doctor, type) => {
    setSelectedDoctor(doctor); // Set the selected doctor data
    setType(type);
    // Find the corresponding price for the selected type
    const selectedPrice =
      doctor.dietitianPrices?.find((price) => price.typeOfCall === type) ||
      null;
    console.log("selectedPrice", selectedPrice);
    setSelectedPrice(selectedPrice);
    setappointmentmodal(true);
    setModal(!modal);
  };
  const bookDoctorDetail = () => {
    setModal(!modal);
    setDoctorLoading(true);
    AxiosInstance.get(`doctor/getAllDoctorsDetail`)
      .then((response) => {
        if (response) {
          setDoctorData(response);
        }
        setDoctorLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setDoctorLoading(false);
      });
  };
  useEffect(() => {
    bookDoctorDetail();
  }, []);
  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      centered
      style={{
        maxWidth: "90%", // Allows the modal to expand up to 90% of the screen width
        minWidth: "60%", // Ensures a minimum width, so it doesn't get too narrow
        width: "auto", // Automatically adjusts the width based on content
      }}
    >
      <ModalHeader
        style={{
          backgroundColor: "black",
          color: "white",
          border: "1px solid lightgrey",
        }}
        toggle={toggle}
      >
        <center>Select Doctor to Book</center>
      </ModalHeader>

      <ModalBody
        style={{
          padding: "20px",
          maxHeight: "80vh", // Adjust the height as needed
          overflowY: "auto", // Enable vertical scroll if content exceeds maxHeight
        }}
      >
        {DoctorLoading ? (
          <Spinner />
        ) : DoctorData.length > 0 ? (
          <DoctorCard
            doctorData={DoctorData}
            onDoctorSelect={handleDoctorSelect}
          ></DoctorCard>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "40vh",
              flexDirection: "column",
            }}
          >
            <Typography variant="h6" color="textSecondary" sx={{ mb: 2 }}>
              No Doctor Available.
            </Typography>
            <SentimentDissatisfiedIcon fontSize="large" color="disabled" />
          </Box>
        )}
      </ModalBody>
    </Modal>
  );
};

export default DoctorCareServices;
