import React, { useState } from "react";
import {
  Button,
  TextField,
  Box,
  Typography,
  IconButton,
  Card,
  CardContent,
  CircularProgress,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { toast } from "react-toastify";

const DietitianNotes = ({ order }) => {
  const [notes, setNotes] = useState([""]); // Initialize with one empty text field
  const [displayNotes, setDisplayNotes] = useState(order.notes || []);
  const [submitting, setSubmitting] = useState(false);

  // Convert 'YYYY-MM-DD' to 'DD/MM/YYYY'
  const formatDate = (dateString) => {
    if (!dateString) return ""; // Check if dateString is undefined or null
    const parts = dateString.split("-");
    if (parts.length !== 3) return dateString; // Ensure it's a valid date format
    return `${parts[2]}/${parts[1]}/${parts[0]}`;
  };

  // Group notes by formatted date
  const groupedNotes = displayNotes.reduce((acc, note) => {
    const formattedDate = formatDate(note.createdOn);
    if (!formattedDate) return acc; // Skip if date is invalid
    if (!acc[formattedDate]) acc[formattedDate] = [];
    acc[formattedDate].push(note);
    return acc;
  }, {});

  // // Handle deleting a note
  // const handleDeleteNote = (noteId) => {
  //   setNotes(notes.filter((note) => note.id !== noteId));
  // };

  // Handle adding a new text field
  const handleAddNote = () => {
    setNotes([...notes, ""]);
  };

  // Handle changing text in the text fields
  const handleNoteChange = (index, value) => {
    const updatedNotes = [...notes];
    updatedNotes[index] = value;
    setNotes(updatedNotes);
  };

  // Handle deleting a note
  const handleDeleteNote = (index) => {
    const updatedNotes = notes.filter((_, i) => i !== index);
    setNotes(updatedNotes);
  };

  // Handle save button click
  const handleSave = () => {
    console.log("Dietitian Notes:", notes);
    setSubmitting(true);
    // Convert notes array to query parameters
    const queryParams = new URLSearchParams();
    notes.forEach((note) => {
      queryParams.append("notes", note); // Use "notes" key for array values
    });

    // Construct the full URL with query parameters
    const url = `consultation/create-order-notes/${
      order.orderId
    }?${queryParams.toString()}`;
    AxiosInstance.post(url)
      .then((response) => {
        setSubmitting(false);
        toast.success("Your Notes Have Been Successfully Saved");
      })
      .catch((err) => {
        setSubmitting(false);
        console.log("err: ", err);
      });
  };

  return (
    <Box
      sx={{
        padding: 4,
        margin: "0 auto",
        backgroundColor: "#f9f9f9",
        borderRadius: 2,
        boxShadow: 3,
      }}
    >
      {Object.keys(groupedNotes).length > 0 && (
        <Box sx={{ margin: "0 auto" }}>
          {Object.keys(groupedNotes).map((date) => (
            <Card key={date} sx={{ marginBottom: 3, boxShadow: 3 }}>
              <CardContent>
                <Typography variant="h6" color="primary" gutterBottom>
                  Date: {date}
                </Typography>

                {groupedNotes[date].map((note) => (
                  <Box
                    key={note.id}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: 1,
                      borderBottom: "1px solid #ddd",
                    }}
                  >
                    <Typography variant="body1">{note.notes}</Typography>
                    {/* <IconButton color="error" onClick={() => handleDeleteNote(note.id)}>
                  <Delete />
                </IconButton> */}
                  </Box>
                ))}
              </CardContent>
            </Card>
          ))}
        </Box>
      )}
      <Typography variant="h6" color="text.secondary" gutterBottom>
        Today's Date: {new Date().toLocaleDateString()}
      </Typography>

      {/* {notes.map((note, index) => (
        <Box key={index} sx={{ marginBottom: 2 }}>
          <TextField
            fullWidth
            label={`Note ${index + 1}`}
            variant="outlined"
            value={note}
            onChange={(e) => handleNoteChange(index, e.target.value)}
          />
        </Box>
      ))} */}
      {notes.map((note, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            marginBottom: 2,
          }}
        >
          <TextField
            fullWidth
            label={`Note ${index + 1}`}
            variant="outlined"
            value={note}
            onChange={(e) => handleNoteChange(index, e.target.value)}
          />
          <IconButton color="error" onClick={() => handleDeleteNote(index)}>
            <Delete />
          </IconButton>
        </Box>
      ))}

      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddNote}
          sx={{ flexGrow: 1 }}
        >
          Add Note
        </Button>
        <Button
          variant="contained"
          color="success"
          onClick={handleSave}
          sx={{ flexGrow: 1 }}
          disabled={submitting}
        >
          {submitting ? <CircularProgress color="inherit" size={24} /> : "Save"}
        </Button>
      </Box>
    </Box>
  );
};

export default DietitianNotes;
