import React from "react";

const Spinner = () => {
  return (
    <div className="mySpinner">
      <img src="/img/spinner3.gif" alt="wait until the page loads" />
    </div>
  );
};

export default Spinner;
