import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Box,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { MdCheckCircle } from "react-icons/md";

const DietPlan = ({ order }) => {
  const [columns, setColumns] = useState([]);
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [DietFilemodalOpen, setDietFileModalOpen] = useState(false);
  const [newColumn, setNewColumn] = useState("");
  const [dietData, setDietData] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [reviewDate, setReviewDate] = useState("");
  const [errors, setErrors] = useState({
    fromDate: "",
    toDate: "",
    reviewDate: "",
    file: "",
  });
  const [dateErrors, setDateErrors] = useState({
    fromDate: "",
    toDate: "",
    reviewDate: "",
  });
  const [dietPlanSubmitted, setDietPlanSubmitted] = useState(false);

  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const handleAddColumn = () => {
    if (newColumn.trim()) {
      setColumns([...columns, newColumn.trim()]);
      setDietData((prev) => {
        const updatedData = { ...prev };
        daysOfWeek.forEach((day) => {
          if (!updatedData[day]) {
            updatedData[day] = {};
          }
          updatedData[day][newColumn.trim()] = "";
        });
        return updatedData;
      });
      setNewColumn("");
      setOpen(false);
    }
  };

  const handleCellChange = (day, column, value) => {
    setDietData((prev) => ({
      ...prev,
      [day]: {
        ...prev[day],
        [column]: value,
      },
    }));
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (
      file &&
      file.type !==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
      file.type !== "application/vnd.ms-excel"
    ) {
      toast.error("Only Excel files are allowed!");
      return;
    }
    setSelectedFile(file);
    setErrors((prev) => ({ ...prev, file: "" }));
  };

  const handleFileSubmit = () => {
    let newErrors = { fromDate: "", toDate: "", reviewDate: "", file: "" };
    if (!fromDate) newErrors.fromDate = "From Date is required";

    if (!toDate) {
      newErrors.toDate = "To Date is required";
    } else if (fromDate && new Date(toDate) < new Date(fromDate)) {
      newErrors.toDate = "To Date cannot be earlier than From Date";
    }

    if (!reviewDate) {
      newErrors.reviewDate = "Review Date is required";
    } else if (toDate && new Date(reviewDate) < new Date(toDate)) {
      newErrors.reviewDate = "Review Date cannot be earlier than To Date";
    }
    // if (!toDate) newErrors.toDate = "To Date is required";
    // if (!reviewDate) newErrors.reviewDate = "Review Date is required";
    if (!selectedFile) newErrors.file = "File is required";

    setErrors(newErrors);

    if (Object.values(newErrors).some((error) => error)) {
      return; // Stop execution if any error exists
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    AxiosInstance.post(
      `consultation/upload-diet-plan-file?orderId=${order.orderId}&fromDate=${fromDate}&toDate=${toDate}&reviewDate=${reviewDate}`,
      formData
    )
      .then((response) => {
        toast.success("Plan uploaded successfully!");
        setDietFileModalOpen(false);
        setReviewDate("");
        setToDate("");
        setFromDate("");
        setDietPlanSubmitted(true);
      })
      .catch((err) => {
        toast.error("Failed to upload diet plan!");
        console.log("err: ", err);
      });
  };

  const handleSubmit = () => {
    let errors = {};

    if (!fromDate) {
      errors.fromDate = "From Date is required";
    }

    if (!toDate) {
      errors.toDate = "To Date is required";
    } else if (fromDate && new Date(toDate) < new Date(fromDate)) {
      errors.toDate = "To Date cannot be earlier than From Date";
    }

    if (!reviewDate) {
      errors.reviewDate = "Review Date is required";
    } else if (toDate && new Date(reviewDate) < new Date(toDate)) {
      errors.reviewDate = "Review Date cannot be earlier than To Date";
    }

    if (Object.keys(errors).length > 0) {
      setDateErrors(errors);
      return;
    }

    const formattedDietPlanList = columns.map((column) => {
      const dietEntry = {
        id: null,
        dietTime: column,
      };

      daysOfWeek.forEach((day) => {
        const key = `${day.toLowerCase()}Diet`; // Convert "Monday" to "mondayDiet"
        dietEntry[key] = dietData[day]?.[column] || "";
      });

      return dietEntry;
    });

    const requestBody = {
      dietPlanList: formattedDietPlanList,
      fromDate: fromDate ? new Date(fromDate).toISOString() : null,
      toDate: toDate ? new Date(toDate).toISOString() : null,
      reviewDate: reviewDate ? new Date(reviewDate).toISOString() : null,
    };
    console.log("columns", requestBody);

    AxiosInstance.post(
      `consultation/create-diet-plan/${order.orderId}`,
      requestBody
    )
      .then((response) => {
        toast.success("Plan uploaded successfully!");
        setReviewDate("");
        setToDate("");
        setFromDate("");
        setModalOpen(false);
        setDietPlanSubmitted(true);
      })
      .catch((err) => {
        toast.error("Failed to upload diet plan!");
        console.log("err: ", err);
      });
  };

  // Date input handlers that clear respective errors
  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
    setDateErrors((prev) => ({ ...prev, fromDate: "" })); // Remove error for From Date
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
    setDateErrors((prev) => ({ ...prev, toDate: "" })); // Remove error for To Date
  };

  const handleReviewDateChange = (e) => {
    setReviewDate(e.target.value);
    setDateErrors((prev) => ({ ...prev, reviewDate: "" })); // Remove error for Review Date
  };

  return (
    <>
      {dietPlanSubmitted ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            padding: 2,
          }}
        >
          <div>
            <div>
              <MdCheckCircle size={50} color="green" />
            </div>
            <Typography variant="h6" sx={{ color: "green" }}>
              You have successfully submitted your Plan to the patient.
            </Typography>
          </div>
        </Box>
      ) : (
        <div style={{ padding: "20px" }}>
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            mb={2}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => setDietFileModalOpen(true)}
            >
              Attach Plan File
            </Button>
          </Box>
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            mb={2}
          >
            <Divider style={{ flexGrow: 1 }} />
            <Typography variant="h6" style={{ padding: "0 10px" }}>
              Or
            </Typography>
            <Divider style={{ flexGrow: 1 }} />
          </Box>
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            mb={2}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => setModalOpen(true)}
            >
              Add Plan
            </Button>
          </Box>

          <Dialog
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            fullWidth
            maxWidth="lg"
          >
            <DialogTitle>
              Plan
              <IconButton
                style={{ position: "absolute", right: 10, top: 10 }}
                onClick={() => setModalOpen(false)}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setOpen(true)}
              >
                Add Time
              </Button>

              <Dialog
                open={open}
                fullWidth
                maxWidth="sm"
                onClose={() => setOpen(false)}
              >
                <DialogTitle>Add Time</DialogTitle>
                <DialogContent>
                  <TextField
                    autoFocus
                    margin="dense"
                    label="Column Name"
                    fullWidth
                    value={newColumn}
                    onChange={(e) => setNewColumn(e.target.value)}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setOpen(false)}>Cancel</Button>
                  <Button onClick={handleAddColumn} color="primary">
                    Add
                  </Button>
                </DialogActions>
              </Dialog>

              <TableContainer
                component={Paper}
                style={{
                  marginTop: "20px",
                  overflowX: "auto",
                  maxWidth: "100%",
                  border: "1px solid black",
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontWeight: "bold" }}>Days</TableCell>
                      {columns.map((col, index) => (
                        <TableCell key={index} style={{ fontWeight: "bold" }}>
                          {col}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {daysOfWeek.map((day) => (
                      <TableRow key={day}>
                        <TableCell>{day}</TableCell>
                        {columns.map((col, index) => (
                          <TableCell key={index}>
                            <TextField
                              fullWidth
                              style={{ minWidth: "200px" }}
                              variant="outlined"
                              size="small"
                              value={dietData[day]?.[col] || ""}
                              onChange={(e) =>
                                handleCellChange(day, col, e.target.value)
                              }
                            />
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                // flexDirection="column"
                mb={2}
                mt={2}
                gap="20px"
              >
                <TextField
                  type="date"
                  label="From Date"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  value={fromDate}
                  onChange={handleFromDateChange}
                  error={!!dateErrors.fromDate}
                  helperText={dateErrors.fromDate}
                />
                <TextField
                  type="date"
                  label="To Date"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  value={toDate}
                  onChange={handleToDateChange}
                  error={!!dateErrors.toDate}
                  helperText={dateErrors.toDate}
                />
                <TextField
                  type="date"
                  label="Review Date"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  value={reviewDate}
                  onChange={handleReviewDateChange}
                  error={!!dateErrors.reviewDate}
                  helperText={dateErrors.reviewDate}
                />
              </Box>
              <Box display="flex" alignItems="center" justifyContent="end">
                <Button
                  variant="contained"
                  color="success"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Box>
            </DialogContent>
          </Dialog>

          <Dialog
            open={DietFilemodalOpen}
            onClose={() => setDietFileModalOpen(false)}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle>
              Attach Plan File
              <IconButton
                style={{ position: "absolute", right: 10, top: 10 }}
                onClick={() => setDietFileModalOpen(false)}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Box display="flex" flexDirection="column" gap={2}>
                <Button variant="contained" component="label">
                  Attach File
                  <input
                    type="file"
                    accept=".xls,.xlsx"
                    hidden
                    onChange={handleFileUpload}
                  />
                </Button>
                {selectedFile && (
                  <Typography variant="body2">{selectedFile.name}</Typography>
                )}
                {errors.file && (
                  <Typography color="error">{errors.file}</Typography>
                )}

                <TextField
                  type="date"
                  label="From Date"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  value={fromDate}
                  onChange={(e) => {
                    setFromDate(e.target.value);
                    setErrors((prev) => ({ ...prev, fromDate: "" }));
                  }}
                  error={!!errors.fromDate}
                  helperText={errors.fromDate}
                />
                <TextField
                  type="date"
                  label="To Date"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  value={toDate}
                  onChange={(e) => {
                    setToDate(e.target.value);
                    setErrors((prev) => ({ ...prev, toDate: "" }));
                  }}
                  error={!!errors.toDate}
                  helperText={errors.toDate}
                />
                <TextField
                  type="date"
                  label="Review Date"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  value={reviewDate}
                  onChange={(e) => {
                    setReviewDate(e.target.value);
                    setErrors((prev) => ({ ...prev, reviewDate: "" }));
                  }}
                  error={!!errors.reviewDate}
                  helperText={errors.reviewDate}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDietFileModalOpen(false)}>
                Cancel
              </Button>
              <Button
                onClick={handleFileSubmit}
                variant="contained"
                color="primary"
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </>
  );
};

export default DietPlan;
