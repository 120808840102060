import React, { useState, useEffect } from "react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import "./formStyle.css";
import { AxiosInstance } from "../../configration/AxiosConfiguration";

function Profile_Registration_Form() {
  const [activeStep, setActiveStep] = useState(0);
  const [uhid, setUhid] = useState("");
  const [startTime, setStartTime] = useState(new Date());
  const [subscriptionDetail, setSubscriptionDetail] = useState(null);

  useEffect(() => {
    // This effect runs when the component mounts (screen entry)
    AxiosInstance.get("/subscription/get-detail")
      .then((response) => {
        setSubscriptionDetail(response);
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error:", error);
      });

    setStartTime(new Date());

    // This function will run when the component unmounts (screen exit)
    return () => {
      const endTime = new Date();
      const formatDateTime = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const seconds = String(date.getSeconds()).padStart(2, "0");

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      };

      const data = {
        screenName: "Profile Screen",
        startTime: formatDateTime(startTime),
        endTime: formatDateTime(endTime),
      };

      // Send data to your API using fetch or Axios here
      // Example using fetch:
      AxiosInstance.post("general/save-app-usage-detail", data)
        .then((response) => {})
        .catch((error) => {
          // Handle errors here
          console.error("Error:", error);
        });
    };
  }, []); // Empty dependency array ensures that this effect runs only once (on mount and unmount)

  const handleNext = (uhid) => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setUhid(uhid);
  };

  return (
    <>
      {activeStep == 0 && <Step1 handleNext={handleNext} />}
      {activeStep == 1 && (
        <Step2
          // handleNext={handleNext}
          uhid={uhid}
        />
      )}
      {/* {subscriptionDetail?.subscriptionDetail?.subscriptionType == "BRONZE" ?   <SilverScreensProfileCards></SilverScreensProfileCards>  : 
} */}
    </>
  );
}

export default Profile_Registration_Form;
