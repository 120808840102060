import React, { useEffect, useState } from "react";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Modal, ModalHeader, ModalBody, Input } from "reactstrap";
import OrderDetailModel from "./OrderDetailModel";
import CorporateOrderDetailModel from "./CorporateOrderDetailModel";
import AppLogo from "../../../static/img/AppLogoWithName.png";
import Spinner from "../../shared/Spinner";

const LabOrderHistory = () => {
  const [rowData, setRowData] = useState([]);
  const [orderId, setOrderId] = useState([]);
  const [corporateOrder, setcorporateOrder] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [modal, setModal] = useState(false);
  const [corporatemodal, setcorporateModal] = useState(false);

  const toggle = () => setModal(!modal);
  const Corporatetoggle = () => setcorporateModal(!corporatemodal);

  const findNearestDate = (data, targetDate) => {
    if (!data.length) return targetDate.toISOString().split("T")[0]; // Default to today's date if no data

    return data.reduce((nearest, item) => {
      const itemDate = new Date(item.date); // Ensure the date field matches your API's structure
      const nearestDate = new Date(nearest);
      return Math.abs(itemDate - targetDate) <
        Math.abs(nearestDate - targetDate)
        ? item.date
        : nearest;
    }, data[0].date);
  };
  const getLabAndCorporateOrders = async () => {
    setLoading(true);
    try {
      const [labOrderResponse, corporateOrderResponse] = await Promise.all([
        AxiosInstance.get("lab-partner/order-history-list"),
        AxiosInstance.get("lab-partner/getCorporateOrderHistoryList"),
      ]);

      const labOrderData = labOrderResponse || [];
      const corporateOrderData = corporateOrderResponse || [];

      // Combine both datasets
      const combinedData = [...labOrderData, ...corporateOrderData];

      // Set state for individual data
      setRowData(labOrderData);
      setcorporateOrder(corporateOrderData);

      // Find the nearest date from the combined data
      const nearestDate = findNearestDate(combinedData, new Date());
      setSelectedDate(nearestDate);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching data: ", err);
      setRowData([]);
      setcorporateOrder([]);
      setLoading(false);
    }
  };

  const viewOrderDetail = (id) => {
    setOrderId(id);
    setModal(!modal);
  };
  const viewCorporateOrderDetail = (id) => {
    const order = corporateOrder.find((order) => order.purchaseOrderId === id);
    setSelectedOrder(order);
    setOrderId(id);
    setcorporateModal(true);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };
  const formatDateToDDMMYYYY = (date) => {
    const dates = new Date(date);
    const day = String(dates.getDate()).padStart(2, "0");
    const month = String(dates.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const year = dates.getFullYear();
    return `${day}-${month}-${year}`;
  };
  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(":");
    const hourInt = parseInt(hours, 10);
    const period = hourInt >= 12 ? "PM" : "AM";
    const formattedHour = hourInt % 12 || 12; // Convert 0 hour to 12
    return `${formattedHour}:${minutes} ${period}`;
  };
  const filteredOrders = rowData.filter((order) => order.date === selectedDate);
  const filteredCorporateOrders = corporateOrder.filter(
    (order) => order.date === selectedDate
  );

  useEffect(() => {
    getLabAndCorporateOrders();
  }, []);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3 style={{ margin: 0 }}>My Orders</h3>
        <div
          style={{
            border: "1px solid lightgrey",
            padding: "15px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <b>Filter By Date :</b>
          <span style={{ marginLeft: "10px" }}>
            <input
              type="date"
              className="form-control"
              id="dateInput"
              value={selectedDate}
              onChange={handleDateChange}
            />
          </span>
        </div>
      </div>
      <p style={{ marginTop: "1%" }}>
        <b>This screen will show you daily home collection service orders.</b>
      </p>
      <div>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader
            style={{
              backgroundColor: "black",
              color: "white",
              border: "1px solid lightgrey",
            }}
            toggle={toggle}
          >
            <img src="/img/AppLogoWithName.png" alt="Lab" />
          </ModalHeader>
          <ModalBody>
            <OrderDetailModel orderId={orderId} />
          </ModalBody>
        </Modal>
      </div>
      <div>
        <Modal
          isOpen={corporatemodal}
          toggle={Corporatetoggle}
          style={{ maxHeight: "90vh", overflowY: "auto" }}
        >
          <ModalHeader
            style={{
              backgroundColor: "black",
              color: "white",
              border: "1px solid lightgrey",
            }}
            toggle={Corporatetoggle}
          >
            <img src="/img/AppLogoWithName.png" alt="Lab" />
          </ModalHeader>
          <ModalBody>
            {selectedOrder && (
              <CorporateOrderDetailModel order={selectedOrder} />
            )}
          </ModalBody>
        </Modal>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <>
          {" "}
          {filteredOrders.length === 0 && corporateOrder?.length === 0 ? (
            <>
              <div style={{ marginTop: "7%", marginLeft: "35%" }}>
                <span>
                  <h4 style={{ marginBottom: "2%", marginLeft: "17px" }}>
                    No Orders Available!
                  </h4>
                  <img
                    src="/img/no-order.jpeg"
                    alt="Lab"
                    style={{ width: "25%" }}
                  />
                </span>
              </div>
            </>
          ) : (
            filteredOrders.map((order) => (
              <>
                <TableContainer
                  style={{ marginTop: "2%", border: "1px solid black" }}
                >
                  <Table key={order.orderId}>
                    <TableRow>
                      <TableCell
                        style={{
                          backgroundColor: " rgb(83, 83, 233)",
                          color: "white",
                          border: "1px solid lightgrey",
                          fontSize: "18px",
                          padding: "10px",
                        }}
                      >
                        <p style={{ fontSize: "18px" }}>
                          <b>Date :</b>{" "}
                          {order.date ? formatDateToDDMMYYYY(order.date) : ""}
                        </p>
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: "rgb(83, 83, 233)",
                          color: "white",
                          border: "1px solid lightgrey",
                          fontSize: "18px",
                          padding: "10px",
                        }}
                      >
                        <p style={{ fontSize: "18px" }}>
                          <b>Time :</b>{" "}
                          {order.time ? formatTime(order.time) : ""}
                        </p>
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: " rgb(83, 83, 233)",
                          color: "white",
                          border: "1px solid lightgrey",
                          fontSize: "18px",
                          padding: "10px",
                        }}
                      >
                        <p style={{ fontSize: "18px" }}>
                          <b>LSH Order ID :</b> {order.orderId}
                        </p>
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: "rgb(83, 83, 233)",
                          color: "white",
                          border: "1px solid lightgrey",
                          fontSize: "18px",
                          padding: "10px",
                        }}
                      >
                        <p style={{ fontSize: "18px" }}>
                          <b>Patient UHID :</b>
                          {""} {order.patientDetail.patientUpId}
                        </p>
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: " rgb(83, 83, 233)",
                          color: "white",
                          border: "1px solid lightgrey",
                          fontSize: "18px",
                          padding: "10px",
                        }}
                      >
                        <b>LRID :</b>{" "}
                        {order.testDetail.some((test) => test.lrid)
                          ? order.testDetail
                              .map((test) =>
                                test.lrid ? test.lrid : "Not Available"
                              )
                              .join(", ")
                          : "Not Available"}
                      </TableCell>
                    </TableRow>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          style={{
                            backgroundColor: "whitesmoke",
                            fontWeight: "bolder",
                            border: "1px solid lightgrey",
                            fontSize: "20px",
                            padding: "10px",
                          }}
                        >
                          Name
                        </TableCell>
                        <TableCell
                          style={{
                            backgroundColor: "whitesmoke",
                            fontWeight: "bolder",
                            border: "1px solid lightgrey",
                            fontSize: "20px",
                            padding: "10px",
                          }}
                        >
                          Cell
                        </TableCell>
                        <TableCell
                          colSpan={2}
                          style={{
                            backgroundColor: "whitesmoke",
                            fontWeight: "bolder",
                            border: "1px solid lightgrey",
                            textAlign: "center",
                            fontSize: "20px",
                            padding: "10px",
                          }}
                        >
                          Address
                        </TableCell>
                        <TableCell
                          style={{
                            backgroundColor: "whitesmoke",
                            fontWeight: "bolder",
                            border: "1px solid lightgrey",
                            fontSize: "20px",
                            padding: "10px",
                          }}
                        >
                          Test(s)
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{
                            border: "1px solid lightgrey",
                            fontSize: "20px",
                            padding: "10px",
                          }}
                        >
                          {order.patientDetail.patientName}
                        </TableCell>
                        <TableCell
                          style={{
                            border: "1px solid lightgrey",
                            fontSize: "20px",
                            padding: "10px",
                          }}
                        >
                          {order.patientDetail.patientContactNumber}
                        </TableCell>
                        <TableCell
                          colSpan={2}
                          style={{
                            border: "1px solid lightgrey",
                            textAlign: "center",
                            fontSize: "20px",
                            padding: "10px",
                          }}
                        >
                          {order.patientDetail.address?.addressLine1 +
                            ", " +
                            order.patientDetail.address?.city +
                            " " +
                            order.patientDetail.address?.zipcode +
                            "( " +
                            order.patientDetail.address?.state +
                            ", " +
                            order.patientDetail.address?.country +
                            ")"}
                        </TableCell>
                        <TableCell
                          style={{
                            border: "1px solid lightgrey",
                            fontSize: "20px",
                            padding: "10px",
                          }}
                        >
                          {" "}
                          {order.testDetail
                            .map((test) => test.testName)
                            .join(", ")}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          colSpan={2}
                          style={{
                            backgroundColor: "whitesmoke",
                            fontWeight: "bolder",
                            textAlign: "center",
                            fontSize: "20px",
                            border: "1px solid lightgrey",
                            padding: "10px",
                          }}
                        >
                          Order Copy
                        </TableCell>
                        <TableCell
                          style={{
                            backgroundColor: "whitesmoke",
                            fontWeight: "bolder",
                            border: "1px solid lightgrey",
                            fontSize: "20px",
                            padding: "10px",
                          }}
                        >
                          Order Status
                        </TableCell>
                        <TableCell
                          colSpan={2}
                          style={{
                            backgroundColor: "whitesmoke",
                            fontWeight: "bolder",
                            textAlign: "center",
                            border: "1px solid lightgrey",
                            fontSize: "20px",
                            padding: "10px",
                          }}
                        >
                          Upload Report
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          colSpan={2}
                          style={{
                            border: "1px solid lightgrey",
                            textAlign: "center",
                            fontSize: "20px",
                            padding: "10px",
                          }}
                        >
                          <span
                            onClick={() => viewOrderDetail(order.orderId)}
                            style={{
                              color: "royalblue",
                              cursor: "pointer",
                              fontWeight: 600,
                            }}
                          >
                            View Order Copy
                          </span>
                        </TableCell>
                        <TableCell
                          style={{
                            border: "1px solid lightgrey",
                            padding: "10px",
                          }}
                        >
                          {/* <select className="form-control form-control-sm" onChange={handleSelectChange}>
                <option selected >
                  Order Generated
                </option>
            <option>Accepted</option>
            <option>Sample Collected</option>
            <option>Completed</option>
            <option>Issues</option>
          </select> */}
                          <Input
                            id={`status-${order.orderId}`}
                            type="text"
                            className="custom-select" // Apply custom CSS class
                            value={order.orderStatus}
                          ></Input>
                        </TableCell>
                        {order?.orderStatus == "Completed" ? (
                          <TableCell
                            colSpan={2}
                            style={{ border: "1px solid lightgrey" }}
                          >
                            <center>All Files are Uploaded Successfully</center>
                          </TableCell>
                        ) : null}
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ))
          )}
          {filteredCorporateOrders?.length > 0 &&
            filteredCorporateOrders?.map((order) => (
              <>
                <TableContainer
                  style={{ marginTop: "2%", border: "1px solid black" }}
                >
                  <Table key={order.orderId}>
                    <TableRow>
                      <TableCell
                        style={{
                          backgroundColor: " rgb(83, 83, 233)",
                          color: "white",
                          border: "1px solid lightgrey",
                          fontSize: "18px",
                        }}
                      >
                        <p style={{ fontSize: "18px" }}>
                          <b>Date :</b>{" "}
                          {order.date ? formatDateToDDMMYYYY(order.date) : ""}
                        </p>
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: "rgb(83, 83, 233)",
                          color: "white",
                          border: "1px solid lightgrey",
                          fontSize: "18px",
                        }}
                      >
                        <p style={{ fontSize: "18px" }}>
                          <b>Time :</b> {formatTime(order.time)}
                        </p>
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: " rgb(83, 83, 233)",
                          color: "white",
                          border: "1px solid lightgrey",
                          fontSize: "18px",
                        }}
                      >
                        <p style={{ fontSize: "18px" }}>
                          <b>LSH Order ID :</b> {order.purchaseOrderId}
                        </p>
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: " rgb(83, 83, 233)",
                          color: "white",
                          border: "1px solid lightgrey",
                          fontSize: "18px",
                        }}
                      >
                        <p style={{ fontSize: "18px" }}>
                          <b>Client Type :</b> {order.typeOfClient}
                        </p>
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: " rgb(83, 83, 233)",
                          color: "white",
                          border: "1px solid lightgrey",
                          fontSize: "18px",
                        }}
                      >
                        <b>LRID :</b>{" "}
                        {order.lrId ? order.lrId : "Not Available"}
                      </TableCell>
                    </TableRow>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          style={{
                            backgroundColor: "whitesmoke",
                            fontWeight: "bolder",
                            border: "1px solid lightgrey",
                            fontSize: "20px",
                          }}
                        >
                          Name
                        </TableCell>
                        <TableCell
                          style={{
                            backgroundColor: "whitesmoke",
                            fontWeight: "bolder",
                            border: "1px solid lightgrey",
                            fontSize: "20px",
                          }}
                        >
                          Cell
                        </TableCell>
                        <TableCell
                          colSpan={3}
                          style={{
                            backgroundColor: "whitesmoke",
                            fontWeight: "bolder",
                            border: "1px solid lightgrey",
                            textAlign: "center",
                            fontSize: "20px",
                          }}
                        >
                          Address
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{
                            border: "1px solid lightgrey",
                            fontSize: "20px",
                          }}
                        >
                          {order.clientName}
                        </TableCell>
                        <TableCell
                          style={{
                            border: "1px solid lightgrey",
                            fontSize: "20px",
                          }}
                        >
                          {order.clientContact}
                        </TableCell>
                        <TableCell
                          colSpan={3}
                          style={{
                            border: "1px solid lightgrey",
                            textAlign: "center",
                            fontSize: "20px",
                          }}
                        >
                          {order.clientAddress
                            ? order.clientAddress
                            : "Not Available"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          colSpan={2}
                          style={{
                            backgroundColor: "whitesmoke",
                            fontWeight: "bolder",
                            border: "1px solid lightgrey",
                            fontSize: "20px",
                            textAlign: "center",
                          }}
                        >
                          Test(s)
                        </TableCell>
                        <TableCell
                          style={{
                            backgroundColor: "whitesmoke",
                            fontWeight: "bolder",
                            border: "1px solid lightgrey",
                            fontSize: "20px",
                          }}
                        >
                          Medical Condition
                        </TableCell>
                        <TableCell
                          style={{
                            backgroundColor: "whitesmoke",
                            fontWeight: "bolder",
                            border: "1px solid lightgrey",
                            fontSize: "20px",
                          }}
                        >
                          Test Collection Date
                        </TableCell>
                        <TableCell
                          style={{
                            backgroundColor: "whitesmoke",
                            fontWeight: "bolder",
                            border: "1px solid lightgrey",
                            fontSize: "20px",
                          }}
                        >
                          Test Collection Time
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{
                            border: "1px solid lightgrey",
                            fontSize: "20px",
                            textAlign: "center",
                          }}
                          colSpan={2}
                        >
                          {order.testNames}
                        </TableCell>
                        <TableCell
                          style={{
                            border: "1px solid lightgrey",
                            fontSize: "18px",
                          }}
                        >
                          {order.medicalCondition || "Not Available"}
                        </TableCell>
                        <TableCell
                          style={{
                            border: "1px solid lightgrey",
                            fontSize: "18px",
                          }}
                        >
                          {order.testCollectionDate
                            ? formatDateToDDMMYYYY(order.testCollectionDate)
                            : "Not Available"}
                        </TableCell>
                        <TableCell
                          style={{
                            border: "1px solid lightgrey",
                            fontSize: "18px",
                          }}
                        >
                          {order.testCollectionTime
                            ? formatTime(order.testCollectionTime)
                            : "Not Available"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          colSpan={2}
                          style={{
                            backgroundColor: "whitesmoke",
                            fontWeight: "bolder",
                            textAlign: "center",
                            fontSize: "20px",
                            border: "1px solid lightgrey",
                          }}
                        >
                          Order Copy
                        </TableCell>
                        <TableCell
                          style={{
                            backgroundColor: "whitesmoke",
                            fontWeight: "bolder",
                            border: "1px solid lightgrey",
                            fontSize: "20px",
                          }}
                        >
                          Order Status
                        </TableCell>
                        <TableCell
                          colSpan={2}
                          style={{
                            backgroundColor: "whitesmoke",
                            fontWeight: "bolder",
                            textAlign: "center",
                            border: "1px solid lightgrey",
                            fontSize: "20px",
                          }}
                        >
                          Upload Report
                          {order?.status != "Completed" && order?.quantity && (
                            <span>
                              ({order.quantity} files to be uploaded only)
                            </span>
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          colSpan={2}
                          style={{
                            border: "1px solid lightgrey",
                            textAlign: "center",
                            fontSize: "20px",
                          }}
                        >
                          <span
                            onClick={() =>
                              viewCorporateOrderDetail(order.purchaseOrderId)
                            }
                            style={{
                              color: "royalblue",
                              cursor: "pointer",
                              fontWeight: 600,
                            }}
                          >
                            View Order Copy
                          </span>
                        </TableCell>
                        <TableCell style={{ border: "1px solid lightgrey" }}>
                          <Input
                            id={`status-${order.id}`}
                            type="text"
                            value={order.status}
                            className="custom-select" // Apply custom CSS class
                            style={{
                              border: "2px solid lightgreen",
                            }}
                          ></Input>
                        </TableCell>
                        {order?.status == "Completed" ? (
                          <TableCell
                            colSpan={2}
                            style={{ border: "1px solid lightgrey" }}
                          >
                            <center>All Files are Uploaded Successfully</center>
                          </TableCell>
                        ) : null}
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ))}
        </>
      )}
    </div>
  );
};

export default LabOrderHistory;
