import React, { useEffect, useState } from "react";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  CardText,
} from "reactstrap";

const DieticianTestInfo = () => {
  return (
    <>
      <h2 className="mt-4">This Page will be available only after review of your General Information.</h2>
      {/* <div style={{ display: "flex" }}>
        <h2 style={{ marginTop: "2%" }}>Test Information</h2>
        <div style={{ marginInlineStart: "auto" }}>
          <div>
            <b>Attach your Test Information File.</b>
          </div>
          <div
            style={{
              border: "1px solid lightgrey",
              padding: "15px",
              marginTop: "10px",
            }}
          >
            <input type="file"></input>
          </div>
        </div>
      </div>
      <p style={{ marginTop: "4%" }}>
        <b>
          Please give information for all tests in the below format or any other
          format similar to this. A sample test is shown below just to give an
          idea.{" "}
        </b>
      </p>
      <table
        class="table  table-bordered table-striped"
        style={{ marginTop: "1%" }}
      >
        <thead class="thead-dark">
          <tr>
            <th
              scope="col"
              style={{
                backgroundColor: "rgb(83, 83, 233)",
                color: "white",
                textAlign: "center",
              }}
            >
              Test Name
            </th>
            <th
              scope="col"
              style={{
                backgroundColor: "rgb(83, 83, 233)",
                color: "white",
                textAlign: "center",
              }}
            >
              Brief Description
            </th>
            <th
              scope="col"
              style={{
                backgroundColor: "rgb(83, 83, 233)",
                color: "white",
                textAlign: "center",
              }}
            >
              Does this test include several other tests (like in Lipid
              Panel/Profile) (YES/NO)
            </th>
            <th
              scope="col"
              style={{
                backgroundColor: "rgb(83, 83, 233)",
                color: "white",
                textAlign: "center",
              }}
            >
              What other tests are included in this test?
            </th>
            <th
              scope="col"
              style={{
                backgroundColor: "rgb(83, 83, 233)",
                color: "white",
                textAlign: "center",
              }}
            >
              State Preparation (like fasting) if required.
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row" style={{ textAlign: "center" }}>
              Lipid and Diabetes Monitoring
            </th>
            <td style={{ textAlign: "center" }}>
              Diabetes and abnormal level of lipids often coexist and so this
              test checks for both the conditions.An early detection helps your
              doctor to recommend proper treatment including Lifestyle
              modifications{" "}
            </td>
            <td style={{ textAlign: "center" }}>Yes</td>
            <td style={{ textAlign: "center" }}>
              HbAIC (Hemoglobin A1c), Lipid panel which includes Total
              Cholesterol, LDLCholesterol, HDL cholesterol, Triglycerides
            </td>
            <td style={{ textAlign: "center" }}>
              10-12 hours of fasting prior to blood sample collection is
              required. It is ok to drink water but no other food or drink
              should be consumed
            </td>
          </tr>
        </tbody>
      </table> */}
    </>
  );
};
export default DieticianTestInfo;
