import { yupResolver } from "@hookform/resolvers/yup";
import { Modal, ModalBody, Label, Input } from "reactstrap";
import { Grid, TableHead } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import "react-phone-input-2/lib/bootstrap.css";
import { toast } from "react-toastify";
import * as Yup from "yup";
import iconAddress from "../../../static/img/icon-address-3.png";
import iconCountry from "../../../static/img/icon-country-3.png";
import iconDate from "../../../static/img/icon-filled-events-3.png";
import iconGender from "../../../static/img/icon-gender-1.png";
import iconMail from "../../../static/img/icon-mail-1.png";
import iconUser from "../../../static/img/icon-user-4.png";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { strings } from "../../constants/strings";
import indiaFlag from "../../img/india-flag.png";
import Spinner from "../../shared/Spinner";
import { calculateAge } from "../../util/Helper";
import { nameValidation } from "../../util/validations";
import InputField from "./InputField";
import PhoneInputField from "./PhoneInputField";
import SelectField from "./SelectField";
import "./formStyle.css";
import CircleIcon from "@mui/icons-material/Circle";
import CottageIcon from "@mui/icons-material/Cottage";
import { Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { getSubscription, createFamilyProfile, getFamilyInformation } from "../../constants/apiList";
import Swal from "sweetalert2";

const Step1 = ({ loading, handleNext }) => {
  const [stateData, setStateData] = useState();
  const [defaultFormValue, setDefaultFormValue] = useState();
  const [userAgeGeneration, setUserAgeGeneration] = useState("");
  const [minDate, setMinDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [focusAfterClose, setFocusAfterClose] = useState(true);
  const [anotherAddress, setAnotherAddress] = useState("");
  const [packageName, setPackageName] = useState();
  const [name, setName] = useState();
  const [relationShipWithUser, setRelationshipWithUser] = useState();
  const [dateOfBirth, setDateOfBirth] = useState();
  const [maritalStatus, setMaritalStatus] = useState();
  const [livingWithUser, setLivingWithUser] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState();
  const [email, setEmail] = useState();
  const [accessNeeded, setAccessNeeded] = useState(false);
  const [addressLine1, setAddressLine1] = useState();
  const [city, setCity] = useState();
  const [country, setCountry] = useState();
  const [state, setState] = useState();
  const [zipcode, setZipcode] = useState();
  const [familyInfo, setFamilyInfo] = useState();
  const [familyMembers, setFamilyMembers] = useState(null);
  const [userLastName, setUserLastName] = useState(null);
  const [familyProfileInfo, setFamilyProfileInfo] = useState(null);
  let numberOfRows = 0;
  const [rowsState, setRowsState] = useState(
    Array(numberOfRows)
      .fill(0)
      .map(() => ({
        firstName: "",
        lastName: "",
        relationShipWithUser: "",
        dateOfBirth: "",
        livingWithUser: true,
        maritalStatus: "",
        phoneNumber: "",
        email: "",
        accessNeeded: false,
        address: {
          addressLine1: "",
          city: "",
          country: "",
          state: "",
          zipcode: "",
          birthPlace: "",
        },
      }))
  );
  const validationSchema = Yup.object().shape({
    profile: Yup.object().shape({
      firstName: nameValidation(strings.field.firstName, { allowNumbers: true }),
      middleName: nameValidation(strings.field.middleName, {
        optional: true,
        allowNumbers: true,
        allowSymbol: false,
      }),
      lastName: nameValidation(strings.field.lastName, { allowNumbers: true }),
      age: Yup.string(),
      dob: Yup.string().required(strings.validation.selectRequired(strings.field.dateOfBirth)),
      gender: Yup.string().required(strings.validation.selectRequired(strings.field.sex)),
      contactNumber: Yup.string().required("Phone number is required").min(10).max(10, "Phone number must be at least 10 digits"),
      alternatePhoneNumber: Yup.string()
        .matches(/^\d*$/, "Phone number should only contain digits")
        .test("len", "Phone number must have exactly 10 digits", (val) => !val || val.length === 10),
      email: Yup.string().required(strings.validation.fieldRequired(strings.field.email)).email(strings.validation.invalidEmail),
    }),
    address: Yup.object().shape({
      addressLine1: nameValidation(strings.field.city, {
        optional: true,
        allowSymbol: false,
        allowNumbers: true,
      }),
      addressLine2: nameValidation(strings.field.city, {
        optional: true,
        allowSymbol: false,
        allowNumbers: true,
      }),
      city: nameValidation(strings.field.city, { optional: false }),
      country: Yup.string(),
      state: Yup.string().required("Please Select State"),
      zipcode: Yup.string()
        .notRequired()
        .matches(/^\d{6}$/, {
          message: "ZIP code must be exactly 6 digits",
          excludeEmptyString: true,
        }),
      birthPlace: Yup.string().required("Birth Place is required"),
    }),
  });

  const getStateList = async () => {
    await AxiosInstance.get("state/list")
      .then((response) => {
        setStateData(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getStateList();
    const today = new Date();
    const eighteenYearsAgo = new Date(today);
    eighteenYearsAgo.setFullYear(today.getFullYear() - 18);
    const setDate = eighteenYearsAgo.toISOString().split("T")[0];
    setMinDate(setDate);
  }, []);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      profile: {
        firstName: "",
        lastName: "",
        middleName: "",
        aadharNumber: "",
        age: "",
        dob: "",
        gender: "",
        preferredLanguage: "",
        contactNumber: "",
        email: "",
        alternatePhoneNumber: "",
        generation: "",
        registrationDate: "",
      },
      address: {
        addressLine1: "",
        addressLine2: "",
        city: "",
        country: "",
        state: "",
        zipcode: "",
        birthPlace: "",
      },
      upId: "",
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await AxiosInstance.get("profile/get");
        setUserLastName(response?.profile?.lastName);
        if (response == null) {
          // const dataFromLocalStorage = JSON.parse(getProfile());
          const dataFromLocalStorage = window.sessionStorage.getItem("userDetails");
          setDefaultFormValue(JSON.parse(dataFromLocalStorage));
        } else {
          setDefaultFormValue(response);
        }
        setIsLoading(false);
      } catch (error) {
        toast.error(error);
      }
    };
    AxiosInstance.get("/family/get-family")
      .then((response) => {
        setFamilyMembers(response);
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    fetchData();
    getFamilyProfile();
  }, []);
  useEffect(() => {
    setValue("profile.firstName", defaultFormValue?.profile?.firstName || defaultFormValue?.firstName);
    setValue("profile.lastName", defaultFormValue?.profile?.lastName || defaultFormValue?.lastName);
    setValue("profile.middleName", defaultFormValue?.profile?.middleName || defaultFormValue?.middleName);
    setValue("profile.age", defaultFormValue?.profile?.age || defaultFormValue?.age);
    setValue("profile.dob", defaultFormValue?.profile?.dob || defaultFormValue?.dob);
    setValue("profile.gender", defaultFormValue?.profile?.gender || defaultFormValue?.gender);
    setValue("profile.contactNumber", defaultFormValue?.profile?.contactNumber || defaultFormValue?.phoneNumber);
    setValue("profile.email", defaultFormValue?.profile?.email || defaultFormValue?.email);
    setValue("profile.alternatePhoneNumber", defaultFormValue?.profile?.alternatePhoneNumber || defaultFormValue?.alternatePhoneNumber);
    setValue("profile.generation", defaultFormValue?.profile?.generation || defaultFormValue?.generation);
    setValue("profile.registrationDate", defaultFormValue?.profile?.registrationDate || defaultFormValue?.registrationDate);
    setValue("address.addressLine1", defaultFormValue?.address?.addressLine1 || defaultFormValue?.addressLine1);
    setValue("address.addressLine2", defaultFormValue?.address?.addressLine2 || defaultFormValue?.addressLine2);
    setValue("address.city", defaultFormValue?.address?.city || defaultFormValue?.city);
    setValue("address.country", defaultFormValue?.address?.country || defaultFormValue?.country);
    setValue("address.state", defaultFormValue?.address?.state || defaultFormValue?.state);
    setValue("address.zipcode", defaultFormValue?.address?.zipcode || defaultFormValue?.zipcode);
    setValue("address.birthPlace", defaultFormValue?.address?.birthPlace || defaultFormValue?.birthPlace);
    setValue("upId", defaultFormValue?.upId || defaultFormValue?.upId);
  }, [defaultFormValue]);

  const { handleSubmit, control, formState, getValues, setValue, reset } = methods;
  const { errors } = formState;

  const handleDateChange = (date) => {
    const ageGeneration = date && calculateAge(date);
    setValue("profile.age", "");
    setUserAgeGeneration(ageGeneration);
  };

  const onSubmit = async (data) => {
    console.log("data", data);
    try {
      await AxiosInstance.post("profile/save", data);

      if (checkConditionsBeforeNextPage()) {
        await familyProfile();
        handleNext(defaultFormValue?.upId);
        reset();
      } else {
        console.log("Conditions not met for moving to next page.");
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const checkConditionsBeforeNextPage = () => {
    for (let row of rowsState) {
      if (row.accessNeeded && (!row.email || !row.phoneNumber)) {
        Swal.fire({
          icon: "error",
          text: "If Access Needed is Yes then Phone number and Email are required",
        });
        return false;
      }
      if (row.maritalStatus && row.maritalStatus !== "single" && (row.relationShipWithUser === "son" || row.relationShipWithUser === "daughter")) {
        Swal.fire({
          icon: "error",
          text: "After marriage your child needs to change their account to individual",
        });
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    setValue("profile.age", userAgeGeneration.age);
    setValue("profile.generation", userAgeGeneration.generation);
  }, [userAgeGeneration]);

  const customHandleChange = (value) => {
    if (value == "india") {
      getStateList();
    } else {
      setStateData([]);
    }
  };
  const handleYesClick = () => {
    setOpenModel(true);
  };
  console.log("rowASta", rowsState);
  const saveAddress = (i, event) => {
    setOpenModel(false);
    const updatedAddress = {
      addressLine1: addressLine1,
      city: city,
      state: state,
      country: country,
      zipcode: zipcode,
    };
    const newRowsState = rowsState.map((row, index) => {
      if (row) {
        return { ...row, address: updatedAddress };
      } else {
        return row;
      }
    });
    setRowsState(newRowsState.filter(Boolean));
  };
  const cancelButton = () => {
    setOpenModel(false);
  };
  const subscriptionInfo = () => {
    AxiosInstance.get(`${getSubscription}`)
      .then((response) => {
        setPackageName(response?.subscriptionDetail?.packageName);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const familyProfile = async () => {
    try {
      if (familyMembers != null && packageName != null && !packageName.startsWith("Individual")) {
        const formData = familyMembers.map((row, index) => ({
          id: row.id || null,
          rootUserId: row.rootUserId || null,
          patientId: row.patientId || null,
          firstName: row.firstName || "",
          lastName: userLastName,
          relationShipWithUser: row.relationShipWithUser || "",
          dateOfBirth: row.dateOfBirth ? new Date(row.dateOfBirth).toISOString() : "",
          livingWithUser: row.livingWithUser || false,
          phoneNumber: row.phoneNumber || "",
          email: row.email || "",
          accessNeeded: row.accessNeeded || false,
          address: {
            addressLine1: (row.address && row.address.addressLine1) || "",
            city: (row.address && row.address.city) || "",
            country: (row.address && row.address.country) || "",
            state: (row.address && row.address.state) || "",
            zipcode: (row.address && row.address.zipcode) || "",
            birthPlace: (row.address && row.address.birthPlace) || "",
          },
        }));

        await AxiosInstance.post(`${createFamilyProfile}`, formData);
      } else {
        if (packageName != null && !packageName.startsWith("Individual")) {
          const formData = rowsState.map((row, index) => {
            if (row.accessNeeded && (!row.email || !row.phoneNumber)) {
              Swal.fire({
                icon: "error",
                text: "If Access Needed is Yes then Phone number and Email are required",
              });
              return null;
            }

            if (row.maritalStatus && row.maritalStatus !== "single" && (row.relationShipWithUser === "son" || row.relationShipWithUser === "daughter")) {
              Swal.fire({
                icon: "error",
                text: "After marriage your child needs to change their account to individual",
              });
              return null;
            }

            return {
              firstName: row.firstName || "",
              lastName: userLastName,
              relationShipWithUser: row.relationShipWithUser || "",
              dateOfBirth: row.dateOfBirth ? new Date(row.dateOfBirth).toISOString() : "",
              livingWithUser: row.livingWithUser || false,
              phoneNumber: row.phoneNumber || "",
              email: row.email || "",
              accessNeeded: row.accessNeeded || false,
              address: {
                addressLine1: index === 0 ? row.addressLine1 : row.addressLine1,
                city: row.city || row.city || "",
                country: row.country || row.country || "",
                state: row.state || row.state || "",
                zipcode: row.zipcode || row.zipcode || "",
              },
            };
          });
          // .filter((data) => data !== null); // Filter out null entries from validation checks

          await AxiosInstance.post(`${createFamilyProfile}`, formData);
        }
      }
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  };

  const getFamilyInfo = () => {
    AxiosInstance.get(`${getFamilyInformation}`)
      .then((response) => {
        setFamilyInfo(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  useEffect(() => {
    subscriptionInfo();
    getFamilyInfo();
  }, []);

  if (!!packageName && !packageName.startsWith("Individual")) {
    let packageLength = packageName.split("+");

    switch (packageLength.length) {
      case 2:
        numberOfRows = 1;
        break;
      case 3:
        numberOfRows = 5;
        break;
      case 4:
        numberOfRows = 7;
        break;
      default:
        numberOfRows = 0;
    }
  }
  // Function to handle dynamic row rendering

  const handleNameChange = (event, index) => {
    const { value } = event.target;
    setRowsState((prevState) => {
      const updatedRows = [...prevState];
      updatedRows[index - 1] = { ...updatedRows[index - 1], firstName: value };
      return updatedRows;
    });
  };
  const handleDateOfBirthChange = (event, index) => {
    const { value } = event.target;
    setRowsState((prevState) => {
      const updatedRows = prevState.map((row, idx) => {
        if (idx === index - 1) {
          return { ...row, dateOfBirth: value };
        }
        return row;
      });
      return updatedRows;
    });
  };
  const handlePhonenumber = (event, index) => {
    const { value } = event.target;
    setRowsState((prevState) => {
      const updatedRows = prevState.map((row, idx) => {
        if (idx === index - 1) {
          return { ...row, phoneNumber: value };
        }
        return row;
      });
      return updatedRows;
    });
  };
  const handleEmail = (event, index) => {
    const { value } = event.target;
    setRowsState((prevState) => {
      const updatedRows = prevState.map((row, idx) => {
        if (idx === index - 1) {
          return { ...row, email: value };
        }
        return row;
      });
      return updatedRows;
    });
  };
  const handleLivingWithUserChange = (event, index) => {
    const { value } = event.target;
    if (rowsState[index - 1]) {
      setRowsState((prevState) => {
        const updatedRows = [...prevState];
        updatedRows[index - 1].livingWithUser = value === "true";
        return updatedRows;
      });
    } else {
      console.error(`Index ${index - 1} does not exist in rowsState`);
    }
  };
  const handleAccessNeededChange = (event, index) => {
    const { value } = event.target;
    if (rowsState[index - 1]) {
      setRowsState((prevState) => {
        const updatedRows = [...prevState];
        updatedRows[index - 1].accessNeeded = value === "true";
        return updatedRows;
      });
    } else {
      console.error(`Index ${index - 1} does not exist in rowsState`);
    }
  };
  const handleRelationshipChange = (event, index) => {
    const { value } = event.target;
    setRowsState((prevState) => {
      const updatedRows = prevState.map((row, idx) => {
        if (idx === index - 1) {
          return { ...row, relationShipWithUser: value };
        }
        return row;
      });
      return updatedRows;
    });
  };

  const handleMaritalStatus = (event, index) => {
    const { value } = event.target;
    setRowsState((prevState) => {
      const updatedRows = prevState.map((row, idx) => {
        if (idx === index - 1) {
          return { ...row, maritalStatus: value };
        }
        return row;
      });
      return updatedRows;
    });
  };
  const handleAddress1 = (event, index) => {
    const { value } = event.target;
    setAddressLine1(value);
    setRowsState((prevState) => {
      const updatedRows = prevState.map((row, idx) => {
        if (idx === index - 1) {
          return { ...row, addressLine1: value };
        }
        return row;
      });
      return updatedRows;
    });
  };
  const handleCity = (event, index) => {
    const { value } = event.target;
    setCity(value);
    setRowsState((prevState) => {
      const updatedRows = prevState.map((row, idx) => {
        if (idx === index - 1) {
          return { ...row, city: value };
        }
        return row;
      });
      return updatedRows;
    });
  };

  const handleState = (event, index) => {
    const { value } = event.target;
    setState(value);
    setRowsState((prevState) => {
      const updatedRows = prevState.map((row, idx) => {
        if (idx === index - 1) {
          return { ...row, state: value };
        }
        return row;
      });
      return updatedRows;
    });
  };
  const handleCountry = (event, index) => {
    const { value } = event.target;
    setCountry(value);
    setRowsState((prevState) => {
      const updatedRows = prevState.map((row, idx) => {
        if (idx === index - 1) {
          return { ...row, country: value };
        }
        return row;
      });
      return updatedRows;
    });
  };
  const handlePincode = (event, index) => {
    const { value } = event.target;
    setZipcode(value);
    setRowsState((prevState) => {
      const updatedRows = prevState.map((row, idx) => {
        if (idx === index - 1) {
          return { ...row, zipcode: value };
        }
        return row;
      });
      return updatedRows;
    });
  };
  const renderRows = () => {
    let rows = [];
    for (let i = 1; i <= numberOfRows; i++) {
      rows.push(
        <>
          <TableRow key={i}>
            <TableCell>
              <input
                value={name}
                className="form-control"
                type="text"
                placeholder="name"
                onChange={(event) => handleNameChange(event, i)}
              ></input>
            </TableCell>
            <TableCell>
              <select
                value={relationShipWithUser}
                className="form-control"
                onChange={(event) => handleRelationshipChange(event, i)}
              >
                <option
                  value=""
                  disabled
                  selected
                >
                  Relationship
                </option>
                <option value="wife">Wife</option>
                <option value="son">Son</option>
                <option value="daughter">Daughter</option>
                <option value="father">Father</option>
                <option value="mother">Mother</option>
              </select>
            </TableCell>
            <TableCell>
              <input
                value={dateOfBirth}
                className="form-control"
                type="date"
                onChange={(event) => handleDateOfBirthChange(event, i)}
              ></input>
            </TableCell>
            <TableCell style={{ textAlign: "center" }}>
              <Input
                name={`livingWithUser${i}`}
                type="radio"
                checked={rowsState[i - 1]?.livingWithUser === false}
                value="false"
                onClick={handleYesClick}
                onChange={(e) => {
                  handleLivingWithUserChange(e, i);
                }}
              />
              &nbsp;
              <Label
                check
                className="ml-1"
              >
                No
              </Label>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Input
                name={`livingWithUser${i}`}
                type="radio"
                checked={rowsState[i - 1]?.livingWithUser === true}
                value="true"
                onChange={(e) => {
                  handleLivingWithUserChange(e, i);
                }}
              />
              &nbsp;
              <Label
                check
                className="ml-1"
              >
                Yes
              </Label>
            </TableCell>
            <TableCell>
              <select
                value={maritalStatus}
                className="form-control"
                onChange={(event) => handleMaritalStatus(event, i)}
              >
                <option
                  value=""
                  disabled
                  selected
                >
                  Marital Status
                </option>
                <option value="single">Single</option>
                <option value="married">Married</option>
                <option value="widowed">Widowed</option>
                <option value="divorced">Divorced</option>
              </select>
            </TableCell>
            <TableCell>
              <input
                value={phoneNumber}
                className="form-control"
                placeholder="Phone Number"
                type="number"
                onChange={(event) => handlePhonenumber(event, i)}
              ></input>
            </TableCell>
            <TableCell>
              <input
                value={email}
                className="form-control"
                placeholder="xyz@gmail.com"
                type="email"
                onChange={(event) => handleEmail(event, i)}
              ></input>
            </TableCell>
            <TableCell style={{ textAlign: "center" }}>
              <br></br>
              <Input
                name={`accessNeeded${i}`}
                type="radio"
                checked={rowsState[i - 1]?.accessNeeded === false}
                value="false"
                onChange={(e) => {
                  handleAccessNeededChange(e, i);
                }}
              />
              &nbsp;
              <Label
                check
                className="ml-1"
              >
                No
              </Label>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Input
                name={`accessNeeded${i}`}
                type="radio"
                checked={rowsState[i - 1]?.accessNeeded === true}
                value="true"
                onChange={(e) => {
                  handleAccessNeededChange(e, i);
                }}
              />
              &nbsp;
              <Label
                check
                className="ml-1"
              >
                Yes
              </Label>
            </TableCell>
          </TableRow>
          <Modal
            returnFocusAfterClose={focusAfterClose}
            isOpen={openModel}
          >
            <ModalBody>
              <div className="card-container mt-5">
                <center>
                  <CottageIcon style={{ fontSize: "48px" }} />
                  <h5>If not living with you, then please enter the address.</h5>
                  <br></br>
                  <div>
                    <div>
                      <b>Address :</b>{" "}
                      <input
                        value={rowsState[i - 1]?.addressLine1}
                        type="text"
                        className="form-control"
                        placeholder="Enter Address"
                        onChange={(event) => handleAddress1(event, i)}
                        style={{ width: "50%" }}
                      ></input>
                      <b>City :</b>
                      <input
                        type="text"
                        value={rowsState[i - 1]?.city}
                        className="form-control"
                        placeholder="Enter City"
                        style={{ width: "50%" }}
                        onChange={(event) => handleCity(event, i)}
                      ></input>
                      <b>State :</b>{" "}
                      <input
                        value={rowsState[i - 1]?.state}
                        type="text"
                        className="form-control"
                        placeholder="Enter State"
                        style={{ width: "50%" }}
                        onChange={(event) => handleState(event, i)}
                      ></input>
                      <b>Country :</b>{" "}
                      <input
                        value={rowsState[i - 1]?.country}
                        type="text"
                        className="form-control"
                        placeholder="Enter Country"
                        style={{ width: "50%" }}
                        onChange={(event) => handleCountry(event, i)}
                      ></input>
                      <b>Pin Code :</b>{" "}
                      <input
                        type="text"
                        value={rowsState[i - 1]?.zipcode}
                        className="form-control"
                        placeholder="Enter Pin Code"
                        style={{ width: "50%" }}
                        onChange={(event) => handlePincode(event, i)}
                      ></input>
                    </div>
                  </div>
                  <br></br>
                  <button
                    className="btn btn-primary"
                    onClick={(event) => saveAddress(i, event)}
                  >
                    Save
                  </button>
                  &nbsp;&nbsp; &nbsp;&nbsp;
                  <button
                    className="btn btn-danger"
                    onClick={cancelButton}
                  >
                    Cancel
                  </button>
                </center>
              </div>
            </ModalBody>
          </Modal>
        </>
      );
    }
    return rows;
  };
  const getFamilyProfile = (event) => {
    AxiosInstance.get("/family/getPatientFamilyProfile")
      .then((response) => {
        setFamilyProfileInfo(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <div className="profile_section">
        <Grid
          container
          spacing={2}
          mb={5}
          justifyContent="space-between"
        >
          <Grid item>
            <div className="register_title">
              <h2 className="m-0">Registration</h2>
              <h5>Tier 1</h5>
            </div>
          </Grid>
        </Grid>
        {isLoading && (
          <div className="loaderSpinner">
            <Spinner />
          </div>
        )}
        <FormProvider {...methods}>
          {!isLoading && (
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="registration_tier1"
            >
              <Grid
                container
                spacing={2}
                mb={2}
                justifyContent="space-between"
                alignItems={"center"}
              >
                <Grid item>
                  <div className="uhid-wrapper date-container">
                    <h5>
                      UHID : <span className="uhid">{defaultFormValue?.upId}</span>
                    </h5>
                  </div>
                </Grid>
                <Grid item>
                  <div className="date-container register-date">
                    <div className="date-icon">
                      <img
                        src={iconDate}
                        alt="Date"
                      />
                    </div>
                    <div className="date-label">
                      <label htmlFor="date">Registration Date:</label>
                    </div>
                    <div className="date-input">
                      {defaultFormValue?.profile?.registrationDate}
                      {/* {getTodayDate()} */}
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={6}
                  md={4}
                >
                  <InputField
                    name="profile.firstName"
                    label="First Name"
                    labelClass="requiredField"
                    control={control}
                    errors={errors}
                    placeholder="First Name"
                    className={`inputField requiredField ${errors.profile?.firstName ? "invalidField" : ""}`}
                    icon={iconUser}
                  />
                  <p className="error-message">{errors.profile?.firstName?.message}</p>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={4}
                >
                  <InputField
                    name="profile.middleName"
                    label="Middle Name"
                    control={control}
                    errors={errors}
                    icon={iconUser}
                    placeholder="Middle Name"
                    className={`inputField requiredField ${errors.profile?.middleName ? "invalidField" : ""}`}
                  />
                  <p className="error-message">{errors.profile?.middleName?.message}</p>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={4}
                >
                  <InputField
                    name="profile.lastName"
                    label="Last Name"
                    labelClass="requiredField"
                    control={control}
                    errors={errors}
                    placeholder="Last Name"
                    icon={iconUser}
                    className={`inputField requiredField ${errors.profile?.lastName ? "invalidField" : ""}`}
                  />
                  <p className="error-message">{errors.profile?.lastName?.message}</p>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={3}
                >
                  <div className="date-field input-field input-date-icon">
                    <label className="requiredField">Date of Birth</label>
                    <Controller
                      name="profile.dob"
                      control={control}
                      render={({ field }) => (
                        <div className="inputWithIcon">
                          <input
                            type="date"
                            className={`inputField ${errors.profile?.dob ? "invalidField" : ""}`}
                            // max={minDate}
                            {...field}
                            onChange={(e) => {
                              field.onChange(e);
                              handleDateChange(e.target.value);
                            }}
                          />
                          <img
                            src={iconDate}
                            alt="Icon"
                          />
                        </div>
                      )}
                    />
                  </div>
                  <p className="error-message">{errors.profile?.dob?.message}</p>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={3}
                >
                  <InputField
                    name="profile.age"
                    label="Age"
                    control={control}
                    errors={errors}
                    placeholder="Age"
                    icon={iconUser}
                    className={`inputField requiredField ${errors.profile?.dob ? "invalidField" : ""}`}
                    isDisabled={true}
                  />
                  <p className="error-message">{errors.profile?.age?.message}</p>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={3}
                >
                  <InputField
                    name="profile.generation"
                    label="Generation"
                    control={control}
                    errors={errors}
                    placeholder="Generation"
                    icon={iconUser}
                    isDisabled={true}
                  />
                  <p className="error-message">{errors.profile?.generation?.message}</p>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={3}
                >
                  <SelectField
                    name="profile.gender"
                    label="Sex"
                    labelClass="requiredField"
                    fieldLabel="Select Gender"
                    options={[
                      { value: "male", name: "Male" },
                      { value: "female", name: "Female" },
                      { value: "other", name: "Other" },
                    ]}
                    control={control}
                    errors={errors}
                    placeholder="eg. 35"
                    icon={iconGender}
                    className={`inputField requiredField ${errors.profile?.gender ? "invalidField" : ""}`}
                  />
                  <p className="error-message">{errors.profile?.gender?.message}</p>
                </Grid>
                <Grid
                  className="otherLabel"
                  item
                  xs={12}
                  md={12}
                >
                  <label>Country</label>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  className="bottomMargin15"
                >
                  <SelectField
                    name="address.country"
                    fieldLabel="Select Country"
                    options={[{ value: "india", name: "India" }]}
                    control={control}
                    errors={errors}
                    defaultValue="india"
                    icon={iconCountry}
                    className="inputField"
                    customHandleChange={customHandleChange}
                  />
                  <p className="error-message">{errors.address?.country?.message}</p>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  className="bottomMargin15"
                >
                  <SelectField
                    fieldLabel="Select State"
                    name="address.state"
                    options={stateData}
                    control={control}
                    errors={errors}
                    placeholder="g. Region"
                    icon={iconCountry}
                    className="inputField"
                  />
                  <p className="error-message">{errors.address?.state?.message}</p>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  className="bottomMargin15"
                >
                  <InputField
                    name="address.addressLine1"
                    label="Address Line 1"
                    control={control}
                    errors={errors}
                    placeholder="Address Line 1"
                    icon={iconAddress}
                    className="inputField"
                  />
                  <p className="error-message">{errors.address?.addressLine1?.message}</p>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  className="bottomMargin15"
                >
                  <InputField
                    name="address.addressLine2"
                    label="Address Line 2"
                    control={control}
                    errors={errors}
                    placeholder="Address Line 2"
                    icon={iconAddress}
                    className="inputField"
                  />
                  <p className="error-message">{errors.address?.addressLine2?.message}</p>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={4}
                >
                  <InputField
                    name="address.city"
                    label="City"
                    control={control}
                    errors={errors}
                    placeholder="City"
                    icon={iconCountry}
                    className="inputField"
                  />
                  <p className="error-message">{errors.address?.city?.message}</p>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={4}
                >
                  <InputField
                    name="address.zipcode"
                    label="Zip Code"
                    control={control}
                    errors={errors}
                    placeholder="Zip Code"
                    icon={iconAddress}
                    className="inputField"
                    maxLength={6}
                  />
                  <p className="error-message">{errors.address?.zipcode?.message}</p>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={4}
                >
                  <SelectField
                    label="Birth Place (State)"
                    name="address.birthPlace"
                    labelClass="requiredField"
                    options={stateData}
                    control={control}
                    errors={errors}
                    placeholder="g. Region"
                    icon={iconCountry}
                    className="inputField"
                  />
                  <p className="error-message">{errors.address?.birthPlace?.message}</p>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={4}
                >
                  <PhoneInputField
                    name="profile.contactNumber"
                    label="Contact Number"
                    control={control}
                    errors={errors}
                    className="phone-custom"
                    icon={indiaFlag}
                    placeholder="Contact Number"
                    fieldLabel="Contact Number"
                    isDisabled={true}
                    maxLength={10}
                    minLength={10}
                    labelClass="requiredField"
                  />
                  <p className="error-message">{errors.profile?.contactNumber?.message}</p>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={4}
                >
                  <PhoneInputField
                    name="profile.alternatePhoneNumber"
                    control={control}
                    label="Alternate Phone Number"
                    errors={errors}
                    className="phone-custom"
                    icon={indiaFlag}
                    placeholder="Alternate Phone Number"
                    maxLength={10}
                  />
                  <p className="error-message">{errors.profile?.alternatePhoneNumber?.message}</p>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={4}
                >
                  <InputField
                    name="profile.email"
                    label="Email"
                    control={control}
                    errors={errors}
                    labelClass="requiredField"
                    placeholder="eg. exampleemail@gmail.com"
                    icon={iconMail}
                    className="inputField"
                    isDisabled={true}
                  />
                  <p className="error-message">{errors.profile?.email?.message}</p>
                </Grid>
              </Grid>
              {familyProfileInfo == null && packageName != null && !packageName.startsWith("Individual") && (
                <Grid
                  container
                  spacing={0}
                  mb={0}
                  mt={3}
                  justifyContent="flex-end"
                  display={"flex"}
                  gap={5}
                >
                  <div style={{ marginTop: "4%" }}>
                    * <h4 style={{ marginTop: "-28px", marginLeft: "12px", marginBottom: "12px" }}>Family Profile</h4>
                    <span style={{ marginTop: "20px" }}>
                      Please complete your family profile information below since you have opted for a Family Health Package. Do not include your information in the table below. It is only for your
                      spouse, daughter, son, father, and mother. Only up to 4 children, spouse and your parents can be registered with us.
                    </span>
                  </div>
                  <TableContainer
                    className="risk-table-wrapper1"
                    style={{ marginTop: "-12px" }}
                  >
                    <Table size="small">
                      <TableHead sx={{ backgroundColor: "#aaa" }}>
                        <TableRow>
                          <TableCell style={{ textAlign: "center" }}>Name</TableCell>
                          <TableCell>Relationship with you</TableCell>
                          <TableCell>Date of Birth</TableCell>
                          <TableCell>Living with you?</TableCell>
                          {familyMembers == null && <TableCell>Material Status</TableCell>}
                          <TableCell>Phone Number(optional)</TableCell>
                          <TableCell>Email Address(optional)</TableCell>
                          <TableCell style={{ width: "12%" }}>Access Needed?</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {familyMembers == null
                          ? renderRows()
                          : familyMembers.map((member, index) => (
                              <TableRow key={index}>
                                <TableCell>
                                  <input
                                    value={member.firstName}
                                    className="form-control"
                                    placeholder="First Name"
                                    type="text"
                                    onChange={(event) => {
                                      const updatedFamilyMembers = [...familyMembers];
                                      updatedFamilyMembers[index].firstName = event.target.value;
                                      setFamilyMembers(updatedFamilyMembers);
                                    }}
                                  ></input>
                                </TableCell>
                                <TableCell>
                                  <select
                                    value={member.relationShipWithUser}
                                    className="form-control"
                                    onChange={(event) => {
                                      const updatedFamilyMembers = [...familyMembers];
                                      updatedFamilyMembers[index].relationShipWithUser = event.target.value;
                                      setFamilyMembers(updatedFamilyMembers);
                                    }}
                                  >
                                    <option
                                      value=""
                                      disabled
                                      selected
                                    >
                                      Relationship
                                    </option>
                                    <option value="wife">Wife</option>
                                    <option value="son">Son</option>
                                    <option value="daughter">Daughter</option>
                                    <option value="father">Father</option>
                                    <option value="mother">Mother</option>
                                  </select>
                                </TableCell>
                                <TableCell>
                                  <input
                                    value={member.dateOfBirth}
                                    className="form-control"
                                    type="date"
                                    onChange={(event) => {
                                      const updatedFamilyMembers = [...familyMembers];
                                      updatedFamilyMembers[index].dateOfBirth = event.target.value;
                                      setFamilyMembers(updatedFamilyMembers);
                                    }}
                                  ></input>
                                </TableCell>
                                <TableCell>
                                  <Input
                                    name={`livingWithUser${index}`}
                                    type="radio"
                                    checked={member.livingWithUser === true}
                                    value="true"
                                    onChange={(event) => {
                                      const updatedFamilyMembers = [...familyMembers];
                                      updatedFamilyMembers[index].livingWithUser = event.target.value === "true";
                                      setFamilyMembers(updatedFamilyMembers);
                                    }}
                                  />
                                  &nbsp;
                                  <Label
                                    check
                                    className="ml-1"
                                  >
                                    Yes
                                  </Label>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  <Input
                                    name={`livingWithUser${index}`}
                                    type="radio"
                                    checked={member.livingWithUser === false}
                                    value="false"
                                    onChange={(event) => {
                                      const updatedFamilyMembers = [...familyMembers];
                                      updatedFamilyMembers[index].livingWithUser = event.target.value === "true";
                                      setFamilyMembers(updatedFamilyMembers);
                                    }}
                                  />
                                  &nbsp;
                                  <Label
                                    check
                                    className="ml-1"
                                  >
                                    No
                                  </Label>
                                </TableCell>
                                <TableCell>
                                  <input
                                    value={member.phoneNumber}
                                    className="form-control"
                                    placeholder="Phone Number"
                                    type="number"
                                    onChange={(event) => {
                                      const updatedFamilyMembers = [...familyMembers];
                                      updatedFamilyMembers[index].phoneNumber = event.target.value;
                                      setFamilyMembers(updatedFamilyMembers);
                                    }}
                                  ></input>
                                </TableCell>
                                <TableCell>
                                  <input
                                    value={member.email}
                                    className="form-control"
                                    placeholder="xyz@gmail.com"
                                    type="email"
                                    onChange={(event) => {
                                      const updatedFamilyMembers = [...familyMembers];
                                      updatedFamilyMembers[index].email = event.target.value;
                                      setFamilyMembers(updatedFamilyMembers);
                                    }}
                                  ></input>
                                </TableCell>
                                <TableCell>
                                  <Input
                                    name={`accessNeeded${index}`}
                                    type="radio"
                                    checked={member.accessNeeded === true}
                                    value="true"
                                    onChange={(event) => {
                                      const updatedFamilyMembers = [...familyMembers];
                                      updatedFamilyMembers[index].accessNeeded = event.target.value === "true";
                                      setFamilyMembers(updatedFamilyMembers);
                                    }}
                                  />
                                  &nbsp;
                                  <Label
                                    check
                                    className="ml-1"
                                  >
                                    Yes
                                  </Label>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  <Input
                                    name={`accessNeeded${index}`}
                                    type="radio"
                                    checked={member.accessNeeded === false}
                                    value="false"
                                    onChange={(event) => {
                                      const updatedFamilyMembers = [...familyMembers];
                                      updatedFamilyMembers[index].accessNeeded = event.target.value === "true";
                                      setFamilyMembers(updatedFamilyMembers);
                                    }}
                                  />
                                  &nbsp;
                                  <Label
                                    check
                                    className="ml-1"
                                  >
                                    No
                                  </Label>
                                </TableCell>
                              </TableRow>
                            ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  {/* <MUITable data={visibleRows2} columns={columns} isLoading={isLoading} filterDate={filterDate} filterLabName={filterLabName} setSelectedRowData={setSelectedRowData} /> */}
                  <div style={{ marginTop: "-32px", marginInlineEnd: "auto" }}>
                    <span>
                      <b>Note :</b> Phone Number is required of your spouse.
                    </span>
                    <br></br>
                    <CircleIcon
                      fontSize="xx-small"
                      style={{ width: "11px", margin: "-3px" }}
                    />
                    <span style={{ marginLeft: "10px" }}>In Access put "Yes" if you would like for the specific family member to be able to access this health account.</span>
                    <br></br>
                    <CircleIcon
                      fontSize="xx-small"
                      style={{ width: "11px", margin: "-3px" }}
                    />
                    <span style={{ marginLeft: "10px" }}>Email and Phone Number is required for all the family member requiring access.</span>
                    <br></br>
                    <CircleIcon
                      fontSize="xx-small"
                      style={{ width: "11px", margin: "-3px" }}
                    />
                    <span style={{ marginLeft: "10px" }}>Children below 18 years cannot be given access unless requested by parents.</span>
                    <br></br>
                    <CircleIcon
                      fontSize="xx-small"
                      style={{ width: "11px", margin: "-3px" }}
                    />
                    <span style={{ marginLeft: "10px" }}>
                      Till your child gets married, he or she can remain as part of your family and avail of our health services.
                      <br /> After marriage, they need to change to their individual or their own family account.
                    </span>
                  </div>
                </Grid>
              )}
              <div
                className="submit-button mt-3"
                style={{ textAlign: "end" }}
              >
                <button
                  type="submit"
                  className="base"
                >
                  Next
                </button>
              </div>
            </form>
          )}
        </FormProvider>
      </div>
    </>
  );
};

export default Step1;
