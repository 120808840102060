import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import Spinner from "../../shared/Spinner";
import NutritionistCard from "../LifeStyleRiskAssessment/components/NutritionistCard";
import AppointmentScheduler from "../LifeStyleRiskAssessment/components/AppointmentScheduler";

const DietitianCareServices = ({ partner = null }) => {
  const [DietitianLoading, setDietitianLoading] = useState(false);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [selectedDietitian, setSelectedDietitian] = useState(null);
  const [DietitianData, setDietitianData] = useState([]);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("");
  const [modal, setModal] = useState(false);
  const [appointmentmodal, setappointmentmodal] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [scheduleId, setscheduleId] = useState(null);
  const toggle = () => setModal(!modal);
  const appointmentmodaltoggle = () => setappointmentmodal(!appointmentmodal);

  const handleDietitianSelect = (doctor, type) => {
    setSelectedDietitian(doctor); // Set the selected doctor data
    setType(type);
    // Find the corresponding price for the selected type
    const selectedPrice =
      doctor.partnerConsultantPrices?.find((price) => price.typeOfCall === type) ||
      null;
    console.log("selectedPrice", selectedPrice);
    setSelectedPrice(selectedPrice);
    setappointmentmodal(true);
    setModal(!modal);
  };
  const onAppointmentSelect = (sessionId) => {
    setappointmentmodal(!appointmentmodal);
    setOpen(true);
    setscheduleId(sessionId.sessionId);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const getPaymentSuccessBaseUrl = () => {
    const baseUrl = window.location.href;
    let arr = baseUrl.split("/");
    let newPath = `${arr[0]}//${arr[2]}/patient-online-consultation`;
    return encodeURI(newPath); // Encode the URL
  };

  const handlePayment = () => {
    const formData = {
      planId: selectedPrice.id,
      dietitianId: selectedDietitian.userId,
      scheduleId: scheduleId,
      // lrId: NutritionistLrid,
    };
    setPaymentLoading(true);
    AxiosInstance.post(
      `/payment/dietitian-create-order?successURL=${encodeURIComponent(
        getPaymentSuccessBaseUrl()
      )}`,
      formData
    )
      .then((response) => {
        window.location.href = response;
        setPaymentLoading(false);
        setOpen(!open);
      })
      .catch((error) => {
        console.error("Error:", error);
        setPaymentLoading(false);
      });
  };
  const bookDietitianDetail = () => {
    setModal(!modal);
    setDietitianLoading(true);
    AxiosInstance.get(`dietitian/getAllDietitianDetail`)
      .then((response) => {
        if (response) {
          if (partner) {
            const filteredData = response.filter(
              (dietitian) => dietitian.dietitianType === "MENTAL_HEALTH"
            );
            setDietitianData(filteredData);
          } else {
            setDietitianData(response);
          }
        }
        setDietitianLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setDietitianLoading(false);
      });
  };
  useEffect(() => {
    bookDietitianDetail();
  }, []);
  return (
    <>
      <Modal
        isOpen={modal}
        toggle={toggle}
        centered
        style={{
          maxWidth: "90%", // Allows the modal to expand up to 90% of the screen width
          minWidth: "60%", // Ensures a minimum width, so it doesn't get too narrow
          width: "auto", // Automatically adjusts the width based on content
        }}
      >
        <ModalHeader
          style={{
            backgroundColor: "black",
            color: "white",
            border: "1px solid lightgrey",
          }}
          toggle={toggle}
        >
          <center>Select Dietitian to Book</center>
        </ModalHeader>

        <ModalBody
          style={{
            padding: "20px",
            maxHeight: "80vh", // Adjust the height as needed
            overflowY: "auto", // Enable vertical scroll if content exceeds maxHeight
          }}
        >
          {DietitianLoading ? (
            <Spinner />
          ) : DietitianData.length > 0 ? (
            <NutritionistCard
              nutritionistData={DietitianData}
              onDoctorSelect={handleDietitianSelect}
            ></NutritionistCard>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "40vh",
                flexDirection: "column",
              }}
            >
              <Typography variant="h6" color="textSecondary" sx={{ mb: 2 }}>
                No Dietitian Available.
              </Typography>
              <SentimentDissatisfiedIcon fontSize="large" color="disabled" />
            </Box>
          )}

          {/* <OrderDetailModel /> */}
        </ModalBody>
      </Modal>
      <Modal
        isOpen={appointmentmodal}
        toggle={appointmentmodaltoggle}
        centered
        style={{
          maxWidth: "90%", // Allows the modal to expand up to 90% of the screen width
          minWidth: "60%", // Ensures a minimum width, so it doesn't get too narrow
          width: "auto", // Automatically adjusts the width based on content
        }}
      >
        <ModalHeader
          style={{
            backgroundColor: "black",
            color: "white",
            border: "1px solid lightgrey",
          }}
          toggle={appointmentmodaltoggle}
        >
          <center>Select Date/Time to Book Appointment</center>
        </ModalHeader>
        <ModalBody
          style={{
            padding: "20px",
            maxHeight: "80vh", // Adjust the height as needed
            overflowY: "auto", // Enable vertical scroll if content exceeds maxHeight
          }}
        >
          <AppointmentScheduler
            onAppointmentSelect={onAppointmentSelect}
            type={type}
            selectedDietician={selectedDietitian}
            selectedPrice={selectedPrice}
          ></AppointmentScheduler>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={open}
        toggle={handleClose}
        centered
        modalClassName="custom-modal"
      >
        <ModalHeader
          style={{
            backgroundColor: "black",
            color: "white",
            border: "1px solid lightgrey",
          }}
          toggle={handleClose}
        >
          <Typography variant="h6" component="h2">
            Complete your Payment
          </Typography>
        </ModalHeader>
        <ModalBody className="modal-body-centered">
          <Typography
            variant="h5"
            sx={{ color: "purple", fontWeight: "600", fontSize: "1.75rem" }}
            component="h5"
          >
            Uncurl: Health Services
          </Typography>
          <Typography
            variant="h6"
            // sx={{ color: "purple" }}
            component="h6"
          >
            Clinical Dietitian Consulting
          </Typography>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={6} sx={{ textAlign: "right" }}>
              <Typography variant="h6">Service Type:</Typography>
              <Typography variant="h6">Order Value:</Typography>
              {/* <Typography variant="body1">Surcharge:</Typography> */}
              <Typography variant="body1">Gst:</Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "left" }}>
              <Typography variant="h6">
                {selectedPrice && selectedPrice.typeOfCall}
              </Typography>
              <Typography variant="h6">
                ₹{selectedPrice != null && selectedPrice.clientPrice}
              </Typography>
              <Typography variant="body1">
                ₹{selectedPrice != null && parseInt(selectedPrice.gstAmount)}
              </Typography>
            </Grid>
          </Grid>

          {/* Line above the Total */}
          <Box
            sx={{
              borderTop: "2px solid lightgrey",
              mt: 2,
              mb: 2,
              width: "100%",
            }}
          />

          <Grid container spacing={2}>
            <Grid item xs={6} sx={{ textAlign: "right" }}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                Total:
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "left" }}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                ₹
                {selectedPrice &&
                  selectedPrice.clientPrice + parseInt(selectedPrice.gstAmount)}
              </Typography>
            </Grid>
          </Grid>

          {/* Line below the Total */}
          <Box
            sx={{
              borderTop: "2px solid lightgrey",
              mt: 2,
              mb: 2,
              width: "100%",
            }}
          />
          <Typography variant="body1" sx={{ mb: 3 }}>
            This amount will be charged to your account.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{
              backgroundColor: "#5353e9",
              "&:hover": { backgroundColor: "#c60176" },
            }}
            onClick={handlePayment} // Add your payment handling function here
            disabled={paymentLoading}
          >
            {paymentLoading ? (
              <CircularProgress color="inherit" size={24} />
            ) : (
              "Confirm & Pay"
            )}
          </Button>
        </ModalBody>
      </Modal>
    </>
  );
};

export default DietitianCareServices;
