import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  Select,
  MenuItem,
  IconButton,
  TextField,
  Grid,
  FormHelperText,
  Chip,
} from "@mui/material";
import { ModalHeader, ModalBody } from "reactstrap";
import Dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2";
import PriceModal from "./paymentModal";
import { ClassNames } from "@emotion/react";
import dayjs from "dayjs";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import Spinner from "../../shared/Spinner";

const FreeSessionModal = ({
  open,
  handleClose,
  sessionType,
  userId,
  trainerData,
  IsFreeSession,
}) => {
  console.log("userId", userId);
  console.log("trainer", trainerData);
  const [freeDemoTimingsMorning, setFreeDemoTimingsMorning] = useState([]);
  const [freeDemoTimingsEvening, setFreeDemoTimingsEvening] = useState([]);
  const [trainerSlotList, setTrainerSlotList] = useState([]);
  const [paymentModal, setPaymentModal] = useState(false);
  const [formData, setFormData] = useState({
    selectedDate: null,
    selectedTime: "",
    errorDate: "",
    errorTime: "",
  });
  Dayjs.extend(utc);
  dayjs.extend(isSameOrAfter);
  const [membershipFormData, setMembershipFormData] = useState({
    id: null,
    selectedPlan: "",
    price: null,
    errors: {
      plan: false,
      price: false,
    },
  });
  const [selectedSession, setSelectedSession] = useState(null);
  const [selectedSlotIds, setSelectedSlotIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleSessionSelect = (session) => {
    setSelectedSession(session);
    setIsLoading(true);
    AxiosInstance.get(
      `trainer/get-available-slot/${trainerData.userId}/${session.className}/${session.mode}/${session.session}/${session.timingFrom}/${session.timingTo}`
    )
      .then((response) => {
        if (response) {
          const today = new Date();
          const filteredSlots = response
            // .filter(
            //   (slot) => new Date(slot.slotDate.replace(/-/g, "/")) >= today
            // ) // Convert and filter dates
            .map((slot) => {
              // const dateParts = slot.slotDate.split("-");
              // const formattedDate = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`; // Convert yyyy-mm-dd → mm/dd/yyyy
              // Convert 24-hour time to 12-hour format with AM/PM
              // console.log("formattedDate", formattedDate);
              const formatTime = (time) => {
                let [hour, minute] = time.split(":").map(Number);
                let period = hour >= 12 ? "PM" : "AM";
                hour = hour % 12 || 12; // Convert 0 to 12
                return `${hour}:${minute
                  .toString()
                  .padStart(2, "0")} ${period}`;
              };

              const formatDate = (dateStr) => {
                let [year, month, day] = dateStr.split("-");
                return `${day}/${month}/${year}`; // Convert to DD/MM/YYYY
              };

              return {
                ...slot,
                formattedDate: new Date(slot.slotDate.replace(/-/g, "/")),
                formattedDateString: formatDate(slot.slotDate),
                formattedStartTime: formatTime(slot.startTime),
                formattedEndTime: formatTime(slot.endTime),
              };
            })
            .filter((slot) => slot.formattedDate >= today) // Keep future dates
            .sort((a, b) => a.formattedDate - b.formattedDate);
          setTrainerSlotList(filteredSlots);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const plans =
    trainerData.trainerPriceList &&
    trainerData.trainerPriceList.filter(
      (data) => data.planName === sessionType
    );
  const workDetail =
    trainerData.trainerPriceList && JSON.parse(trainerData.workDetail);

  Dayjs.extend(utc);
  const handlePlanChange = (event) => {
    const selectedValue = event.target.value;

    // Find the corresponding price
    const selectedPlanObj = plans.find(
      (plan) => plan.duration === selectedValue
    );
    // Update state with selected plan, price, and reset errors
    setMembershipFormData((prev) => ({
      ...prev,
      id: selectedPlanObj.id,
      selectedPlan: selectedValue,
      price: selectedPlanObj ? selectedPlanObj.clientPrice : "",
      gstAmount: selectedPlanObj ? selectedPlanObj.gstAmount : 0,
    }));
  };

  const handleBookAppointment = (gymData) => {
    let isValid = true;
    let errors = { errorDate: "", errorTime: "" };

    if (!formData.selectedDate) {
      errors.errorDate = "Please enter date";
      isValid = false;
    }

    if (!formData.selectedTime) {
      errors.errorTime = "Please enter time";
      isValid = false;
    }

    setFormData({ ...formData, ...errors });

    if (isValid) {
      console.log("Booking Details:", {
        date: formData.selectedDate.format("DD/MM/YYYY"),
        time: formData.selectedTime,
      });
      const sendForMdata = {
        partnerUserId: trainerData?.userId,
        sessionClassName: sessionType,
        sessionDate: formData.selectedDate,
        sessionTime: formData.selectedTime,
      };

      AxiosInstance.post("trainer/create-free-session", sendForMdata)
        .then((response) => {
          handleClose();
          Swal.fire({
            title: "Appointment Booked!",
            text: `Your appointment is booked for ${formData.selectedDate.format(
              "DD/MM/YYYY"
            )} at ${formData.selectedTime}.`,
            icon: "success",
            confirmButtonText: "OK",
            customClass: {
              popup: "swal-popup",
            },
            didOpen: () => {
              const swalPopup = document.querySelector(".swal-popup");
              if (swalPopup) {
                swalPopup.style.zIndex = "999999";
              }
            },
          });
        })
        .catch((error) => {
          console.log("Error:", error);
        });
    }
  };
  const handleBookMembership = () => {
    const { selectedPlan, price } = membershipFormData;

    // Validate fields
    const newErrors = {
      plan: !selectedPlan,
      price: !price,
    };

    setMembershipFormData((prev) => ({
      ...prev,
      errors: newErrors,
    }));

    // Proceed only if both fields are filled
    if (selectedPlan && price) {
      setPaymentModal(true);
    }
  };
  const getPaymentSuccessBaseUrl = () => {
    const baseUrl = window.location.href;
    let arr = baseUrl.split("/");
    let newPath = `${arr[0]}//${arr[2]}/patient-online-consultation`;
    return encodeURI(newPath); // Encode the URL
  };

  const handlePayment = (gymUserId) => {
    const formData = {
      // amount: membershipFormData.price,  // Use price here instead of total
      partnerUserId: gymUserId,
      partnerType: "GYM",
      planDuration: membershipFormData.selectedPlan,
      ClassNames: "Excerise",
    };

    AxiosInstance.post(
      `payment/create-subscription-gym-and-trainer?successURL=${encodeURIComponent(
        getPaymentSuccessBaseUrl()
      )}`,
      formData
    )
      .then((response) => {
        setMembershipFormData({
          selectedPlan: "",
          price: null,
          errors: {
            plan: false,
            price: false,
          },
        });
        setPaymentModal(false);
        onClose();
        window.location.href = response.data; // Assuming the response is a URL or location to redirect
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    if (sessionType) {
      if (trainerData?.freeDemoTimingsMorning) {
        setFreeDemoTimingsMorning(
          JSON.parse(trainerData?.freeDemoTimingsMorning)
        );
      }
      if (trainerData?.freeDemoTimingsEvening) {
        setFreeDemoTimingsEvening(
          JSON.parse(trainerData?.freeDemoTimingsEvening)
        );
      }
    }
  }, [trainerData, sessionType]);

  // useEffect(() => {}, []);

  const morningFrom = freeDemoTimingsMorning?.timing?.from;
  const morningTo = freeDemoTimingsMorning?.timing?.to;
  const eveningFrom = freeDemoTimingsEvening?.timing?.from;
  const eveningTo = freeDemoTimingsEvening?.timing?.to;

  const generateTimeSlots = (from, to) => {
    const slots = [];
    let startTime = dayjs(from, "HH:mm"); // Convert to Day.js object
    let endTime = dayjs(to, "HH:mm");

    while (startTime.isBefore(endTime)) {
      let nextHour = startTime.add(1, "hour");

      // Ensure the next slot is within the range
      if (nextHour.isAfter(endTime)) break;

      slots.push(
        `${startTime.format("h:mm A")} - ${nextHour.format("h:mm A")}`
      );
      startTime = nextHour; // Move to next interval
    }

    return slots;
  };

  // Generate morning and evening time slots
  const morningSlots =
    freeDemoTimingsMorning?.timing?.from && freeDemoTimingsMorning?.timing?.to
      ? generateTimeSlots(
          freeDemoTimingsMorning.timing.from,
          freeDemoTimingsMorning.timing.to
        )
      : [];

  const eveningSlots =
    freeDemoTimingsEvening?.timing?.from && freeDemoTimingsEvening?.timing?.to
      ? generateTimeSlots(
          freeDemoTimingsEvening.timing.from,
          freeDemoTimingsEvening.timing.to
        )
      : [];

  const timeSlots = [...morningSlots, ...eveningSlots]; // Combine both slots

  const handleSlotChange = (event) => {
    const selectedSlot = trainerSlotList.find(
      (slot) => slot.slotId === event.target.value
    );

    if (selectedSlot) {
      // Store all slotIds from the selected date till the last available slot
      const remainingSlotIds = trainerSlotList
        .filter((slot) => slot.formattedDate >= selectedSlot.formattedDate)
        .map((slot) => slot.slotId);

      setSelectedSlotIds(remainingSlotIds);
    }
  };
  // timeSlots.push("5:00 PM");
  console.log(
    "selectedSlotsIds",
    selectedSlotIds,
    trainerSlotList,
    workDetail,
    sessionType
  );
  return (
    <>
      {IsFreeSession ? (
        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "50%",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
              textAlign: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography id="modal-title" variant="h6">
                {IsFreeSession ? "Book Free Session" : "Book Session"}
              </Typography>
              <IconButton onClick={handleClose} size="small">
                <CloseIcon />
              </IconButton>
            </Box>
            <Box
              textAlign="center"
              p={3}
              boxShadow={3}
              free
              borderRadius={2}
              mt={2}
              border="1px solid"
            >
              <Typography variant="h6" gutterBottom>
                {IsFreeSession
                  ? `Free Appointment is available only between ${morningFrom} to ${morningTo} AM & ${eveningFrom} to ${eveningTo} PM`
                  : ""}
              </Typography>
              <Typography variant="h5" mb={2}>
                {IsFreeSession
                  ? `Book Free Appointment for ${sessionType}`
                  : ""}
              </Typography>
              <Box
                display="flex"
                justifyContent="center"
                gap={2}
                alignItems="center"
              >
                <Box display="flex" flexDirection="column" alignItems="center">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={
                        formData.selectedDate
                          ? Dayjs(formData.selectedDate).utc()
                          : null
                      }
                      minDate={Dayjs().startOf("day")}
                      onChange={(newDate) =>
                        setFormData({
                          ...formData,
                          selectedDate: newDate
                            ? newDate.utc().startOf("day")
                            : null,
                          errorDate: "",
                        })
                      }
                      format="DD/MM/YYYY"
                      renderInput={(params) => (
                        <TextField {...params} variant="standard" fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                  {formData.errorDate && (
                    <Typography color="error" variant="body2">
                      {formData.errorDate}
                    </Typography>
                  )}
                </Box>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Select
                    value={formData.selectedTime}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        selectedTime: e.target.value,
                        errorTime: "",
                      })
                    }
                    displayEmpty
                    variant="outlined"
                    error={!!formData.errorTime}
                  >
                    <MenuItem value="" disabled>
                      Select Time Slot
                    </MenuItem>
                    {timeSlots.map((time, index) => (
                      <MenuItem key={index} value={time}>
                        {time}
                      </MenuItem>
                    ))}
                  </Select>
                  {formData.errorTime && (
                    <Typography color="error" variant="body2">
                      {formData.errorTime}
                    </Typography>
                  )}
                </Box>
              </Box>
              {IsFreeSession ? (
                <>
                  {" "}
                  <Button
                    color="primary"
                    variant="contained"
                    sx={{ mt: 3 }}
                    onClick={handleBookAppointment}
                  >
                    Book
                  </Button>
                </>
              ) : (
                ""
              )}
            </Box>
          </Box>
        </Modal>
      ) : (
        <>
          <Modal open={open} onClose={handleClose}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "50%",
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                borderRadius: 2,
                textAlign: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography id="modal-title" variant="h6">
                  Book Session
                </Typography>
                <IconButton onClick={handleClose} size="small">
                  <CloseIcon />
                </IconButton>
              </Box>
              <Box
                textAlign="center"
                p={3}
                boxShadow={3}
                borderRadius={2}
                mt={2}
                border="1px solid"
              >
                <Typography variant="h5" mb={2}>
                  Book Membership
                </Typography>
                <Box
                  display="flex"
                  justifyContent="center"
                  gap={2}
                  alignItems="center"
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Typography variant="subtitle1" mb={1}>
                      Select Session
                    </Typography>
                    <Select
                      value={membershipFormData.selectedPlan}
                      onChange={handlePlanChange}
                      displayEmpty
                      fullWidth
                      // error={membershipFormData.errors.plan} // Highlight field in red if error
                      sx={{ minWidth: 200 }}
                    >
                      <MenuItem value="" disabled>
                        Select Session
                      </MenuItem>
                      {plans?.map((plan, index) => (
                        <MenuItem key={index} value={plan.duration}>
                          {plan.duration}
                        </MenuItem>
                      ))}
                    </Select>
                    {membershipFormData.errors.plan && (
                      <FormHelperText sx={{ color: "red" }}>
                        session is required
                      </FormHelperText>
                    )}
                  </Box>

                  {/* Price Field (Read-only) */}
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Typography variant="subtitle1" mb={1}>
                      Membership Price
                    </Typography>
                    <TextField
                      value={membershipFormData.price}
                      fullWidth
                      disabled
                      type="number"
                      error={membershipFormData.errors.price} // Highlight field in red if error
                      sx={{ minWidth: 200 }}
                    />
                    {membershipFormData.errors.price && (
                      <FormHelperText sx={{ color: "red" }}>
                        Price is required
                      </FormHelperText>
                    )}
                  </Box>
                </Box>

                {/* Session Selection */}
                {membershipFormData.selectedPlan !== "" && (
                  <>
                    <Typography variant="h6" mb={2} mt={1}>
                      Which time do you prefer?
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="center"
                      gap={2}
                      flexWrap="wrap"
                    >
                      {workDetail?.map((item, index) => (
                        <Button
                          key={index}
                          variant={
                            selectedSession?.session === item.session
                              ? "contained"
                              : "outlined"
                          }
                          color={
                            selectedSession?.session === item.session
                              ? "secondary"
                              : "inherit"
                          }
                          sx={{
                            minWidth: 150,
                            padding: "10px",
                            borderRadius: 2,
                            fontSize: "16px",
                          }}
                          onClick={() => handleSessionSelect(item)}
                        >
                          {item.session}
                        </Button>
                      ))}
                    </Box>

                    {isLoading ? (
                      <Spinner />
                    ) : selectedSession ? ( // Check only trainerSlotList first
                      trainerSlotList.length > 0 ? (
                        <>
                          <Box mt={3}>
                            <Typography variant="subtitle1">
                              From which date you want to Start Sessions ?
                            </Typography>
                            <Select
                              // value={
                              //   selectedSlot ? selectedSlot.formattedDateString : ""
                              // }
                              // onChange={(e) => {
                              //   const slot = trainerSlotList.find(
                              //     (s) => s.slotId === e.target.value
                              //   );
                              //   setSelectedSlot(slot);
                              //   setSelectedSlotIds((prev) =>
                              //     prev.includes(slot.slotId)
                              //       ? prev
                              //       : [...prev, slot.slotId]
                              //   );
                              // }}
                              onChange={handleSlotChange}
                              displayEmpty
                              fullWidth
                            >
                              <MenuItem value="" disabled>
                                Select Slot
                              </MenuItem>
                              {trainerSlotList.map((slot) => (
                                <MenuItem key={slot.slotId} value={slot.slotId}>
                                  {slot.formattedDateString} (
                                  {slot.formattedStartTime} -{" "}
                                  {slot.formattedEndTime})
                                </MenuItem>
                              ))}
                            </Select>
                          </Box>

                          {/* Display Timing and Weekdays */}

                          <Box mt={3} textAlign="center">
                            <Typography variant="h6" color="primary">
                              Timing: {selectedSession.timingFrom} -{" "}
                              {selectedSession.timingTo}
                            </Typography>

                            <Box
                              display="flex"
                              justifyContent="center"
                              gap={1}
                              mt={1}
                              alignItems="baseline"
                            >
                              <Typography variant="subtitle1" mt={1}>
                                Weekdays:
                              </Typography>
                              {selectedSession.weekdays.map((day, index) => (
                                <Chip
                                  key={index}
                                  label={day.name}
                                  style={{
                                    backgroundColor: "#2e7d32",
                                    color: "white",
                                  }}
                                />
                              ))}
                            </Box>
                          </Box>
                        </>
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mt: 3,
                            flexDirection: "column",
                          }}
                        >
                          <Typography
                            variant="h6"
                            color="textSecondary"
                            sx={{ mb: 1 }}
                          >
                            No Slots Available.
                          </Typography>
                          <SentimentDissatisfiedIcon
                            fontSize="large"
                            color="disabled"
                          />
                        </Box>
                      )
                    ) : (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        mt={2}
                        flexDirection="column"
                      >
                        <Typography
                          variant="h6"
                          color="textSecondary"
                          sx={{ mb: 2 }}
                        >
                          Please select a session to view slots.
                        </Typography>
                      </Box>
                    )}
                  </>
                )}
                {selectedSlotIds.length > 0 && (
                  <Button
                    color="primary"
                    variant="contained"
                    sx={{ mt: 3 }}
                    onClick={handleBookMembership}
                  >
                    Book Membership
                  </Button>
                )}
              </Box>
            </Box>
          </Modal>
          {paymentModal && (
            <PriceModal
              open={paymentModal}
              setOpen={setPaymentModal}
              trainerData={trainerData}
              slotIds={selectedSlotIds}
              userId={userId} // Pass the payment handler
              membershipFormData={membershipFormData} // Pass necessary data to PriceModal
            />
          )}
        </>
      )}
    </>
  );
};

export default FreeSessionModal;
