import { Container, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import "./formStyle.css";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { addWorkPlaceStressPulseSurvey, getWorkPlaceStressPulseSurvey } from "../../constants/apiList";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const WorkplaceStressSurvey = ({ onBack }) => {
  const [formData, setFormData] = useState({
    stressedByWork: false,
    stressUnacceptableToYou: false,
    physicalMentalHarmDistress: false,
    rateLevelOfJobStress: "",
    howLongHaveYouBeenExperiencingStressFromWork: "",
    howOftenYouFeelHighlyStressedFromJob: "",
    frequentHeadaches: "",
    prolongedFatigue: "",
    irritability: "",
    lossOfAppetite: "",
    overSensitivity: "",
    insomnia: "",
    anxiety: "",
    depression: "",
    roleAlignmentWithOrganizationalGoals: false,
    demandsOfYourJob: false,
    lackOfControl: false,
    relationshipsAtWork: false,
    change: false,
    workingEnvironment: false,
    learningOpportunities: false,
  });

  const [formStep, setFormStep] = useState(0);
  const [errors, setErrors] = useState({});

  const formNextStepHandler = () => {
    if (validate()) {
      setFormStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const formBackStepHandler = () => {
    setFormStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  // const handleRadioChange = (event, category) => {
  //   setFormData({
  //     ...formData,
  //     [category]: {
  //       ...formData[category],
  //       [event.target.name]: event.target.value,
  //     },
  //   });
  // };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validate()) {
      AxiosInstance.post(addWorkPlaceStressPulseSurvey, formData)
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Your Form Submitted Successfully!",
          });
          setTimeout(() => {
            onBack();
          }, 1000);
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  };

  const getWorkPlaceStress = () => {
    AxiosInstance.get(getWorkPlaceStressPulseSurvey)
      .then((response) => {
        setFormData(response);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  useEffect(() => {
    getWorkPlaceStress();
  }, []);

  const validate = () => {
    let tempErrors = {};
    tempErrors.howLongHaveYouBeenExperiencingStressFromWork = formData?.howLongHaveYouBeenExperiencingStressFromWork ? "" : "This field is required";
    tempErrors.howOftenYouFeelHighlyStressedFromJob = formData?.howOftenYouFeelHighlyStressedFromJob ? "" : "This field is required";
    tempErrors.rateLevelOfJobStress = formData?.rateLevelOfJobStress ? "" : "This field is required";
    setErrors(tempErrors);
    return Object.values(tempErrors).every((val) => val === "");
  };

  const symptomsList = [
    { name: "frequentHeadaches", label: "Frequent headaches" },
    { name: "prolongedFatigue", label: "Prolonged fatigue" },
    { name: "irritability", label: "Irritability" },
    { name: "lossOfAppetite", label: "Loss of Appetite" },
    { name: "overSensitivity", label: "Over-sensitivity" },
    { name: "insomnia", label: "Insomnia" },
    { name: "anxiety", label: "Anxiety" },
    { name: "depression", label: "Depression" },
  ];

  const frequencyOptions = ["Always", "Very often", "Sometimes", "Rarely", "Never"];

  const stressDurationOptions = [
    { value: "Less Than a Month", label: "Less Than A Month" },
    { value: "2 months", label: "2 months" },
    { value: "3 months", label: "3 months" },
    { value: "4 months", label: "4 months" },
    { value: "5 months", label: "5 months" },
    { value: "More than 1 year", label: "More than 1 year" },
  ];
  const rateLevelOfJobStressOptions = [
    { value: "Mild", label: "Mild" },
    { value: "Moderate", label: "Moderate" },
    { value: "Severe", label: "Severe" },
    { value: "Extreme", label: "Extreme" },
  ];

  return (
    <Container
      style={{
        borderRadius: 12,
        padding: "1.5rem",
        boxShadow: "0 0 8px -2px #00000080",
        marginTop: "15px",
      }}
    >
      <form onSubmit={handleSubmit}>
        {formStep == 0 && (
          <>
            <Typography
              variant="h5"
              fontWeight="bold"
              gutterBottom
            >
              Workplace Stress Pulse Survey
            </Typography>
            <Typography
              variant="h7"
              gutterBottom
            >
              This survey has been initiated by your organization. Please complete this survey. The result of this survey is confidential. Your name and choices will not be disclosed to your employer,
              only summary of all employee’s responses will be shared with the management team for their efforts to manage and reduce stress across the organization. Your participation is critical to
              their initiative.
            </Typography>

            <Typography
              variant="h6"
              gutterBottom
              fontWeight="bold"
              marginTop={2}
            >
              PART-I: Are you stressed by your workplace environment?
            </Typography>
            <Row>
              <Col md={4}>
                <FormControl
                  component="fieldset"
                  fullWidth
                  margin="normal"
                >
                  <FormLabel className="workPlaceLabel">1. Do you feel stressed by your work?</FormLabel>
                  <RadioGroup
                    name="stressedByWork"
                    value={formData?.stressedByWork}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                  {errors.stressedByWork && <Typography color="error">{errors.stressedByWork}</Typography>}
                </FormControl>
              </Col>
              <Col md={4}>
                <FormControl
                  component="fieldset"
                  fullWidth
                  margin="normal"
                >
                  <FormLabel className="workPlaceLabel">2. Is the level of stress unacceptable to you?</FormLabel>
                  <RadioGroup
                    name="stressUnacceptableToYou"
                    value={formData?.stressUnacceptableToYou}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                  {errors.stressUnacceptableToYou && <Typography color="error">{errors.stressUnacceptableToYou}</Typography>}
                </FormControl>
              </Col>
              <Col md={4}>
                <FormControl
                  component="fieldset"
                  fullWidth
                  margin="normal"
                >
                  <FormLabel className="workPlaceLabel">3. Is it causing you any physical or mental harm?</FormLabel>
                  <RadioGroup
                    name="physicalMentalHarmDistress"
                    value={formData?.physicalMentalHarmDistress}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                  {errors.physicalMentalHarmDistress && <Typography color="error">{errors.physicalMentalHarmDistress}</Typography>}
                </FormControl>
              </Col>
              <Col md={4}>
                <FormControl
                  component="fieldset"
                  fullWidth
                  margin="normal"
                >
                  <FormLabel className="workPlaceLabel">4. How would you rate the level of your job stress?</FormLabel>
                  <RadioGroup
                    name="rateLevelOfJobStress"
                    value={formData?.rateLevelOfJobStress}
                    onChange={handleChange}
                  >
                    {rateLevelOfJobStressOptions.map((option) => (
                      <FormControlLabel
                        key={option.value}
                        value={option.value}
                        control={<Radio />}
                        label={option.label}
                      />
                    ))}
                  </RadioGroup>
                  {errors.rateLevelOfJobStress && <Typography color="error">{errors.rateLevelOfJobStress}</Typography>}
                </FormControl>
              </Col>
              <Col md={4}>
                <FormControl
                  component="fieldset"
                  fullWidth
                  margin="normal"
                >
                  <FormLabel className="workPlaceLabel">5. How long have you been experiencing stress from work?</FormLabel>
                  <RadioGroup
                    name="howLongHaveYouBeenExperiencingStressFromWork"
                    value={formData?.howLongHaveYouBeenExperiencingStressFromWork}
                    onChange={handleChange}
                  >
                    {stressDurationOptions.map((option) => (
                      <FormControlLabel
                        key={option.value}
                        value={option.value}
                        control={<Radio />}
                        label={option.label}
                      />
                    ))}
                  </RadioGroup>
                  {errors.howLongHaveYouBeenExperiencingStressFromWork && <Typography color="error">{errors.howLongHaveYouBeenExperiencingStressFromWork}</Typography>}
                </FormControl>
              </Col>
              <Col md={4}>
                <FormControl
                  component="fieldset"
                  fullWidth
                  margin="normal"
                >
                  <FormLabel className="workPlaceLabel">6. How often do you feel highly stressed from your job?</FormLabel>
                  <RadioGroup
                    name="howOftenYouFeelHighlyStressedFromJob"
                    value={formData?.howOftenYouFeelHighlyStressedFromJob}
                    onChange={handleChange}
                  >
                    {frequencyOptions.map((option) => (
                      <FormControlLabel
                        key={option}
                        value={option}
                        control={<Radio />}
                        label={option}
                      />
                    ))}
                  </RadioGroup>
                  {errors.howOftenYouFeelHighlyStressedFromJob && <Typography color="error">{errors.howOftenYouFeelHighlyStressedFromJob}</Typography>}
                </FormControl>
              </Col>
              <Col md={12}>
                <FormControl
                  component="fieldset"
                  fullWidth
                  margin="normal"
                >
                  <FormLabel className="workPlaceLabel">7. Please let us know how often you have experienced the following as a possible result of stress</FormLabel>
                  {symptomsList.map(({ name, label }) => (
                    <FormControl
                      component="fieldset"
                      fullWidth
                      key={name}
                    >
                      <Row className="align-items-center">
                        <Col md={4}>
                          <FormLabel style={{ color: "#333" }}>{label}</FormLabel>
                        </Col>
                        <Col md={8}>
                          <RadioGroup
                            row
                            name={name}
                            value={formData ? formData[name]: ""}
                            onChange={handleChange}
                          >
                            {frequencyOptions.map((option) => (
                              <FormControlLabel
                                key={option}
                                value={option}
                                control={<Radio />}
                                label={option}
                              />
                            ))}
                          </RadioGroup>
                        </Col>
                      </Row>
                    </FormControl>
                  ))}
                </FormControl>
              </Col>
              <div className="d-flex justify-content-end">
                <div className="submit-button">
                  <button
                    type="button"
                    className="base"
                    onClick={formNextStepHandler}
                  >
                    Next
                  </button>
                </div>
              </div>
            </Row>
          </>
        )}
        {formStep == 1 && (
          <>
            <Typography
              variant="h6"
              gutterBottom
              fontWeight="bold"
              marginTop={2}
            >
              PART-II: Identifying Stressors
            </Typography>
            <Typography
              variant="h7"
              gutterBottom
              fontWeight="bold"
              marginTop={2}
            >
              This part identifies what may be causing the stress. Please fill out each question.
            </Typography>

            <Row>
              <Col md={4}>
                <FormControl
                  component="fieldset"
                  fullWidth
                  margin="normal"
                >
                  <FormLabel className="workPlaceLabel">A. Role Alignment with Organizational Goals</FormLabel>
                  <RadioGroup
                    name="roleAlignmentWithOrganizationalGoals"
                    value={formData?.roleAlignmentWithOrganizationalGoals}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Col>
              <Col md={4}>
                <FormControl
                  component="fieldset"
                  fullWidth
                  margin="normal"
                >
                  <FormLabel className="workPlaceLabel">B. Demands of your job? e.g. too much work, insufficient time, long working hours, not enough rest breaks</FormLabel>
                  <RadioGroup
                    name="demandsOfYourJob"
                    value={formData?.demandsOfYourJob}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Col>
              <Col md={4}>
                <FormControl
                  component="fieldset"
                  fullWidth
                  margin="normal"
                >
                  <FormLabel className="workPlaceLabel">C. Lack of control? e.g. unrealistic deadlines, unremitting pressure to perform well, too much/little supervision</FormLabel>
                  <RadioGroup
                    name="lackOfControl"
                    value={formData?.lackOfControl}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Col>
              <Col md={4}>
                <FormControl
                  component="fieldset"
                  fullWidth
                  margin="normal"
                >
                  <FormLabel className="workPlaceLabel">D. Relationships at work? e.g. bullying, harassment, lack of </FormLabel>
                  <RadioGroup
                    name="relationshipsAtWork"
                    value={formData?.relationshipsAtWork}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Col>
              <Col md={4}>
                <FormControl
                  component="fieldset"
                  fullWidth
                  margin="normal"
                >
                  <FormLabel className="workPlaceLabel">E. Change? e.g. uncertainty about your future, lack of consultation </FormLabel>
                  <RadioGroup
                    name="change"
                    value={formData?.change}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Col>
              <Col md={4}>
                <FormControl
                  component="fieldset"
                  fullWidth
                  margin="normal"
                >
                  <FormLabel className="workPlaceLabel">
                    D. Working environment? e.g. temperature in the work place, overcrowding or cramped work areas, poor eating and rest facilities, badly designed, unsuitable or uncomfortable
                    equipment – such as computer workstations
                  </FormLabel>
                  <RadioGroup
                    name="workingEnvironment"
                    value={formData?.workingEnvironment}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Col>
              <Col md={4}>
                <FormControl
                  component="fieldset"
                  fullWidth
                  margin="normal"
                >
                  <FormLabel className="workPlaceLabel">D. Learning opportunities? e.g. insufficient time or resources for CPD and courses</FormLabel>
                  <RadioGroup
                    name="learningOpportunities"
                    value={formData?.learningOpportunities}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Col>
            </Row>

            <div className="d-flex justify-content-between mt-3">
              <div className="submit-button">
                <button
                  className="base"
                  onClick={formBackStepHandler}
                >
                  Back
                </button>
              </div>
              <div className="submit-button">
                <button
                  type="submit"
                  className="base"
                >
                  Submit
                </button>
              </div>
            </div>
          </>
        )}
      </form>
    </Container>
  );
};

export default WorkplaceStressSurvey;
