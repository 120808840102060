import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  Tooltip,
  Avatar,
  Modal,
  Box,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/system";
import { Col, Row } from "reactstrap";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import CloseIcon from "@mui/icons-material/Close";
import "../LifeStyleRiskAssessment.css";

// Styled component for highlighted card
const HighlightedCard = styled(Card)(({ theme, isSelected }) => ({
  transition: "all 0.3s ease",
  "&:hover": {
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
  },
  height: "100%",
  flexGrow: 1,
}));

const NutritionistCard = ({ nutritionistData, onDoctorSelect }) => {
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [downloadedImages, setDownloadedImages] = useState({});

  const handleOpenModal = (files) => {
    setSelectedFiles(files);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleCardClick = (doctor) => {
    setSelectedDoctor(doctor); // Set selected doctor locally
  };

  const handleBookHereClick = (doctor, type) => {
    setSelectedDoctor(doctor);
    if (doctor) {
      onDoctorSelect(doctor, type); // Send selected doctor data to parent component
    }
  };

  const truncateBio = (bio, maxLength) => {
    if (bio.length > maxLength) {
      return bio.substring(0, maxLength) + "...";
    }
    return bio;
  };

  const getAvatarColor = (id) => {
    const colors = ["#FFC300", "#33A1FD", "#DAF7A6", "#FF33F6", "#33FF95"];
    return colors[id % colors.length]; // Cycles through the colors array
  };

  const handleDownload = async (filePath) => {
    if (!filePath) return;

    const downloadApi = "consultation/downloadDietFile";
    const encodedUrl = encodeURIComponent(filePath);
    const authToken = window.sessionStorage.getItem("token");

    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}${downloadApi}?path=${encodedUrl}`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to download the file.");
      }

      // Create a Blob from the response and download it
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filePath.split("/").pop(); // Extract the file name from the path
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error during download:", error);
    }
  };

  const handleProfileDownload = async (filePath, userId) => {
    if (!filePath) return;

    const downloadApi = "doctor/download-profile-pic";
    const encodedUrl = encodeURIComponent(filePath);
    const authToken = window.sessionStorage.getItem("token");

    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}${downloadApi}/${filePath}`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to download the file.");
      }

      const blob = await response.blob();
      const objectUrl = URL.createObjectURL(blob); // Create a temporary URL

      // Store the downloaded image in state
      setDownloadedImages((prev) => ({ ...prev, [userId]: objectUrl }));
    } catch (error) {
      console.error("Error during download:", error);
    }
  };

  useEffect(() => {
    nutritionistData.forEach((doctor) => {
      if (!downloadedImages[doctor.userId]) {
        const filePath = `${doctor.firstName.toLowerCase()}.jpg`;
        handleProfileDownload(filePath, doctor.userId);
      }
    });
  }, [nutritionistData]);

  return (
    <div>
      <Row className="d-flex flex-column">
        {nutritionistData?.map((doctor) => (
          <Col key={doctor?.userId} className="mb-4 ">
            <HighlightedCard
              isSelected={selectedDoctor?.userId === doctor?.userId}
              // onClick={() => handleCardClick(doctor)}
              sx={{ maxWidth: "100%", minHeight: "350px" }}
            >
              <CardContent>
                <Grid
                  container
                  alignItems="center"
                  spacing={2}
                  style={{ marginBottom: "8px" }}
                >
                  <Grid item>
                    <Avatar
                      // sx={{
                      //   bgcolor: getAvatarColor(doctor?.userId), // Dynamic background color
                      //   color: "white", // Text color
                      // }}
                      sx={{
                        bgcolor: downloadedImages[doctor?.userId]
                          ? "transparent"
                          : getAvatarColor(doctor?.userId),
                        color: downloadedImages[doctor?.userId]
                          ? "inherit"
                          : "white",
                      }}
                      src={downloadedImages[doctor?.userId] || ""}
                    >
                      {doctor?.firstName?.[0] || ""}
                      {doctor?.lastName?.[0] || ""}
                    </Avatar>
                  </Grid>
                  <Grid item>
                    <Typography variant="h5" style={{ fontWeight: "bold" }}>
                      {doctor?.firstName} {doctor?.lastName}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  {/* Left Column */}
                  <Grid item xs={12} md={6}>
                    <Tooltip
                      title={doctor?.bio}
                      arrow
                      classes={{
                        tooltip: "custom-tooltip",
                        arrow: "custom-arrow",
                      }}
                    >
                      <Typography
                        variant="body1"
                        style={{
                          marginBottom: "8px",
                          fontSize: "1.1rem",
                          cursor: "pointer",
                          display: "-webkit-box",
                          WebkitLineClamp: 3, // Limit to 3 lines
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <strong>Bio:</strong> {truncateBio(doctor?.bio, 100)}
                      </Typography>
                    </Tooltip>
                    <Typography variant="body1" style={{ marginBottom: "8px" }}>
                      <strong>Google Rating:</strong>{" "}
                      {doctor?.googleRating || "N/A"}
                    </Typography>

                    <Typography variant="body1" style={{ marginBottom: "8px" }}>
                      <strong>Self-Services Rating:</strong>{" "}
                      {doctor?.selfRating || "N/A"}
                    </Typography>

                    <Typography variant="body1" style={{ marginBottom: "8px" }}>
                      <strong>Licensed/Registered Dietician:</strong>{" "}
                      {doctor?.registeredBusiness ? "Yes" : "No"}
                    </Typography>
                    <Typography variant="body1" style={{ marginBottom: "8px" }}>
                      <strong>Education Qualifications:</strong>{" "}
                      {doctor?.educationQualifications}
                    </Typography>
                    {doctor?.yearsWorkingAsDietitian && (
                      <Typography
                        variant="body1"
                        style={{ marginBottom: "8px" }}
                      >
                        <strong>Total Years Of Experience:</strong>{" "}
                        {doctor?.yearsWorkingAsDietitian}
                      </Typography>
                    )}
                    <Typography variant="body1" style={{ marginBottom: "8px" }}>
                      <strong>Ever Worked As Clinical Dietitian ?</strong>{" "}
                      {doctor?.everWorkedAsClinicalDietitian ? "Yes" : "No"}
                    </Typography>
                    {doctor?.everWorkedAsClinicalDietitian && (
                      <div
                        style={{
                          marginLeft: "20px",
                          paddingLeft: "10px",
                          borderLeft: "2px solid #ccc",
                        }}
                      >
                        <Typography
                          variant="body2"
                          style={{ marginBottom: "8px" }}
                        >
                          <strong>Name of Hospital:</strong>{" "}
                          {doctor?.hospitalName || "N/A"}
                        </Typography>
                        <Typography
                          variant="body2"
                          style={{ marginBottom: "8px" }}
                        >
                          <strong>Years of Experience:</strong>{" "}
                          {doctor?.yearsWorkingAtHospital || "N/A"}
                        </Typography>
                        <Typography
                          variant="body2"
                          style={{ marginBottom: "8px" }}
                        >
                          <strong>Specialization:</strong>{" "}
                          {doctor?.specialization || "N/A"}
                        </Typography>
                      </div>
                    )}
                    <Typography variant="body1" style={{ marginBottom: "8px" }}>
                      <strong>
                        Expert Dietician, helping clients with following
                        diseases:{" "}
                      </strong>
                      {doctor?.nutritionConsultingForDiseasesForClients}
                    </Typography>
                    <Typography variant="body1" style={{ marginBottom: "8px" }}>
                      <strong>Age Group qualified for:</strong>{" "}
                      {doctor?.clientAgeGroupExperience ||
                        "3 and more (including seniors)"}
                    </Typography>
                  </Grid>

                  {/* Right Column */}
                  <Grid item xs={12} md={6}>
                    <div
                      style={{
                        marginBottom: "16px",
                        borderRadius: "8px",
                        overflow: "hidden",
                        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                        border: "1px solid black",
                      }}
                    >
                      <Typography
                        variant="h6"
                        style={{
                          marginBottom: "8px",
                          color: "#1976d2",
                          textAlign: "center", // Center the title
                          backgroundColor: "#e3f2fd", // Light background for the title
                          padding: "8px",
                          borderRadius: "8px 8px 0 0", // Rounded corners on top
                        }}
                      >
                        Experience Categories
                      </Typography>
                      <Grid
                        container
                        spacing={1}
                        style={{
                          border: "1px solid #ccc",
                          borderRadius: "0 0 8px 8px", // Rounded corners on bottom
                          backgroundColor: "#ffffff", // White background for the grid
                          padding: "16px",
                        }}
                      >
                        {/* Column Headings */}
                        <Grid
                          container
                          spacing={1}
                          style={{ marginBottom: "8px" }}
                        >
                          <Grid item xs={3}>
                            <Typography
                              variant="body1"
                              style={{ fontWeight: "bold", color: "#333" }}
                            >
                              Disease:
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography
                              variant="body1"
                              style={{ fontWeight: "bold", color: "#333" }}
                            >
                              Client Age Group:
                            </Typography>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography
                              variant="body1"
                              style={{ fontWeight: "bold", color: "#333" }}
                            >
                              Number of Clients Helped So Far:
                            </Typography>
                          </Grid>
                        </Grid>

                        {/* Mapping over the experienceCategory array to display rows */}
                        {doctor?.experienceCategory.map((item, index) => (
                          <Grid
                            container
                            key={index}
                            spacing={1}
                            style={{ marginBottom: "8px" }}
                          >
                            {/* Disease Column */}
                            <Grid item xs={3}>
                              <Typography variant="body1">
                                {item.diseases}
                              </Typography>
                            </Grid>

                            {/* Client Age Group Column */}
                            <Grid item xs={4}>
                              <Typography variant="body1">
                                {item.ageGroup}
                              </Typography>
                            </Grid>

                            {/* Number of Clients Helped Column */}
                            <Grid item xs={5}>
                              <Typography variant="body1">
                                {item.patientHandled}
                              </Typography>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    </div>

                    {/* Expert Dietician Section */}

                    {/* Client Reference Section */}
                    {doctor?.refrences && (
                      <div
                        style={{
                          marginBottom: "16px",
                          borderRadius: "8px",
                          overflow: "hidden",
                          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                          border: "1px solid black",
                        }}
                      >
                        <Typography
                          variant="h6"
                          style={{
                            border: "1px solid #ccc",
                            color: "#1976d2",
                            textAlign: "center", // Center the title
                            backgroundColor: "#e3f2fd", // Light background for the title
                            padding: "8px",
                            borderRadius: "8px 8px 0 0", // Rounded corners on top
                          }}
                        >
                          Here is what Clients are saying about{" "}
                          {doctor?.firstName} (References)
                        </Typography>
                        <div
                          style={{
                            backgroundColor: "#ffffff", // White background for the testimonials
                            padding: "16px",
                            borderRadius: "0 0 8px 8px", // Rounded corners on bottom
                          }}
                        >
                          <Typography
                            variant="body1"
                            style={{
                              marginBottom: "8px",
                              fontStyle: "italic",
                              color: "#555",
                            }}
                          >
                            1. {doctor?.testimonial1 || "Client Reference 1"}
                          </Typography>
                          <Typography
                            variant="body1"
                            style={{
                              marginBottom: "8px",
                              fontStyle: "italic",
                              color: "#555",
                            }}
                          >
                            2. {doctor?.testimonial2 || "Client Reference 2"}
                          </Typography>
                        </div>
                      </div>
                    )}

                    <Typography variant="body1">
                      <span
                        style={{
                          color: "#1976d2",
                          cursor: "pointer",
                          fontWeight: "bold",
                        }}
                        onClick={() => handleOpenModal(doctor.testimonialFile)}
                      >
                        Click here{" "}
                      </span>
                      to read {doctor?.firstName}'s client testimonials.
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {/* Charges Section */}
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        variant="body1"
                        style={{
                          marginRight: "16px",
                          color: "#3f51b5",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Audio Charge: ₹
                        {doctor?.partnerConsultantPrices?.find(
                          (price) => price.typeOfCall === "Audio"
                        )?.clientPrice || "N/A"}
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{
                          marginRight: "16px",
                          color: "#4caf50",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Video Charge: ₹
                        {doctor?.partnerConsultantPrices?.find(
                          (price) => price.typeOfCall === "Video"
                        )?.clientPrice || "N/A"}
                      </Typography>
                    </div>

                    {/* Consultation Buttons Section */}
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Button
                        variant="contained"
                        style={{
                          marginRight: "16px",
                          backgroundColor: "#A7C7E7",
                          color: "black",
                        }}
                        onClick={() => handleBookHereClick(doctor, "Audio")} // Add function for audio consultation
                      >
                        Audio Consultation
                      </Button>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "#d0f0c0",
                          color: "black",
                        }}
                        onClick={() => handleBookHereClick(doctor, "Video")} // Add function for video consultation
                      >
                        Video Consultation
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </HighlightedCard>
          </Col>
        ))}
      </Row>

      {/* {selectedDoctor && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            onClick={handleBookHereClick}
          >
            Book Here
          </Button>
        </div>
      )} */}
      {/* {selectedDoctor && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
            gap: "10px", // Space between the buttons
          }}
        >
          <Button
            variant="contained"
            style={{
              backgroundColor: "#3f51b5", // Color for Audio Booking (matches from previous card colors)
              color: "#ffffff",
            }}
            onClick={() => handleBookHereClick("audio")}
          >
            Book Audio Here
          </Button>

          <Button
            variant="contained"
            style={{
              backgroundColor: "#4caf50", // Color for Video Booking
              color: "#ffffff",
            }}
            onClick={() => handleBookHereClick("video")}
          >
            Book Video Here
          </Button>

          <Button
            variant="contained"
            style={{
              backgroundColor: "#f44336", // Color for Chat Booking
              color: "#ffffff",
            }}
            onClick={() => handleBookHereClick("chat")}
          >
            Book Chat Here
          </Button>
        </div>
      )} */}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            minWidth: 400,
          }}
        >
          {/* Close icon at the top-left */}
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 8,
              left: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" component="h2" gutterBottom>
            Testimonial Files
          </Typography>
          <Box sx={{ display: "flex", gap: 2 }}>
            {selectedFiles.length > 0 ? (
              selectedFiles.map((file) => (
                <IconButton
                  key={file.id}
                  onClick={() => handleDownload(file.filePath)}
                >
                  <InsertDriveFileIcon sx={{ fontSize: 40 }} />
                </IconButton>
              ))
            ) : (
              <Typography>No files available</Typography>
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default NutritionistCard;
