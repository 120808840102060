import React from "react";
import { GrRefresh } from "react-icons/gr";

const FilterComponent = ({
  showFilters,
  showDateField,
  showTextField,
  filterDate,
  filterDateWithoutPre,
  filterLabName,
  handleDateFilterChange,
  handleLabNameFilterChange,
  handleDateChange,
  handleLabNameChange,
  getTestRecordsList,
  currentTabIndex,
  showFiltersIndex
}) => {
  return (
    <>
      {showFilters && currentTabIndex === showFiltersIndex && (
        <div style={{ border: "1px solid lightgrey", padding: "10px", marginTop: "-10px" }}>
          <input
            type="checkbox"
            id="dateFilter"
            name="dateFilter"
            onChange={handleDateFilterChange}
          />{" "}
          &nbsp;
          <label htmlFor="dateFilter">Filter by Date</label>
          <br />
          {showDateField && (
            <div>
              <label>
                <span className="text-style"></span>
              </label>
              &nbsp;&nbsp;
              <input
                className="input-date"
                type="date"
                value={currentTabIndex == 0 ? filterDate : filterDateWithoutPre}
                onChange={(event) => handleDateChange(event, currentTabIndex)}
              />
              <GrRefresh
                className="refresh-list"
                onClick={getTestRecordsList}
              />
            </div>
          )}
          <input
            type="checkbox"
            id="labNameFilter"
            name="labNameFilter"
            onChange={handleLabNameFilterChange}
          />
          &nbsp;&nbsp;
          <label htmlFor="labNameFilter">Filter by Hospital Name</label>
          <br />
          {showTextField && (
            <div>
              <label>
                <span className="text-style"></span>
              </label>
              &nbsp;&nbsp;
              <input
                className="input-date"
                type="text"
                placeholder="Enter Lab Name"
                value={filterLabName}
                onChange={handleLabNameChange}
              />
              <GrRefresh
                className="refresh-list"
                onClick={getTestRecordsList}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default FilterComponent;
