import React, { useEffect, useState } from "react";
import { Box, Tab, Tabs } from "@mui/material/Box";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import {
  Form,
  FormGroup,
  FormFeedback,
  FormText,
  Label,
  Input,
  Row,
  Col,
  Button,
} from "reactstrap";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import "./style.css";
import TablePagination from "@mui/material/TablePagination";
import Spinner from "../../shared/Spinner";

const DieticianBilling = () => {
  const [rowData, setRowData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setLoading] = useState(false);
  const getOrderBilling = async () => {
    setLoading(true);
    await AxiosInstance.get(`consultation/getOrderBilling`)
      .then((response) => {
        if (response != null) {
          setRowData(response);
        } else {
          setRowData("");
        }
      })
      .catch((err) => {
        console.log("err: ", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getOrderBilling();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <h3>Billing</h3>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <TableContainer>
            <Table style={{ border: "1px solid lightgrey", marginTop: "20px" }}>
              <TableHead>
                <TableRow>
                  <TableCell
                    className="tableCell"
                    style={{ backgroundColor: "rgb(83, 83, 233)" }}
                  >
                    LSH Order ID
                  </TableCell>
                  <TableCell
                    className="tableCell"
                    style={{ backgroundColor: "rgb(83, 83, 233)" }}
                  >
                    Date
                  </TableCell>
                  <TableCell
                    className="tableCell"
                    style={{ backgroundColor: "rgb(83, 83, 233)" }}
                  >
                    Patient ID
                  </TableCell>
                  <TableCell
                    className="tableCell"
                    style={{ backgroundColor: "rgb(83, 83, 233)" }}
                  >
                    Amount
                  </TableCell>
                  <TableCell
                    className="tableCell"
                    style={{ backgroundColor: "rgb(83, 83, 233)" }}
                  >
                    Tax
                  </TableCell>
                  <TableCell
                    className="tableCell"
                    style={{ backgroundColor: "rgb(83, 83, 233)" }}
                  >
                    Total Amount
                  </TableCell>
                  <TableCell
                    className="tableCell"
                    style={{ backgroundColor: "rgb(83, 83, 233)" }}
                  >
                    Order Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowData.length === 0 ? (
                  <TableRow>
                    <TableCell
                      colSpan={7}
                      style={{
                        textAlign: "center",
                        border: "1px solid lightgrey",
                      }}
                    >
                      <b>No data available</b>
                    </TableCell>
                  </TableRow>
                ) : (
                  rowData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <TableRow key={index + page * rowsPerPage}>
                        <TableCell style={{ border: "1px solid lightgrey" }}>
                          {row.orderId}
                        </TableCell>
                        <TableCell style={{ border: "1px solid lightgrey" }}>
                          {row.orderDate}
                        </TableCell>
                        <TableCell style={{ border: "1px solid lightgrey" }}>
                          {row.patientId}
                        </TableCell>
                        <TableCell style={{ border: "1px solid lightgrey" }}>
                          {row.amount}
                        </TableCell>
                        <TableCell style={{ border: "1px solid lightgrey" }}>
                          {row.tax}
                        </TableCell>
                        <TableCell style={{ border: "1px solid lightgrey" }}>
                          {row.totalAmount}
                        </TableCell>
                        <TableCell style={{ border: "1px solid lightgrey" }}>
                          {row.orderStatus}
                        </TableCell>
                      </TableRow>
                    ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rowData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </>
  );
};
export default DieticianBilling;
