import React, { useEffect, useState } from "react";
import { Card } from "reactstrap";

const GymHomePage = () => {
  return (
    <>
      <h2 style={{ textAlign: "center", marginBottom: "10px" }}>
        Welcome Gym Brand Partner
      </h2>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <Card
          style={{
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            border: "1px solid black",
          }}
        >
          <img
            src="/img/gym1.jpg"
            alt="Lab"
            style={{ height: "43rem", width: "30rem", padding: "10px" }}
          />
        </Card>
        <Card
          style={{
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            border: "1px solid black",
          }}
        >
          <img
            src="/img/gym4.avif"
            alt="Lab"
            style={{ height: "43rem", width: "30rem", padding: "10px" }}
          />
        </Card>
      </div>
    </>
  );
};
export default GymHomePage;
