import "../LifeStyleRiskAssessment.css";
import { useEffect, useState } from "react";
import { AxiosInstance } from "../../../configration/AxiosConfiguration";
import { useNavigate } from "react-router-dom";
import NutritionistCard from "./NutritionistCard";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import AppointmentScheduler from "./AppointmentScheduler";
import BookGymModel from "../../GymScreen/BookGymModel";
import { bookPreventiveCareTest } from "../../../constants/apiList";
import Spinner from "../../../shared/Spinner";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import GymDetail from "../../GymScreen/GymDetail";
import CloseIcon from "@mui/icons-material/Close";
import LabServices from "../../LabServices/LabServices";

const PreventiveCareServices = ({
  PreventiveCareRecommendationNext,
  FullRecommendationNext,
}) => {
  const [preventiveCare, setPreventiveCare] = useState([]);
  const [familyInfo, setFamilyInfo] = useState(null);
  const [modal, setModal] = useState(false);
  const [appointmentmodal, setappointmentmodal] = useState(false);
  const toggle = () => setModal(!modal);
  const appointmentmodaltoggle = () => setappointmentmodal(!appointmentmodal);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [nutritionistData, setnutritionistData] = useState([]);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("");
  const [scheduleId, setscheduleId] = useState(null);
  const [NutritionistLrid, setNutritionistLrid] = useState("");
  const [isGymModalOpen, setGymIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const [dietitianLoading, setDietitianLoading] = useState(false);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [tests, setTests] = useState([]);
  const [labShowmodal, setlabShowmodal] = useState(false);
  const labShowmodaltoggle = () => setlabShowmodal(!labShowmodal);
  const [cartLoading, setCartLoading] = useState(null);
  const [defaultTab , setDefaultTab] = useState(0)

  const handleNutritionistSelect = (doctor, type) => {
    setSelectedDoctor(doctor); // Set the selected doctor data
    setType(type);
    // Find the corresponding price for the selected type
    const selectedPrice =
      doctor.partnerConsultantPrices?.find(
        (price) => price.typeOfCall === type
      ) || null;
    console.log("selectedPrice", selectedPrice);
    setSelectedPrice(selectedPrice);
    setappointmentmodal(true);
    setModal(!modal);
  };
  const onAppointmentSelect = (sessionId) => {
    setappointmentmodal(!appointmentmodal);
    setOpen(true);
    setscheduleId(sessionId.sessionId);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // const surcharge = 0;
  // const taxRate = 0.18;
  // const tax =
  //   selectedPrice != null && parseInt(selectedPrice.clientPrice * taxRate);
  // const total =
  //   selectedPrice != null &&
  //   parseInt(selectedPrice.clientPrice + surcharge + tax);

  useEffect(() => {
    AxiosInstance.get("riskAssessment/getLifestylePreventiveCareServices")
      .then((response) => {
        if (response != null) {
          setPreventiveCare(response);
        } else {
          setPreventiveCare([]);
        }
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  }, []);

  const getPaymentSuccessBaseUrl = () => {
    const baseUrl = window.location.href;
    let arr = baseUrl.split("/");
    let newPath = `${arr[0]}//${arr[2]}/patient-online-consultation`;
    return encodeURI(newPath); // Encode the URL
  };

  const handlePayment = () => {
    const formData = {
      planId: selectedPrice.id,
      dietitianId: selectedDoctor.userId,
      scheduleId: scheduleId,
      lrId: NutritionistLrid,
    };
    setPaymentLoading(true);
    AxiosInstance.post(
      `/payment/dietitian-create-order?successURL=${encodeURIComponent(
        getPaymentSuccessBaseUrl()
      )}`,
      formData
    )
      .then((response) => {
        window.location.href = response;
        setPaymentLoading(false);
        setOpen(!open);
      })
      .catch((error) => {
        console.error("Error:", error);
        setPaymentLoading(false);
      });
  };

  const handlePreventiveTest = (lrId) => {
    AxiosInstance.post(`${bookPreventiveCareTest}/${lrId}`)
      .then((response) => {
        // navigate("/lab-services?page=testcart");
        setTests(response);
        setlabShowmodal(true);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };
  const handleAddToCart = async (labId, testId, isPreventiveTest, labName) => {
    setCartLoading(testId); // Show loader only for the clicked button

    try {
      const response = await AxiosInstance.post(
        `test-labs/add-to-cart-test/${labId}/${testId}/${isPreventiveTest}`
      );
      console.log("Added to cart:", response); // You can replace this with a better UI feedback
    } catch (err) {
      console.error("Error adding to cart:", err);
    } finally {
      setCartLoading(null); // Hide loader after API call
      // setSelectedLab(labName);
      setDefaultTab(1);
    }
  };

  useEffect(() => {
    AxiosInstance.get("profile/get")
      .then((response) => {
        setFamilyInfo(response?.profile);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const bookNutritionistDetail = (lrid) => {
    setNutritionistLrid(lrid);
    setModal(!modal);
    setDietitianLoading(true);
    AxiosInstance.get(`dietitian/getAllDietitianDetail?disease=${lrid}`)
      .then((response) => {
        if (response) {
          setnutritionistData(response);
        }
        setDietitianLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setDietitianLoading(false);
      });
  };

  const bookGym = () => {
    setGymIsModalOpen(true);
  };
  const closeModal = () => {
    setGymIsModalOpen(false); // Set to false to hide the modal
  };

  const labColors = ["orange", "green", "rgb(227, 242, 253)"];

  // Group tests by labName
  const groupedTests = tests.reduce((acc, test) => {
    if (!acc[test.labName]) acc[test.labName] = [];
    acc[test.labName].push(test);
    return acc;
  }, {});

  console.log("grouped", groupedTests);

  return (
    <>
      {preventiveCare.length > 0 && (
        <div className="">
          {preventiveCare?.map((item, index) => (
            <>
              <table
                class="table table-bordered"
                style={{ border: "2px solid black" }}
              >
                <thead class="table-dark">
                  <tr>
                    <th scope="col">
                      <p style={{ fontSize: "18px" }}>
                        <b>Medical Condition :</b> {item.medicalCondition}
                      </p>
                    </th>
                    <th scope="col">
                      <p style={{ fontSize: "18px" }}>
                        <b>Date Recommended :</b> {item.dateRecommended}
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <p style={{ fontSize: "18px" }}>
                        <b>Preventive Test :</b> {item.preventiveTest}
                      </p>
                    </th>
                    <td>
                      <p style={{ fontSize: "18px" }}>
                        <b>LRID :</b> {item.lrid}
                      </p>
                    </td>
                  </tr>
                  <tr class="table-active">
                    <th scope="row" colSpan={2} style={{ textAlign: "center" }}>
                      <p style={{ fontSize: "20px" }}>
                        <b>Importance</b>
                      </p>
                    </th>
                  </tr>
                  <tr>
                    <th scope="row" colSpan={2}>
                      <p style={{ fontSize: "18px" }}> {item.importance}</p>
                    </th>
                  </tr>
                  <tr class="table-active">
                    <th scope="row" colSpan={2} style={{ textAlign: "center" }}>
                      <p style={{ fontSize: "20px" }}>
                        <b>Why You?</b>
                      </p>
                    </th>
                  </tr>
                  <tr>
                    <th scope="row" colSpan={2}>
                      <p style={{ fontSize: "18px" }}>{item.whyYou}</p>
                    </th>
                  </tr>
                  <tr class="table-active">
                    <th scope="row" colSpan={2} style={{ textAlign: "center" }}>
                      <p style={{ fontSize: "20px" }}>
                        <b>Recommendation</b>
                      </p>
                    </th>
                  </tr>
                  <tr>
                    <th scope="row" colSpan={2}>
                      <p style={{ fontSize: "18px" }}>{item.recommendation}</p>
                    </th>
                  </tr>
                  {familyInfo?.age >= 3 && (
                    <>
                      {item.bookForTest && (
                        <tr class="table-active">
                          <th
                            scope="row"
                            colSpan={2}
                            style={{ textAlign: "center" }}
                          >
                            <p style={{ fontSize: "20px" }}>
                              <b>Book for Test - "{item.bookForTest}"</b>
                            </p>
                          </th>
                        </tr>
                      )}
                      {item.bookForTest && (
                        <tr>
                          <th scope="row" colSpan={2}>
                            {item.lrid === "CVD-04-02" ||
                            item.lrid === "Diabetes-03" ? (
                              <center>
                                <button
                                  type="button"
                                  className="btn btn-success"
                                  onClick={() =>
                                    bookNutritionistDetail(item.lrid)
                                  }
                                >
                                  Book Nutritionist
                                </button>{" "}
                                &nbsp;
                                <button
                                  type="button"
                                  className="btn btn-success"
                                  onClick={bookGym}
                                >
                                  Book Gym
                                </button>
                                {isGymModalOpen && (
                                  // <GymDetail
                                  //   open={isGymModalOpen}
                                  //   onClose={closeModal}
                                  // />
                                  <Dialog
                                    open={isGymModalOpen}
                                    onClose={closeModal}
                                    fullWidth
                                    maxWidth="xl"
                                    sx={{
                                      zIndex: 999,
                                      "& .css-ji9sn5-MuiPaper-root-MuiDialog-paper":
                                        {
                                          padding: "40px",
                                        },
                                    }}
                                  >
                                    <DialogTitle
                                      sx={{
                                        backgroundColor: "black",
                                        color: "white",
                                        border: "1px solid lightgrey",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <p style={{ flexShrink: 0 }}>
                                        Choose Your Gym
                                      </p>
                                    </DialogTitle>
                                    <IconButton
                                      aria-label="close"
                                      onClick={closeModal}
                                      sx={{
                                        position: "absolute",
                                        right: 8,
                                        top: 5,
                                        color: (theme) =>
                                          theme.palette.grey[500],
                                      }}
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                    <GymDetail />
                                  </Dialog>
                                )}
                              </center>
                            ) : (
                              <center>
                                <button
                                  type="button"
                                  className="btn btn-success"
                                  onClick={() =>
                                    handlePreventiveTest(item.lrid)
                                  }
                                >
                                  Book
                                </button>
                              </center>
                            )}
                          </th>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
              <br></br>
            </>
          ))}
          {/* <table class="table table-bordered" style={{ border: "2px solid black" }}>
                <thead class="table-dark">
                  <tr>
                    <th scope="col">
                      <p style={{ fontSize: "18px" }}>
                        <b>Medical Condition :</b> Dengue
                      </p>
                    </th>
                    <th scope="col">
                      <p style={{ fontSize: "18px" }}>
                        <b>Date Recommended :</b> 8-10-2024
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <p style={{ fontSize: "18px" }}>
                        <b>Preventive Test :</b> Dengue test
                      </p>
                    </th>
                    <td>
                      <p style={{ fontSize: "18px" }}>
                        <b>LRID :</b> DR01
                      </p>
                    </td>
                  </tr>
                  <tr class="table-active">
                    <th scope="row" colSpan={2} style={{ textAlign: "center" }}>
                      <p style={{ fontSize: "20px" }}>
                        <b>Importance</b>
                      </p>
                    </th>
                  </tr>
                  <tr>
                    <th scope="row" colSpan={2}>
                      <p style={{ fontSize: "18px" }}>very important</p>
                    </th>
                  </tr>
                  <tr class="table-active">
                    <th scope="row" colSpan={2} style={{ textAlign: "center" }}>
                      <p style={{ fontSize: "20px" }}>
                        <b>Why You?</b>
                      </p>
                    </th>
                  </tr>
                  <tr>
                    <th scope="row" colSpan={2}>
                      <p style={{ fontSize: "18px" }}>cwgrt</p>
                    </th>
                  </tr>
                  <tr class="table-active">
                    <th scope="row" colSpan={2} style={{ textAlign: "center" }}>
                      <p style={{ fontSize: "20px" }}>
                        <b>Recommendation</b>
                      </p>
                    </th>
                  </tr>
                  <tr>
                    <th scope="row" colSpan={2}>
                      <p style={{ fontSize: "18px" }}>xdewrgt</p>
                    </th>
                  </tr>
                  {familyInfo?.age >= 3 && (
                    <>
                  <tr class="table-active">
                    <th scope="row" colSpan={2} style={{ textAlign: "center" }}>
                      <p style={{ fontSize: "20px" }}>
                        <b>Book for Test - "Nurtitionist"</b>
                      </p>
                    </th>
                  </tr>
                  <tr>
                    <th scope="row" colSpan={2}>
                        <center>
                          <button type="button" className="btn btn-success" onClick={() => bookNutritionistDetail()}>
                            Book Nutritionist
                          </button>
                        </center>
                    </th>
                  </tr>
                    </>
                  )}
                </tbody>
              </table> */}

          <div>
            <Modal
              isOpen={modal}
              toggle={toggle}
              centered
              style={{
                maxWidth: "90%", // Allows the modal to expand up to 90% of the screen width
                minWidth: "60%", // Ensures a minimum width, so it doesn't get too narrow
                width: "auto", // Automatically adjusts the width based on content
              }}
            >
              <ModalHeader
                style={{
                  backgroundColor: "black",
                  color: "white",
                  border: "1px solid lightgrey",
                }}
                toggle={toggle}
              >
                <center>Select Nurtitionist/Dietitian to Book</center>
              </ModalHeader>

              <ModalBody
                style={{
                  padding: "20px",
                  maxHeight: "80vh", // Adjust the height as needed
                  overflowY: "auto", // Enable vertical scroll if content exceeds maxHeight
                }}
              >
                {dietitianLoading ? (
                  <Spinner />
                ) : nutritionistData.length > 0 ? (
                  <NutritionistCard
                    nutritionistData={nutritionistData}
                    onDoctorSelect={handleNutritionistSelect}
                  ></NutritionistCard>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "40vh",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="h6"
                      color="textSecondary"
                      sx={{ mb: 2 }}
                    >
                      No Dietitan Available.
                    </Typography>
                    <SentimentDissatisfiedIcon
                      fontSize="large"
                      color="disabled"
                    />
                  </Box>
                )}

                {/* <OrderDetailModel /> */}
              </ModalBody>
            </Modal>
          </div>
          <Modal
            isOpen={appointmentmodal}
            toggle={appointmentmodaltoggle}
            centered
            style={{
              maxWidth: "90%", // Allows the modal to expand up to 90% of the screen width
              minWidth: "60%", // Ensures a minimum width, so it doesn't get too narrow
              width: "auto", // Automatically adjusts the width based on content
            }}
          >
            <ModalHeader
              style={{
                backgroundColor: "black",
                color: "white",
                border: "1px solid lightgrey",
              }}
              toggle={appointmentmodaltoggle}
            >
              <center>Select Date/Time to Book Appointment</center>
            </ModalHeader>
            <ModalBody
              style={{
                padding: "20px",
                maxHeight: "80vh", // Adjust the height as needed
                overflowY: "auto", // Enable vertical scroll if content exceeds maxHeight
              }}
            >
              <AppointmentScheduler
                onAppointmentSelect={onAppointmentSelect}
                type={type}
                selectedDietician={selectedDoctor}
                selectedPrice={selectedPrice}
              ></AppointmentScheduler>
            </ModalBody>
          </Modal>
          <Modal
            isOpen={open}
            toggle={handleClose}
            centered
            modalClassName="custom-modal"
          >
            <ModalHeader
              style={{
                backgroundColor: "black",
                color: "white",
                border: "1px solid lightgrey",
              }}
              toggle={handleClose}
            >
              <Typography variant="h6" component="h2">
                Complete your Payment
              </Typography>
            </ModalHeader>
            <ModalBody className="modal-body-centered">
              <Typography
                variant="h5"
                sx={{ color: "purple", fontWeight: "600", fontSize: "1.75rem" }}
                component="h5"
              >
                Uncurl: Health Services
              </Typography>
              <Typography
                variant="h6"
                // sx={{ color: "purple" }}
                component="h6"
              >
                Clinical Dietitian Consulting
              </Typography>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={6} sx={{ textAlign: "right" }}>
                  <Typography variant="h6">Service Type:</Typography>
                  <Typography variant="h6">Order Value:</Typography>
                  {/* <Typography variant="body1">Surcharge:</Typography>
                  <Typography variant="body1">Tax (18%):</Typography> */}
                </Grid>
                <Grid item xs={6} sx={{ textAlign: "left" }}>
                  <Typography variant="h6">
                    {selectedPrice && selectedPrice.typeOfCall}
                  </Typography>
                  <Typography variant="h6">
                    ₹{selectedPrice != null && selectedPrice.clientPrice}
                  </Typography>
                  {/* <Typography variant="body1">₹{surcharge}</Typography>
                  <Typography variant="body1">
                    ₹{tax && tax.toFixed(2)}
                  </Typography> */}
                </Grid>
              </Grid>

              {/* Line above the Total */}
              <Box
                sx={{
                  borderTop: "2px solid lightgrey",
                  mt: 2,
                  mb: 2,
                  width: "100%",
                }}
              />

              <Grid container spacing={2}>
                <Grid item xs={6} sx={{ textAlign: "right" }}>
                  <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                    Total:
                  </Typography>
                </Grid>
                <Grid item xs={6} sx={{ textAlign: "left" }}>
                  <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                    ₹{selectedPrice && selectedPrice.clientPrice}
                  </Typography>
                </Grid>
              </Grid>

              {/* Line below the Total */}
              <Box
                sx={{
                  borderTop: "2px solid lightgrey",
                  mt: 2,
                  mb: 2,
                  width: "100%",
                }}
              />
              <Typography variant="body1" sx={{ mb: 3 }}>
                This amount will be charged to your account.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  backgroundColor: "#5353e9",
                  "&:hover": { backgroundColor: "#c60176" },
                }}
                onClick={handlePayment} // Add your payment handling function here
                disabled={paymentLoading}
              >
                {paymentLoading ? (
                  <CircularProgress color="inherit" size={24} />
                ) : (
                  "Confirm & Pay"
                )}
              </Button>
            </ModalBody>
          </Modal>
          <Modal
            isOpen={labShowmodal}
            toggle={labShowmodaltoggle}
            centered
            style={{
              maxWidth: "90%", // Allows the modal to expand up to 90% of the screen width
              minWidth: "60%", // Ensures a minimum width, so it doesn't get too narrow
              width: "auto", // Automatically adjusts the width based on content
            }}
          >
            <ModalHeader
              style={{
                backgroundColor: "black",
                color: "white",
                border: "1px solid lightgrey",
              }}
              toggle={labShowmodaltoggle}
            >
              <center>Select Lab to Book Test</center>
            </ModalHeader>
            <ModalBody
              style={{
                padding: "20px",
                maxHeight: "80vh", // Adjust the height as needed
                overflowY: "auto", // Enable vertical scroll if content exceeds maxHeight
              }}
            >
              {defaultTab === 0 ?  tests.length > 0 && (
                <>
                  {Object.keys(groupedTests).map((lab, index) => (
                    <Card
                      key={lab}
                      style={{
                        // backgroundColor: labColors[index % labColors.length],
                        padding: "15px",
                        marginBottom: "20px",
                        border: `1px solid ${
                          labColors[index % labColors.length]
                        }`,
                        width: "100%",
                      }}
                    >
                      <Typography
                        variant="h5"
                        style={{
                          fontWeight: "bold",
                          color: labColors[index % labColors.length],
                        }}
                      >
                        {lab}
                      </Typography>
                      <Grid container spacing={2} style={{ marginTop: "10px" }}>
                        {groupedTests[lab].map((test) => (
                          <Grid item xs={12} sm={6} md={4} key={test.testId}>
                            <Card
                              style={{
                                padding: "16px",
                                borderRadius: "8px",
                                boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                textAlign: "center",
                                border: `1px solid ${
                                  labColors[index % labColors.length]
                                }`,
                              }}
                            >
                              <Typography variant="h6">
                                {test.labName}
                              </Typography>
                              <Typography
                                variant="body1"
                                style={{ marginTop: "8px", fontWeight: "bold" }}
                              >
                                {test.testName}
                              </Typography>
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                style={{ marginTop: "4px" }}
                              >
                                ₹ {test.testPrice}
                              </Typography>
                              <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                style={{ marginTop: "12px", minWidth: "120px" }}
                                onClick={() =>
                                  handleAddToCart(
                                    test.labId,
                                    test.testId,
                                    test.preventiveTest,
                                    test.labName
                                  )
                                }
                                disabled={cartLoading === test.testId}
                              >
                                {cartLoading === test.testId ? (
                                  <CircularProgress size={20} color="inherit" />
                                ) : (
                                  "Add to Cart"
                                )}
                              </Button>
                            </Card>
                          </Grid>
                        ))}
                      </Grid>
                    </Card>
                  ))}
                </>
              ) : <LabServices defaultTab={defaultTab}/>}
            </ModalBody>
          </Modal>
        </div>
      )}
    </>
  );
};

export default PreventiveCareServices;
