import React from "react";
import DietitianOrderHistory from "../Dieticians/DietitianOrderHistory";

const PhysiotherapistOrderHistory = () => {
  return (
    <>
      <DietitianOrderHistory />
    </>
  );
};
export default PhysiotherapistOrderHistory;
