import React from "react";
import DieticianBilling from "../Dieticians/dietician-billing";

const PhysiotherapistBilling = () => {
  return (
    <>
      <DieticianBilling />
    </>
  );
};
export default PhysiotherapistBilling;
