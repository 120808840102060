import React from "react";
import { useEffect, useState } from "react";
import {
  MenuItem,
  Button,
  Typography,
  Box,
  Select,
  TextField,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  FormHelperText,
  Grid,
  Tabs,
  Tab,
  Dialog,
  DialogTitle,
  CircularProgress,
} from "@mui/material";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import Dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { Col, Row } from "reactstrap";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import SeeMoreAboutGym from "./SeeMoreAboutGym";
import StarIcon from "@mui/icons-material/Star";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Swal from "sweetalert2";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Spinner from "../../shared/Spinner";

const GymDetail = ({ onClose }) => {
  const [seeMoreAboutGym, setSeeMoreAboutGym] = useState(false);
  const [activeGym, setActiveGym] = useState(null);
  const [trailBooking, setTrailBooking] = useState(false);
  const [membershipBooking, setMembershipBooking] = useState(false);
  const [loading, setLoading] = useState(false);
  const [gymGeneralInformation, setGymGeneralInformation] = useState([]);
  const [kindOfEquipment, setKindOfEquipment] = useState([]);
  const [classesAndProgram, setClassesProgramList] = useState([]);
  const [formData, setFormData] = useState({
    selectedDate: null,
    selectedTime: "",
    errorDate: "",
    errorTime: "",
  });
  const [membershipFormData, setMembershipFormData] = useState({
    selectedPlan: "",
    id: null,
    price: null,
    errors: {
      plan: false,
      price: false,
    },
  });
  const [paymentModal, setPaymentModal] = useState(false);
  const [paymentLoader, setPaymentLoader] = useState(false);

  const taxRate = 0.18;
  const tax =
    membershipFormData.gstAmount != null &&
    parseInt(membershipFormData.gstAmount);
  const total =
    membershipFormData.price != null &&
    parseInt(membershipFormData.price + tax);

  Dayjs.extend(utc);
  const timeSlots = [];
  for (let hour = 11; hour <= 16; hour++) {
    const period = hour >= 12 ? "PM" : "AM";
    const displayHour = hour > 12 ? hour - 12 : hour; // Convert to 12-hour format

    timeSlots.push(`${displayHour}:00 ${period}`);
    timeSlots.push(`${displayHour}:30 ${period}`);
  }
  timeSlots.push("5:00 PM");
  const handleSeeMoreInfo = () => {
    setSeeMoreAboutGym(true);
  };
  const onCloseSeeMoreInfo = () => {
    setSeeMoreAboutGym(false);
  };
  const handleGymClick = (gym) => {
    setActiveGym(gym);
  };

  const handleTrailBooking = () => {
    setTrailBooking(true);
    setMembershipBooking(false);
  };

  const handleMembershipBooking = () => {
    setMembershipBooking(true);
    setTrailBooking(false);
  };

  const handlePlanChange = (event) => {
    const selectedValue = event.target.value;

    // Find the corresponding price
    const selectedPlanObj = gymGeneralInformation[0].gymPriceList.find(
      (plan) => plan.duration === selectedValue
    );

    // Update state with selected plan, price, and reset errors
    setMembershipFormData((prev) => ({
      ...prev,
      gstAmount: selectedPlanObj.gstAmount,
      selectedPlan: selectedValue,
      id: selectedPlanObj.id,
      price: selectedPlanObj ? selectedPlanObj.clientPrice : "",
      errors: { plan: false, price: false }, // Reset errors when user selects a valid option
    }));
  };

  const handleBookAppointment = (gymData) => {
    let isValid = true;
    let errors = { errorDate: "", errorTime: "" };

    if (!formData.selectedDate) {
      errors.errorDate = "Please enter date";
      isValid = false;
    }

    if (!formData.selectedTime) {
      errors.errorTime = "Please enter time";
      isValid = false;
    }

    setFormData({ ...formData, ...errors });

    if (isValid) {
      console.log("Booking Details:", {
        date: formData.selectedDate.format("DD/MM/YYYY"),
        time: formData.selectedTime,
      });
      const sendForMdata = {
        partnerUserId: gymData?.userId,
        sessionDate: formData.selectedDate,
        sessionTime: formData.selectedTime,
      };

      AxiosInstance.post("gym/create-free-session", sendForMdata)
        .then((response) => {
          onClose();
          Swal.fire({
            title: "Appointment Booked!",
            text: `Your appointment at ${
              gymData?.gymName
            } is booked for ${formData.selectedDate.format("DD/MM/YYYY")} at ${
              formData.selectedTime
            }.`,
            icon: "success",
            confirmButtonText: "OK",
            customClass: {
              popup: "swal-popup",
            },
            didOpen: () => {
              const swalPopup = document.querySelector(".swal-popup");
              if (swalPopup) {
                swalPopup.style.zIndex = "999999";
              }
            },
          });
        })
        .catch((error) => {
          console.log("Error:", error);
        });
    }
  };
  const handleBookMembership = () => {
    const { selectedPlan, price } = membershipFormData;

    // Validate fields
    const newErrors = {
      plan: !selectedPlan,
      price: !price,
    };

    setMembershipFormData((prev) => ({
      ...prev,
      errors: newErrors,
    }));

    // Proceed only if both fields are filled
    if (selectedPlan && price) {
      setPaymentModal(true);
    }
  };
  const getPaymentSuccessBaseUrl = () => {
    const baseUrl = window.location.href;
    let arr = baseUrl.split("/");
    let newPath = `${arr[0]}//${arr[2]}/patient-online-consultation`;
    return encodeURI(newPath); // Encode the URL
  };
  const handlePayment = (gymUserId) => {
    const formData = {
      // amount: total,
      partnerUserId: gymUserId,
      partnerType: "GYM",
      ClassName: "Excerise",
      // planDuration: membershipFormData.selectedPlan,
      planId: membershipFormData.id,
      slotIds: null,
    };
    setPaymentLoader(true);
    AxiosInstance.post(
      `payment/create-subscription-gym-and-trainer?successURL=${encodeURIComponent(
        getPaymentSuccessBaseUrl()
      )}`,
      formData
    )
      .then((response) => {
        window.location.href = response;
        setMembershipFormData({
          selectedPlan: "",
          id: null,
          price: null,
          errors: {
            plan: false,
            price: false,
          },
        });
        setPaymentModal(false);
        onClose();
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setPaymentLoader(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    AxiosInstance.get("gym/get-all-gym-list")
      .then((response) => {
        if (response) {
          setGymGeneralInformation(response);
          setKindOfEquipment(response[0].kindOfEquipment);
          setClassesProgramList(response[0].classesAndProgram);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (gymGeneralInformation.length > 0) {
      setActiveGym(gymGeneralInformation[0].gymName);
    }
  }, [gymGeneralInformation]);

  console.log("membershipData", membershipFormData);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : gymGeneralInformation.length > 0 ? (
        <div>
          <Tabs
            value={activeGym}
            onChange={(event, newValue) => setActiveGym(newValue)}
            variant="scrollable"
            scrollButtons="auto"
            indicatorColor="primary"
            textColor="primary"
          >
            {gymGeneralInformation.map((gym) => (
              <Tab key={gym.gymName} label={gym.gymName} value={gym.gymName} />
            ))}
          </Tabs>
          <br></br>
          {activeGym &&
            gymGeneralInformation
              .filter((gym) => gym.gymName === activeGym)
              .map((gym) => (
                <>
                  <div
                    style={{
                      border: "1px solid rgb(25, 118, 210)",
                      borderRadius: "10px",
                      padding: "15px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      style={{
                        marginBottom: "8px",
                        color: "#1976d2",
                        textAlign: "center", // Center the title
                        backgroundColor: "#e3f2fd", // Light background for the title
                        padding: "8px",
                        borderRadius: "8px 8px 0 0", // Rounded corners on top
                      }}
                    >
                      <h3
                        style={{
                          textAlign: "center",
                          textDecoration: "underline",
                          fontFamily: "cursive",
                        }}
                      >
                        {gym.gymName}
                      </h3>
                      4.5
                      <StarIcon /> (533)
                    </Typography>

                    <Row className="flex flex-wrap justify-center">
                      <Col md={4}>
                        <b> Year of Establish:</b> Since{" "}
                        {gym.gymEstablished || "Not Available"}
                      </Col>
                      <Col md={4}>
                        <b> Size(area):</b> {gym.sizeOfGym || "Not Available"}{" "}
                        Sq. ft
                      </Col>
                      <Col md={4}>
                        <b>Location: </b>
                        {gym.locationOfGym || "Not Available"}
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md={4}>
                        <b>General time of the gym: </b>Morning 5.30 AM To 11.00
                        PM (Monday To Saturday ) & Morning 7.00 AM To 1.00 PM (
                        Sunday )
                      </Col>
                      <Col md={4}>
                        <b>Total staff members: </b>
                        {gym.staffMemberPresent || "Not Available"}
                      </Col>
                      <Col md={4}>
                        <b>Gym Description: </b>
                        {gym.describeGym || "Not Available"}
                      </Col>
                    </Row>

                    <Row className="flex flex-wrap justify-center mb-4 mt-5">
                      <Col className="rounded-lg  p-4" md={4}>
                        <h6>Available Facilities in gym :</h6>
                        {gymGeneralInformation[0]?.otherFacility}
                        <p className="mt-2"></p>
                      </Col>
                      <Col md={8}>
                        <TableContainer
                          component={Paper}
                          sx={{ maxHeight: 400, overflowY: "auto" }}
                        >
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Equipment Name</TableCell>
                                <TableCell>Equipment Type</TableCell>
                                <TableCell>How Old Equipment</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {kindOfEquipment.map((item) => (
                                <TableRow key={item.id}>
                                  <TableCell>
                                    <p>{item.equipment}</p>
                                  </TableCell>
                                  <TableCell>
                                    <p>{item.type}</p>
                                  </TableCell>
                                  <TableCell>
                                    <p>{item.equipmentOld}</p>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Col>
                    </Row>
                  </div>
                  <br></br>
                  <div
                    style={{
                      border: "1px solid rgb(25, 118, 210)",
                      borderRadius: "10px",
                      padding: "15px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      style={{
                        marginBottom: "8px",
                        color: "#1976d2",
                        textAlign: "center", // Center the title
                        backgroundColor: "#e3f2fd", // Light background for the title
                        padding: "8px",
                        borderRadius: "8px 8px 0 0", // Rounded corners on top
                      }}
                    >
                      <h3
                        style={{
                          textAlign: "center",
                          textDecoration: "underline",
                          fontFamily: "cursive",
                        }}
                      >
                        Membership, Training and Testing
                      </h3>
                    </Typography>{" "}
                    <Row className="flex flex-wrap justify-center mb-4 mt-3">
                      <Col className="rounded-lg" md={4}>
                        <b>
                          1. Is there any introductory clinic to help members
                          get started?
                        </b>
                        <p>
                          {" "}
                          {gymGeneralInformation[0]?.introductoryClinic == false
                            ? "- No"
                            : "- Yes"}
                        </p>
                      </Col>
                      <Col className="rounded-lg" md={4}>
                        <b>
                          2. Is separate training provided on complex equipment
                          for proper usage?
                        </b>
                        <p>
                          {" "}
                          {gymGeneralInformation[0]?.separateTraining == false
                            ? "- No"
                            : "- Yes"}
                        </p>
                      </Col>
                      <Col className="rounded-lg" md={4}>
                        <b>
                          3. Is an initial fitness orientation, screening or
                          body assessment provided to each member right after
                          joining?
                        </b>
                        <p>
                          {" "}
                          {gymGeneralInformation[0]
                            ?.initialFitnessOrientation == false
                            ? "- No"
                            : "- Yes"}
                        </p>
                      </Col>
                    </Row>
                    <Row className="">
                      <Col className="rounded-lg" md={4}>
                        <b>
                          4. Are there areas dedicated to stretching or turf
                          areas for training?
                        </b>
                        <p>
                          {" "}
                          {gymGeneralInformation[0]?.areaDedicated == false
                            ? "- No"
                            : "- Yes"}
                        </p>
                      </Col>
                      <Col className="rounded-lg" md={4}>
                        <b>
                          5. Does the gym offer body fat testing, cardiovascular
                          testing, and strength and flexibility testing
                        </b>
                        <p>
                          {" "}
                          {gymGeneralInformation[0]?.gymOfferTesting == false
                            ? "- No"
                            : "- Yes"}
                        </p>
                      </Col>
                      <Col className="rounded-lg" md={4}>
                        <b>
                          6. Is there any free trial membership available to
                          check out the gym ?
                        </b>
                        <p>
                          {" "}
                          {gymGeneralInformation[0]?.freeTrailMembership ==
                          false
                            ? "- No"
                            : "- Yes"}
                        </p>
                        <p>
                          Note :{" "}
                          {gymGeneralInformation[0]?.freeTrailMembershipExplain}
                        </p>
                      </Col>
                    </Row>
                    <Row className="flex justify-center mb-4 mt-3">
                      <Col className="rounded-lg" md={4}>
                        <b>
                          7. Can I get a Tour of the gym before signing up for
                          the membership?
                        </b>
                        <p>
                          {" "}
                          {gymGeneralInformation[0]?.getTour == false
                            ? "- No"
                            : "- Yes"}
                        </p>
                        <p>Timing : {gymGeneralInformation[0]?.getTourTime}</p>{" "}
                        to {gymGeneralInformation[0]?.getTourTimeTo}
                      </Col>
                      <Col className="rounded-lg" md={4}>
                        <b>
                          8. Can I cancel and get refund of membership fees, if
                          for some reason, after few days of training, I do not
                          want to continue with my membership anymore?{" "}
                        </b>
                        <p>
                          {" "}
                          {gymGeneralInformation[0]?.refundMembership == false
                            ? "- No"
                            : "- Yes"}
                        </p>
                      </Col>
                      <Col className="rounded-lg" md={4}>
                        <b>
                          9. What kind of membership plans the Gym has? (Select
                          all that applies)
                        </b>
                        <p>
                          {" "}
                          {gymGeneralInformation[0]
                            ?.explainDifferentMembershipPlan == false
                            ? "- No"
                            : "- Yes"}
                        </p>
                      </Col>
                    </Row>
                  </div>

                  <br></br>
                  <div
                    style={{
                      border: "1px solid rgb(25, 118, 210)",
                      borderRadius: "10px",
                      padding: "15px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      style={{
                        marginBottom: "8px",
                        color: "#1976d2",
                        textAlign: "center", // Center the title
                        backgroundColor: "#e3f2fd", // Light background for the title
                        padding: "8px",
                        borderRadius: "8px 8px 0 0", // Rounded corners on top
                      }}
                    >
                      <h3
                        style={{
                          textAlign: "center",
                          textDecoration: "underline",
                          fontFamily: "cursive",
                        }}
                      >
                        Google Reviews
                      </h3>
                    </Typography>
                    <p>
                      <b>1. </b> The gym vibe is sufficient to keep you
                      motivated for the workouts. Trainers are very friendly and
                      supportive too. Being working out here for almost 2 years
                      now.
                    </p>
                    <p>
                      <b>2. </b> This gym is amazing. Its huge n Amazing
                      facility, top equipment and great environment.
                      Professional n super friendly staff and comfortable gym
                      for everyone from beginners to professional athletes to
                      train at. Huge cardio section with crossfit. Dedicated
                      zone for powerlifting.
                    </p>
                    <p>
                      <b>3.</b> This place is equipped with every tool to make
                      your exercise regime a fulfilling ride.
                    </p>
                    <p>
                      <b>4.</b> Gym is equipped with modern equipments and the
                      vibe is just amazing. Additionally there are multiple
                      extra activities which happen every week or 10 days. The
                      trainers are super knowledgeable and helpful. I personally
                      took personal training from Rohit sir and the results he
                      has delivered are amazing.
                    </p>
                    <p>
                      <b>5. </b>Good vibe, gym environment keeps you motivated
                      to work towards your goal.
                    </p>
                    <p>
                      <b>6. </b>One of the best gyms with state of the art
                      facilities and equipment. The staff is friendly and
                      knowledgeable. The quality of the gym is right up there
                      with international standards
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <div>
                      <Button
                        color="primary"
                        variant="contained"
                        className="mt-4"
                        onClick={handleTrailBooking}
                      >
                        Book for a Free Tour of the Gym
                      </Button>
                      <Button
                        color="secondary"
                        variant="contained"
                        className="mt-4"
                        onClick={handleMembershipBooking}
                        style={{ marginLeft: "15px" }}
                      >
                        Book Membership
                      </Button>
                    </div>
                    <Button
                      color="tertiary"
                      variant="outlined"
                      onClick={handleSeeMoreInfo}
                      className="mt-4"
                      style={{ float: "right" }}
                    >
                      See More Information about Gym
                    </Button>
                  </div>
                  {seeMoreAboutGym && (
                    <SeeMoreAboutGym
                      open={seeMoreAboutGym}
                      onClose={onCloseSeeMoreInfo}
                      classesAndProgram={classesAndProgram}
                      gymGeneralInformation={gymGeneralInformation}
                    />
                  )}

                  {trailBooking && (
                    <Box
                      textAlign="center"
                      p={3}
                      boxShadow={3}
                      borderRadius={2}
                      mt={2}
                      border="1px solid"
                    >
                      <Typography variant="h6" gutterBottom>
                        Free tour is available only between 11 AM to 5 PM
                        (Monday - Saturday)
                      </Typography>
                      <Typography variant="h5" mb={2}>
                        Book Appointment
                      </Typography>
                      <Box
                        display="flex"
                        justifyContent="center"
                        gap={2}
                        alignItems="center"
                      >
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                        >
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              value={
                                formData.selectedDate
                                  ? Dayjs(formData.selectedDate).utc()
                                  : null
                              }
                              minDate={Dayjs().startOf("day")}
                              onChange={(newDate) =>
                                setFormData({
                                  ...formData,
                                  selectedDate: newDate
                                    ? newDate.utc().startOf("day")
                                    : null,
                                  errorDate: "",
                                })
                              }
                              format="DD/MM/YYYY"
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  fullWidth
                                />
                              )}
                            />
                          </LocalizationProvider>
                          {formData.errorDate && (
                            <Typography color="error" variant="body2">
                              {formData.errorDate}
                            </Typography>
                          )}
                        </Box>
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                        >
                          <Select
                            value={formData.selectedTime}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                selectedTime: e.target.value,
                                errorTime: "",
                              })
                            }
                            displayEmpty
                            variant="outlined"
                            error={!!formData.errorTime}
                          >
                            <MenuItem value="" disabled>
                              Select Time Slot
                            </MenuItem>
                            {timeSlots.map((time, index) => (
                              <MenuItem key={index} value={time}>
                                {time}
                              </MenuItem>
                            ))}
                          </Select>
                          {formData.errorTime && (
                            <Typography color="error" variant="body2">
                              {formData.errorTime}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                      <Button
                        color="primary"
                        variant="contained"
                        sx={{ mt: 3 }}
                        onClick={() => handleBookAppointment(gym)}
                      >
                        Book
                      </Button>
                    </Box>
                  )}

                  {membershipBooking && (
                    <Box
                      textAlign="center"
                      p={3}
                      boxShadow={3}
                      borderRadius={2}
                      mt={2}
                      border="1px solid"
                    >
                      <Typography variant="h5" mb={2}>
                        Book Membership
                      </Typography>
                      <Box
                        display="flex"
                        justifyContent="center"
                        gap={2}
                        alignItems="center"
                      >
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                        >
                          <Typography variant="subtitle1" mb={1}>
                            Select Plan Duration
                          </Typography>
                          <Select
                            value={membershipFormData.selectedPlan}
                            onChange={handlePlanChange}
                            displayEmpty
                            fullWidth
                            error={membershipFormData.errors.plan} // Highlight field in red if error
                            sx={{ minWidth: 200 }}
                          >
                            <MenuItem value="" disabled>
                              Select Duration
                            </MenuItem>
                            {gym.gymPriceList.map((plan, index) => (
                              <MenuItem key={index} value={plan.duration}>
                                {plan.duration}
                              </MenuItem>
                            ))}
                          </Select>
                          {membershipFormData.errors.plan && (
                            <FormHelperText sx={{ color: "red" }}>
                              Plan duration is required
                            </FormHelperText>
                          )}
                        </Box>

                        {/* Price Field (Read-only) */}
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                        >
                          <Typography variant="subtitle1" mb={1}>
                            Membership Price
                          </Typography>
                          <TextField
                            value={membershipFormData.price}
                            fullWidth
                            disabled
                            type="number"
                            error={membershipFormData.errors.price} // Highlight field in red if error
                            sx={{ minWidth: 200 }}
                          />
                          {membershipFormData.errors.price && (
                            <FormHelperText sx={{ color: "red" }}>
                              Price is required
                            </FormHelperText>
                          )}
                        </Box>
                      </Box>

                      <Button
                        color="primary"
                        variant="contained"
                        sx={{ mt: 3 }}
                        onClick={() => handleBookMembership()}
                      >
                        Book
                      </Button>
                    </Box>
                  )}

                  <Modal
                    isOpen={paymentModal}
                    toggle={() => setPaymentModal(false)}
                    centered
                    modalClassName="custom-modal"
                  >
                    <ModalHeader
                      style={{
                        backgroundColor: "black",
                        color: "white",
                        border: "1px solid lightgrey",
                      }}
                      toggle={() => setPaymentModal(false)}
                    >
                      <Typography variant="h6" component="h2">
                        Complete your Payment
                      </Typography>
                    </ModalHeader>
                    <ModalBody className="modal-body-centered">
                      <Typography
                        variant="h5"
                        sx={{
                          color: "purple",
                          fontWeight: "600",
                          fontSize: "1.75rem",
                        }}
                        component="h5"
                      >
                        Uncurl: Health Services
                      </Typography>
                      <Typography
                        variant="h6"
                        // sx={{ color: "purple" }}
                        component="h6"
                      >
                        Gym Services
                      </Typography>
                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={6} sx={{ textAlign: "right" }}>
                          <Typography variant="h6">Plan Duration:</Typography>
                          <Typography variant="h6">Order Value:</Typography>
                          <Typography variant="body1">Gst:</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: "left" }}>
                          <Typography variant="h6">
                            {membershipFormData.selectedPlan}
                          </Typography>
                          <Typography variant="h6">
                            ₹{membershipFormData.price}
                          </Typography>
                          <Typography variant="body1">
                            ₹
                            {membershipFormData.gstAmount &&
                              parseInt(membershipFormData.gstAmount)}
                          </Typography>
                        </Grid>
                      </Grid>

                      {/* Line above the Total */}
                      <Box
                        sx={{
                          borderTop: "2px solid lightgrey",
                          mt: 2,
                          mb: 2,
                          width: "100%",
                        }}
                      />

                      <Grid container spacing={2}>
                        <Grid item xs={6} sx={{ textAlign: "right" }}>
                          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                            Total:
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: "left" }}>
                          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                            ₹{total && total}
                          </Typography>
                        </Grid>
                      </Grid>

                      {/* Line below the Total */}
                      <Box
                        sx={{
                          borderTop: "2px solid lightgrey",
                          mt: 2,
                          mb: 2,
                          width: "100%",
                        }}
                      />
                      <Typography variant="body1" sx={{ mb: 3 }}>
                        This amount will be charged to your account.
                      </Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{
                          backgroundColor: "#5353e9",
                          "&:hover": { backgroundColor: "#c60176" },
                        }}
                        onClick={() => handlePayment(gym?.userId)} // Add your payment handling function here
                      >
                        {paymentLoader ? (
                          <CircularProgress size={24} color="inheri" />
                        ) : (
                          "Confirm & Pay"
                        )}
                      </Button>
                    </ModalBody>
                  </Modal>
                </>
              ))}
        </div>
      ) : (
        <Typography
          align="center"
          variant="h6"
          style={{
            marginTop: "20px",
            fontSize: "18px",
            color: "gray",
            width: "100%",
          }}
        >
          No Gym found in your Pincode Location.
        </Typography>
      )}
    </>
  );
};

export default GymDetail;
