import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useEffect, useState } from "react";
import MyFitness from "./MyFitness";
import { useLocation } from "react-router-dom";
import FitnessIntervention from "./FitnessIntervention";
import ChildrenExercise from "./ChildrenExercise";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import DietIntervention from "./DietIntervention";
import Calendar from "./Calendar";
import MyMentalHealth from "./MyMentalHealth";

const MentalHealth = () => {
  const location = useLocation();
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [familyInfo, setFamilyInfo] = useState();
  const [familyRelation, setFamilyRelation] = useState(null);
  const [dietitianDetail, setdietitianDetail] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const handleTabChange = (e, tabIndex) => {
    console.log(tabIndex);
    setCurrentTabIndex(tabIndex);
  };
  useEffect(() => {
    if (location.state && location.state.psychiatristtabIndex !== undefined) {
      setCurrentTabIndex(location.state.psychiatristtabIndex);
    }
    if (location.state?.selectedPsychiatristOrder) {
      setSelectedOrder(location.state.selectedPsychiatristOrder); // Set the order when navigating
    }
  }, [location.state]);
  const handleOrderSubmit = () => {
    setSelectedOrder(null);
  };
  const handleScheduleCall = (dietitianDetails) => {
    setdietitianDetail(dietitianDetails); // Save the selected order
    setCurrentTabIndex(2); // Navigate to Calendar tab
  };
  useEffect(() => {
    AxiosInstance.get("profile/get")
      .then((response) => {
        console.log("response: ", response);
        if (response != null) {
          setFamilyInfo(response.profile);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    AxiosInstance.get("/family/getPatientFamilyProfile")
      .then((response) => {
        setFamilyRelation(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <div style={{}}>
      <Box sx={{ width: "100%" }}>
        <Tabs
          value={currentTabIndex}
          onChange={handleTabChange}
          style={{ display: "flex", justifyContent: "end" }}
        >
          <Tab
            label="My Mental Health"
            style={{ display: "flex", justifyContent: "end" }}
            sx={{
              textTransform: "capitalize",
              display: "flex",
              justifyContent: "end",
              fontWeight: 600,
              fontSize: "large",
              color: currentTabIndex === 0 ? "blue" : "black",
              "&.Mui-selected": {
                color: "blue",
              },
            }}
          />
          <Tab
            label="Mental Health Intervention"
            style={{ display: "flex", justifyContent: "end" }}
            sx={{
              textTransform: "capitalize",
              fontWeight: 600,
              fontSize: "large",
              color: currentTabIndex === 1 ? "blue" : "black",
              "&.Mui-selected": {
                color: "blue",
              },
            }}
          />
          <Tab
            label="Calendar"
            style={{ display: "flex", justifyContent: "end" }}
            sx={{
              textTransform: "capitalize",
              fontWeight: 600,
              fontSize: "large",
              color: currentTabIndex === 2 ? "blue" : "black",
              "&.Mui-selected": {
                color: "blue",
              },
            }}
          />
        </Tabs>
        {currentTabIndex === 0 && (
          <Box sx={{ p: 3 }}>
            <MyMentalHealth />
          </Box>
        )}
        {currentTabIndex === 1 && (
          <Box sx={{ p: 3 }}>
            <DietIntervention
              selectedOrder={selectedOrder}
              onSubmit={handleOrderSubmit}
              onScheduleCall={handleScheduleCall}
              partnerType="Psychiatrist"
            ></DietIntervention>
          </Box>
        )}
        {currentTabIndex === 2 && (
          <Box sx={{ p: 3 }}>
            <Calendar
              selectedOrder={dietitianDetail}
              partnerType="Psychiatrist"
              onSubmitProposal={() => {
                setSelectedOrder(null);
                setdietitianDetail(null);
              }}
            ></Calendar>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default MentalHealth;
