import React, { useEffect, useState } from "react";
import { Box, Tab, Tabs } from "@mui/material/Box";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import {
  Form,
  FormGroup,
  FormFeedback,
  FormText,
  Label,
  Input,
  Row,
  Col,
  Button,
} from "reactstrap";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Spinner from "../../shared/Spinner";

const GymBilling = () => {
  const [rowData, setRowData] = useState([]);
  const [lshrowData, setLshRowData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [lshpage, setLshPage] = React.useState(0);
  const [lshrowsPerPage, setLshRowsPerPage] = React.useState(10);
  const [loading, setLoading] = useState(false);
  const getOrderBilling = async () => {
    setLoading(true);
    await AxiosInstance.get(`gym/get-gym-order-billing`)
      .then((response) => {
        if (response != null) {
          setRowData(response);
        } else {
          setRowData("");
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("err: ", err);
        setLoading(false);
      });
  };
  const LshOrderBilling = async () => {
    await AxiosInstance.get(`subscription/get-sub-order-billing`)
      .then((response) => {
        console.log("lsh", response);
        setLshRowData(response || []);
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  };
  useEffect(() => {
    getOrderBilling();
    LshOrderBilling();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleLshChangePage = (event, newPage) => {
    setLshPage(newPage);
  };
  const handleLshChangeRowsPerPage = (event) => {
    setLshRowsPerPage(+event.target.value);
    setLshPage(0);
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <h3>Membership Orders Billing</h3>
          <TableContainer>
            <Table style={{ border: "1px solid lightgrey", marginTop: "20px" }}>
              <TableHead>
                <TableRow>
                  <TableCell
                    className="tableCell"
                    style={{ backgroundColor: "rgb(83, 83, 233)" }}
                  >
                    LSH Order ID
                  </TableCell>
                  <TableCell
                    className="tableCell"
                    style={{ backgroundColor: "rgb(83, 83, 233)" }}
                  >
                    Date
                  </TableCell>
                  <TableCell
                    className="tableCell"
                    style={{ backgroundColor: "rgb(83, 83, 233)" }}
                  >
                    Amount
                  </TableCell>
                  <TableCell
                    className="tableCell"
                    style={{ backgroundColor: "rgb(83, 83, 233)" }}
                  >
                    Tax
                  </TableCell>
                  <TableCell
                    className="tableCell"
                    style={{ backgroundColor: "rgb(83, 83, 233)" }}
                  >
                    Total Amount
                  </TableCell>
                  <TableCell
                    className="tableCell"
                    style={{ backgroundColor: "rgb(83, 83, 233)" }}
                  >
                    Order Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowData.length === 0 ? (
                  <TableRow>
                    <TableCell
                      colSpan={7}
                      style={{
                        textAlign: "center",
                        border: "1px solid lightgrey",
                      }}
                    >
                      <b>No data available</b>
                    </TableCell>
                  </TableRow>
                ) : (
                  rowData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <TableRow key={index + page * rowsPerPage}>
                        <TableCell style={{ border: "1px solid lightgrey" }}>
                          {row.orderId}
                        </TableCell>
                        <TableCell style={{ border: "1px solid lightgrey" }}>
                          {row.createdOn}
                        </TableCell>
                        <TableCell style={{ border: "1px solid lightgrey" }}>
                          {row.amount}
                        </TableCell>
                        <TableCell style={{ border: "1px solid lightgrey" }}>
                          {row.tax}
                        </TableCell>
                        <TableCell style={{ border: "1px solid lightgrey" }}>
                          {row.totalAmount}
                        </TableCell>
                        <TableCell style={{ border: "1px solid lightgrey" }}>
                          {row.orderStatus}
                        </TableCell>
                      </TableRow>
                    ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rowData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <div>
            <h3>LSH Membership Orders Billing</h3>
            <TableContainer>
              <Table
                style={{ border: "1px solid lightgrey", marginTop: "20px" }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      className="tableCell"
                      style={{ backgroundColor: "rgb(83, 83, 233)" }}
                    >
                      LSH Order ID
                    </TableCell>
                    <TableCell
                      className="tableCell"
                      style={{ backgroundColor: "rgb(83, 83, 233)" }}
                    >
                      Date
                    </TableCell>
                    <TableCell
                      className="tableCell"
                      style={{ backgroundColor: "rgb(83, 83, 233)" }}
                    >
                      Amount
                    </TableCell>
                    <TableCell
                      className="tableCell"
                      style={{ backgroundColor: "rgb(83, 83, 233)" }}
                    >
                      Tax
                    </TableCell>
                    <TableCell
                      className="tableCell"
                      style={{ backgroundColor: "rgb(83, 83, 233)" }}
                    >
                      Total Amount
                    </TableCell>
                    <TableCell
                      className="tableCell"
                      style={{ backgroundColor: "rgb(83, 83, 233)" }}
                    >
                      Order Status
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {lshrowData.length === 0 ? (
                    <TableRow>
                      <TableCell
                        colSpan={7}
                        style={{
                          textAlign: "center",
                          border: "1px solid lightgrey",
                        }}
                      >
                        <b>No data available</b>
                      </TableCell>
                    </TableRow>
                  ) : (
                    lshrowData
                      .slice(
                        lshpage * lshrowsPerPage,
                        lshpage * lshrowsPerPage + lshrowsPerPage
                      )
                      .map((row, index) => (
                        <TableRow key={index + lshpage * lshrowsPerPage}>
                          <TableCell style={{ border: "1px solid lightgrey" }}>
                            {row.orderId}
                          </TableCell>
                          <TableCell style={{ border: "1px solid lightgrey" }}>
                            {row.createdOn}
                          </TableCell>
                          <TableCell style={{ border: "1px solid lightgrey" }}>
                            {row.amount}
                          </TableCell>
                          <TableCell style={{ border: "1px solid lightgrey" }}>
                            {row.tax}
                          </TableCell>
                          <TableCell style={{ border: "1px solid lightgrey" }}>
                            {row.totalAmount}
                          </TableCell>
                          <TableCell style={{ border: "1px solid lightgrey" }}>
                            {row.orderStatus}
                          </TableCell>
                        </TableRow>
                      ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={lshrowData.length}
              rowsPerPage={lshrowsPerPage}
              page={lshpage}
              onPageChange={handleLshChangePage}
              onRowsPerPageChange={handleLshChangeRowsPerPage}
            />
          </div>
        </>
      )}
    </>
  );
};
export default GymBilling;
