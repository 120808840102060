import React, { useEffect, useState } from "react";
import { Card, CardText, Col, Row } from "reactstrap";
import "./SaveForLater.css";
import Swal from "sweetalert2";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import {
  deleteSavedTests,
  getSaveLaterData,
  moveToCart,
} from "../../constants/apiList";
import { toast } from "react-toastify";
import { useCart } from "../TestCart/CartContext";
const SaveItem = ({ isVisible, onRefresh }) => {
  const { refresh, triggerRefresh } = useCart();
  const [isReload, setIsReload] = useState(false);
  const [testCatalog, setTestCatalog] = useState([]);
  const getSaveLater = async () => {
    await AxiosInstance.get(`${getSaveLaterData}`)
      .then((response) => {
        if (response != null) {
          setTestCatalog(response);
        } else {
          setTestCatalog("");
        }
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  };
  useEffect(() => {
    getSaveLater();
    triggerRefresh();
  }, [isReload]);
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: true,
  });
  const deleteTestsDetails = (cartId, testId, preventiveTest) => {
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: `Do you want to remove this test from Cart ?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, remove it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          AxiosInstance.delete(
            `${deleteSavedTests}/${cartId}/${testId}/${preventiveTest}`
          )
            .then((response) => {
              toast.success(response.message);
              setIsReload(!isReload);
            })
            .catch((err) => {
              console.log("err: ", err);
            });
        }
      });
  };
  const moveToCartFun = (id, testId, preventiveTest) => {
    // testName.trim();
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: `Do you want to move this test ?`,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes, Save it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          AxiosInstance.put(`${moveToCart}/${id}/${testId}/${preventiveTest}`)
            .then((response) => {
              if (isVisible) {
                onRefresh();
              }
              toast.success(response.message);
              setIsReload(!isReload);
            })
            .catch((err) => {
              console.log("err: ", err);
            });
        }
      });
  };
  return (
    <>
      {testCatalog.length > 0 && isVisible ? (
        <h3 className="mb-2">Save for later ({testCatalog.length})</h3>
      ) : (
        ""
      )}
      <Row>
        {testCatalog && testCatalog.length > 0
          ? testCatalog.map((item, index) => (
              <Col sm="3" key={index}>
                {/* <Card body style={{ cursor: "pointer" }} className={`for-font-cart-container ${isVisible ? 'hover-card' : ''}`}> */}
                <Card
                  body
                  style={{ cursor: "pointer" }}
                  className={`for-font-cart-container hover-card`}
                >
                  <CardText className="mb-1">
                    <b>Test Name:</b> {item.testName}
                  </CardText>
                  <CardText className="mb-1">
                    <b>Test Price:</b> {item.testPrice}
                  </CardText>
                  <div style={{ textAlign: "right", marginTop: "5px" }}>
                    <span
                      className="payNow-btn cart-icon-btn m-2"
                      onClick={() =>
                        moveToCartFun(item.id, item.testId, item.preventiveTest)
                      }
                    >
                      Move to Cart
                    </span>
                    &nbsp; | &nbsp;
                    <span
                      className="delete-btn cart-icon-btn"
                      onClick={() =>
                        deleteTestsDetails(
                          item.cartId,
                          item.testId,
                          item.preventiveTest
                        )
                      }
                    >
                      Delete
                    </span>
                  </div>
                </Card>
              </Col>
            ))
          : !isVisible && (
              <div className="mt-4">
                <Row>
                  <Col sm="4">
                    <div className="empty-card">
                      <div className="for-border-radius">
                        <span className="empty-save-icon">!</span>
                      </div>
                    </div>
                  </Col>
                  <Col sm="8">
                    <div className="empty-cart-box">
                      <p className="empty-cart-text">
                        Your Save for later is{" "}
                        <span className="empty-cart-text-empty">Empty!</span>
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            )}
      </Row>
    </>
  );
};
export default SaveItem;
