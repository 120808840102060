import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import PatientOrders from "./PatientOrders";
import PatientReviews from "./PatientReviews";
import PatientOrderHistory from "./PatientOrderHistory";
import { useNavigate } from "react-router-dom";

const PatientOnlineConsultation = () => {
  const navigate = useNavigate();
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const handleTabChange = (e, tabIndex) => {
    setCurrentTabIndex(tabIndex);
  };
  const handlePatientReviewClick = (order) => {
    setSelectedOrder(order);
    if (order.consult === "dietitian") {
      navigate("/lifestyle-modifications", {
        state: { selectedOrder: order, tabIndex: 1, lifestyletabIndex: 1 },
      }); // Navigate to the second tab
    } else if (order.consult === "physician") {
      navigate("/lifestyle-modifications", {
        state: {
          selectedPhysicianOrder: order,
          physiciantabIndex: 1,
          physicianlifestyletabIndex: 2,
        },
      });
    } else if (order.consult === "psychiatrist") {
      navigate("/lifestyle-modifications", {
        state: {
          selectedPsychiatristOrder: order,
          psychiatristtabIndex: 1,
          psychiatristlifestyletabIndex: 4,
        },
      });
    } else {
      setCurrentTabIndex(1); // Handle other service types or statuses if needed
    }
  };
  const handleReviewSubmit = () => {
    setSelectedOrder(null); // Reset the selected order
  };
  return (
    <>
      <Tabs
        value={currentTabIndex}
        onChange={handleTabChange}
        sx={{ backgroundColor: "steelblue" }}
      >
        <Tab
          label="My Order"
          sx={{
            textTransform: "capitalize",
            fontWeight: 600,
            fontSize: "large",
            color: currentTabIndex === 0 ? "white" : "black",
            "&.Mui-selected": {
              color: "white",
            },
          }}
        />
        <Tab
          label="Reviews"
          sx={{
            textTransform: "capitalize",
            fontWeight: 600,
            fontSize: "large",
            color: currentTabIndex === 1 ? "white" : "black",
            "&.Mui-selected": {
              color: "white",
            },
          }}
        />
        <Tab
          label="Order History"
          sx={{
            textTransform: "capitalize",
            fontWeight: 600,
            fontSize: "large",
            color: currentTabIndex === 2 ? "white" : "black",
            "&.Mui-selected": {
              color: "white",
            },
          }}
        />
      </Tabs>
      {currentTabIndex === 0 && (
        <Box sx={{ p: 3 }}>
          <PatientOrders
            onReviewClick={handlePatientReviewClick}
          ></PatientOrders>
        </Box>
      )}
      {currentTabIndex === 1 && (
        <Box sx={{ p: 3 }}>
          <PatientReviews
            order={selectedOrder}
            onReviewSubmit={handleReviewSubmit}
          ></PatientReviews>
        </Box>
      )}
      {currentTabIndex === 2 && (
        <Box sx={{ p: 3 }}>
          <PatientOrderHistory></PatientOrderHistory>
        </Box>
      )}
    </>
  );
};
export default PatientOnlineConsultation;
