import React, { useEffect, useRef, useState } from "react";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Button,
  FormLabel,
  RadioGroup,
  Radio,
  Table,
} from "reactstrap";
import { event } from "react-ga";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddIcon from "@mui/icons-material/Add";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  getGeneralInformation,
  getLicenceInfo,
  getUserDetails,
} from "../../constants/apiList";
import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Spinner from "../../shared/Spinner";
import SampleTestReportModal from "./SampleTestReportModal";
import LabDocumentModal from "./LabDocumentModal";

const stateList = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttarakhand",
  "Uttar Pradesh",
  "West Bengal",
  "Andaman and Nicobar Islands (UT)",
  "Chandigarh (UT)",
  "Dadra and Nagar Haveli and Daman & Diu (UT)",
  "Delhi (UT)",
  "Jammu & Kashmir (UT)",
  "Ladakh (UT)",
  "Lakshadweep (UT)",
  "Puducherry (UT)",
  "Other Territory (UT)",
];

const GeneralInfo = ({ handleTabChange }) => {
  const [generalInfoData, setGeneralInfoData] = useState([]);
  const [labName, setLabName] = useState();
  const [yearStablish, setYearStablish] = useState();
  const [registrationNumber, setRegistrationNumber] = useState();
  const [numberOfLocation, setNumberOfLocation] = useState("");
  const [ownerName, setOwnerName] = useState();
  const [ownerPhone, setOwnerPhone] = useState();
  const [ownerEmail, setOwnerEmail] = useState();
  const [labAddress, setLabAddress] = useState();
  const [labPhoneNumber, setLabPhoneNumber] = useState();
  const [pincode, setPinCode] = useState();
  const [nablAccreditation, setNablAccreditation] = useState();
  const [isoCertificate, setIsoCertificate] = useState();
  const [fieldErrors, setFieldErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [accessNeeded, setAccessNeeded] = useState(false);
  const [sundayIsHoliday, setSundayIsHoliday] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [labPartnerSampleTest, setLabPartnerSampleTest] = useState([]);
  const [editedRows, seteditedRows] = useState([]);
  const [serviceAreaPinCode, setServiceAreaPinCode] = useState([]);
  const [formLoading, setFormLoading] = useState(false);
  const [SampleReportModalOpen, setisSampleReportModalOpen] = useState(false);
  const [selectedSampleTestReport, setselectedSampleTestReport] = useState([]);
  const [adminRows, setAdminRows] = useState([
    {
      id: null,
      adminName: "",
      adminPhoneNumber: "",
      adminEmail: "",
      accessNeeded: false,
    },
  ]);

  const [rows, setRows] = useState([
    {
      id: null,
      labDirectorName: "",
      phoneNumber: "",
      email: "",
      accessNeeded: false,
    },
  ]);
  const [licenserows, setLicenseRows] = useState([
    { state: "", expiryDate: "", fileName: "", city: "", branchName: "" },
  ]);
  const [licenses, setLicenses] = useState([]);
  const [licenseModalOpen, setislicenseModalOpen] = useState(false);
  const [loadingIndex, setLoadingIndex] = useState(null);
  const [nablrows, setNablRows] = useState([
    { state: "", expiryDate: "", fileName: "", city: "", branchName: "" },
  ]);
  const [nablCertificates, setnablCertificates] = useState([]);
  const [nablloadingIndex, setNablLoadingIndex] = useState(null);
  const [nablModalOpen, setisNablModalOpen] = useState(false);
  const [isoCertificates, setisoCertificates] = useState([]);
  const [isorows, setIsoRows] = useState([
    {
      state: "",
      expiryDate: "",
      fileName: "",
      city: "",
      branchName: "",
      isoName: "",
    },
  ]);
  const [isoloadingIndex, setIsoLoadingIndex] = useState(null);
  const [isoModalOpen, setisIsoModalOpen] = useState(false);
  const [timings, setTimings] = useState({
    Monday: { from: "", to: "" },
    Tuesday: { from: "", to: "" },
    Wednesday: { from: "", to: "" },
    Thursday: { from: "", to: "" },
    Friday: { from: "", to: "" },
    Saturday: { from: "", to: "" },
    Sunday: { from: "", to: "" },
  });
  const [sampleTimings, setSampleTimings] = React.useState({
    Monday: { from: "", to: "" },
    Tuesday: { from: "", to: "" },
    Wednesday: { from: "", to: "" },
    Thursday: { from: "", to: "" },
    Friday: { from: "", to: "" },
    Saturday: { from: "", to: "" },
    Sunday: { from: "", to: "" },
  });
  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const handleAddRow = () => {
    setLicenseRows((prevRows) => [
      ...prevRows,
      { state: "", expiryDate: "", fileName: "", city: "", branchName: "" },
    ]);
  };

  const handleNablAddRow = () => {
    setNablRows((prevRows) => [
      ...prevRows,
      { state: "", expiryDate: "", fileName: "", city: "", branchName: "" },
    ]);
  };

  const handleIsoAddRow = () => {
    setIsoRows((prevRows) => [
      ...prevRows,
      {
        state: "",
        expiryDate: "",
        fileName: "",
        city: "",
        branchName: "",
        isoName: "",
      },
    ]);
  };

  // Delete row
  const handleDeleteRow = (index) => {
    setLicenseRows((prevRows) => prevRows.filter((_, i) => i !== index));
    setFieldErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors[`state${index}`];
      delete updatedErrors[`expiryDate${index}`];
      delete updatedErrors[`city${index}`];
      delete updatedErrors[`branchName${index}`];
      return updatedErrors;
    });
  };

  const handleNablDeleteRow = (index) => {
    setNablRows((prevRows) => prevRows.filter((_, i) => i !== index));
    setFieldErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors[`nablstate${index}`];
      delete updatedErrors[`nablexpiryDate${index}`];
      delete updatedErrors[`nablcity${index}`];
      delete updatedErrors[`nablbranchName${index}`];
      return updatedErrors;
    });
  };

  const handleIsoDeleteRow = (index) => {
    setIsoRows((prevRows) => prevRows.filter((_, i) => i !== index));
    setFieldErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors[`isoName${index}`];
      delete updatedErrors[`isostate${index}`];
      delete updatedErrors[`isoexpiryDate${index}`];
      delete updatedErrors[`isocity${index}`];
      delete updatedErrors[`isobranchName${index}`];
      return updatedErrors;
    });
  };

  // Handle file upload
  const handleFileUpload = (e, index) => {
    // e.preventDefault();
    const file = e.target.files[0];
    if (!file) return;

    // Validate state and expiry date before allowing file upload
    const errors = {};
    if (!licenserows[index].state) {
      errors[`state${index}`] = "Please fill state before attaching the file.";
    }
    if (!licenserows[index].expiryDate) {
      errors[`expiryDate${index}`] =
        "Please fill expiry date before attaching the file.";
    }
    if (!licenserows[index].city) {
      errors[`city${index}`] = "Please fill city before attaching the file.";
    }
    if (!licenserows[index].branchName) {
      errors[`branchName${index}`] =
        "Please fill Branch Name before attaching the file.";
    }

    if (Object.keys(errors).length > 0) {
      setFieldErrors((prevErrors) => ({ ...prevErrors, ...errors }));
      return;
    }

    // Simulate file upload
    setLoadingIndex(index);
    setTimeout(() => {
      setLicenseRows((prevRows) => {
        const updatedRows = [...prevRows];
        updatedRows[index].fileName = file.name;
        const isAnyRowValid = updatedRows.some(
          (row) =>
            row.state &&
            row.expiryDate &&
            row.fileName &&
            row.branchName &&
            row.city
        );

        // Update errors
        setFieldErrors((prevErrors) => {
          if (isAnyRowValid) {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors.noRows; // Clear noRows error
            return updatedErrors;
          }
          return prevErrors;
        });
        return updatedRows;
      });
    }, 1000);

    let fileCheck = file.fileName?.split(".")?.pop();
    if (
      fileCheck == "gif" ||
      fileCheck == "jfif" ||
      fileCheck == "csv" ||
      fileCheck == "xls"
    ) {
      toast.error("GIF, csv, xls and Jfif file are not allowed");
    } else {
      const documentType = "LICENSE";
      const formData = new FormData();
      formData.append("file", file);

      formData.append("branchName", licenserows[index].branchName);
      formData.append("city", licenserows[index].city);
      formData.append("state", licenserows[index].state);
      formData.append("expiryDate", licenserows[index].expiryDate);
      formData.append("licenseNames", "License");

      AxiosInstance.post(
        `/lab-partner/licenses-upload/${documentType}`,
        formData
      )
        .then((response) => {
          toast.success("Document Uploaded Successfully");
          setLoadingIndex(null);
          // GetLicenceInfo();
        })
        .catch((error) => {
          setLoadingIndex(null);
          //setError("An
        });
    }
  };
  const handleIsoFileUpload = (e, index) => {
    // e.preventDefault();
    const file = e.target.files[0];
    if (!file) return;

    // Validate state and expiry date before allowing file upload
    const errors = {};
    if (!isorows[index].isoName) {
      errors[`isoName${index}`] =
        "Please fill Iso Name before attaching the file.";
    }
    if (!isorows[index].state) {
      errors[`isostate${index}`] =
        "Please fill state before attaching the file.";
    }
    if (!isorows[index].expiryDate) {
      errors[`isoexpiryDate${index}`] =
        "Please fill expiry date before attaching the file.";
    }
    if (!isorows[index].city) {
      errors[`isocity${index}`] = "Please fill city before attaching the file.";
    }
    if (!isorows[index].branchName) {
      errors[`isobranchName${index}`] =
        "Please fill Branch Name before attaching the file.";
    }

    if (Object.keys(errors).length > 0) {
      setFieldErrors((prevErrors) => ({ ...prevErrors, ...errors }));
      return;
    }

    // Simulate file upload
    setIsoLoadingIndex(index);
    setTimeout(() => {
      setIsoRows((prevRows) => {
        const updatedRows = [...prevRows];
        updatedRows[index].fileName = file.name;
        const isAnyRowValid = updatedRows.some(
          (row) =>
            row.state &&
            row.expiryDate &&
            row.fileName &&
            row.branchName &&
            row.city &&
            row.isoName
        );

        // Update errors
        setFieldErrors((prevErrors) => {
          if (isAnyRowValid) {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors.noIsoRows; // Clear noRows error
            return updatedErrors;
          }
          return prevErrors;
        });
        return updatedRows;
      });
    }, 1000);

    let fileCheck = file.fileName?.split(".")?.pop();
    if (
      fileCheck == "gif" ||
      fileCheck == "jfif" ||
      fileCheck == "csv" ||
      fileCheck == "xls"
    ) {
      toast.error("GIF, csv, xls and Jfif file are not allowed");
    } else {
      const documentType = "ISO_CERTIFICATE";
      const formData = new FormData();
      formData.append("file", file);

      formData.append("branchName", isorows[index].branchName);
      formData.append("city", isorows[index].city);
      formData.append("state", isorows[index].state);
      formData.append("expiryDate", isorows[index].expiryDate);
      formData.append("licenseNames", isorows[index].isoName);

      AxiosInstance.post(
        `/lab-partner/licenses-upload/${documentType}`,
        formData
      )
        .then((response) => {
          toast.success("Document Uploaded Successfully");
          setIsoLoadingIndex(null);
          // GetLicenceInfo();
        })
        .catch((error) => {
          setIsoLoadingIndex(null);
          //setError("An
        });
    }
  };
  const handleNablFileUpload = (e, index) => {
    // e.preventDefault();
    const file = e.target.files[0];
    if (!file) return;

    // Validate state and expiry date before allowing file upload
    const errors = {};
    if (!nablrows[index].state) {
      errors[`nablstate${index}`] =
        "Please fill state before attaching the file.";
    }
    if (!nablrows[index].expiryDate) {
      errors[`nablexpiryDate${index}`] =
        "Please fill expiry date before attaching the file.";
    }
    if (!nablrows[index].city) {
      errors[`nablcity${index}`] =
        "Please fill city before attaching the file.";
    }
    if (!nablrows[index].branchName) {
      errors[`nablbranchName${index}`] =
        "Please fill Branch Name before attaching the file.";
    }

    if (Object.keys(errors).length > 0) {
      setFieldErrors((prevErrors) => ({ ...prevErrors, ...errors }));
      return;
    }

    // Simulate file upload
    setNablLoadingIndex(index);
    setTimeout(() => {
      setNablRows((prevRows) => {
        const updatedRows = [...prevRows];
        updatedRows[index].fileName = file.name;
        const isAnyRowValid = updatedRows.some(
          (row) =>
            row.state &&
            row.expiryDate &&
            row.fileName &&
            row.branchName &&
            row.city
        );

        // Update errors
        setFieldErrors((prevErrors) => {
          if (isAnyRowValid) {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors.noNablRows; // Clear noRows error
            return updatedErrors;
          }
          return prevErrors;
        });
        return updatedRows;
      });
    }, 1000);

    let fileCheck = file.fileName?.split(".")?.pop();
    if (
      fileCheck == "gif" ||
      fileCheck == "jfif" ||
      fileCheck == "csv" ||
      fileCheck == "xls"
    ) {
      toast.error("GIF, csv, xls and Jfif file are not allowed");
    } else {
      const documentType = "NABL_CERTIFICATE";
      const formData = new FormData();
      formData.append("file", file);

      formData.append("branchName", nablrows[index].branchName);
      formData.append("city", nablrows[index].city);
      formData.append("state", nablrows[index].state);
      formData.append("expiryDate", nablrows[index].expiryDate);
      formData.append("licenseNames", "NABL_Certificate");

      AxiosInstance.post(
        `/lab-partner/licenses-upload/${documentType}`,
        formData
      )
        .then((response) => {
          toast.success("Document Uploaded Successfully");
          setNablLoadingIndex(null);
          // GetLicenceInfo();
        })
        .catch((error) => {
          setNablLoadingIndex(null);
          //setError("An
        });
    }
  };
  const handleTimingChange = (day, field, value) => {
    setTimings((prev) => {
      const updatedTimings = {
        ...prev,
        [day]: {
          ...prev[day],
          [field]: value,
        },
      };

      // Check if any field in timings is filled and clear the error
      const isTimingsValid = Object.values(updatedTimings).some(
        (day) => day.from.trim() !== "" && day.to.trim() !== ""
      );

      if (isTimingsValid) {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          timings: false,
        }));
      }

      return updatedTimings;
    });
  };

  const handleSampleTimingChange = (day, field, value) => {
    setSampleTimings((prev) => {
      const updatedSampleTimings = {
        ...prev,
        [day]: { ...prev[day], [field]: value },
      };

      // Check if any field in sampleTimings is filled and clear the error
      const isSampleTimingsValid = Object.values(updatedSampleTimings).some(
        (day) => day.from.trim() !== "" && day.to.trim() !== ""
      );

      if (isSampleTimingsValid) {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          sampleTimings: false,
        }));
      }

      return updatedSampleTimings;
    });
  };

  const addRow = () => {
    setRows([
      ...rows,
      {
        id: null,
        labDirectorName: "",
        phoneNumber: "",
        email: "",
        accessNeeded: false,
      },
    ]);
  };
  const userDetails = JSON.parse(window.sessionStorage.getItem("userDetails"));
  // const [isoStandards, setIsoStandards] = useState([""]); // Initial field

  // Handle opening the View Modal

  const handleChange = (index, e, checked) => {
    const { name } = e.target;

    const newRows = [...rows];
    if (name.startsWith("accessNeeded")) {
      newRows[index]["accessNeeded"] = checked; // Update accessNeeded directly
    } else {
      newRows[index][name] = e.target.value; // Update other properties
    }
    setRows(newRows);
  };

  const deleteDirectorRow = (index) => {
    setRows((prevRows) => prevRows.filter((_, i) => i !== index));
  };

  const handleDirectorChange = (id, e) => {
    const newRows = [...generalInfoData?.labUsers];
    const index = newRows.findIndex((row) => row.id === id);
    if (index !== -1) {
      newRows[index][e.target.name] = e.target.value;
      seteditedRows(newRows);
    }
  };

  const handleDirector2Change = (id, fieldName, value) => {
    const newRows = generalInfoData?.labUsers.map((row) => {
      if (row.id === id) {
        return { ...row, [fieldName]: value };
      }
      return row;
    });
    setGeneralInfoData((prevData) => ({
      ...prevData,
      labUsers: newRows,
    }));
    seteditedRows(newRows);
  };

  const handleLabName = (event) => {
    setLabName(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      LabName: false,
    }));
  };
  const handleYearStablish = (event) => {
    setYearStablish(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      yearStablish: false,
    }));
  };
  const handleRegistration = (event) => {
    setRegistrationNumber(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      registrationNumber: false,
    }));
  };
  const handleNumberofLocation = (event) => {
    setNumberOfLocation(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      numberOfLocation: false,
    }));
  };
  const handleOwnerName = (event) => {
    setOwnerName(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      ownerName: false,
    }));
  };
  const handleOwnerPhone = (event) => {
    setOwnerPhone(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      ownerPhone: false,
    }));
  };
  const handleOwnerEmail = (event) => {
    setOwnerEmail(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      ownerEmail: false,
    }));
  };
  const handleLabAddress = (event) => {
    setLabAddress(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      labAddress: false,
    }));
  };
  const handlelabPhoneNumber = (event) => {
    setLabPhoneNumber(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      labPhoneNumber: false,
    }));
  };
  const handlePinCode = (event) => {
    setPinCode(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      pincode: false,
    }));
  };

  const handleNablAccreditationChange = (value) => {
    setNablAccreditation(value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      nablAccreditation: value === null,
    }));
  };
  const handleIsoCertification = (value) => {
    setIsoCertificate(value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      isoCertificate: value === null,
    }));
  };

  const deleteAdminRow = (index) => {
    setAdminRows((prevRows) => prevRows.filter((_, i) => i !== index));
  };

  const handleAdminChange = (index, event, accessValue = null) => {
    const { name, value } = event.target;
    const updatedRows = [...adminRows];
    const updatedErrors = { ...fieldErrors };
    if (name.includes("adminAccessNeeded")) {
      updatedRows[index].accessNeeded = accessValue;
    } else {
      updatedRows[index][name] = value;
      if (name === "adminPhoneNumber" && value.length === 10) {
        if (updatedErrors.adminRows && updatedErrors.adminRows[index]) {
          delete updatedErrors.adminRows[index];
        }
      }
    }
    setAdminRows(updatedRows);
    setFieldErrors(updatedErrors);
  };

  const addAdminRow = () => {
    setAdminRows([
      ...adminRows,
      {
        id: null,
        adminName: "",
        adminPhoneNumber: "",
        adminEmail: "",
        accessNeeded: false,
        isNew: true,
      },
    ]);
  };

  const transformTimingsToLabTiming = (timings) => {
    return Object.keys(timings).map((day) => ({
      day: day,
      startTime: timings[day].from,
      endTime: timings[day].to,
    }));
  };
  const transformLabTimingToTimings = (labTimingArray) => {
    return labTimingArray.reduce((acc, item) => {
      acc[item.day] = {
        from: item.startTime,
        to: item.endTime,
      };
      return acc;
    }, {});
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let error = {};
    let adminErrors = {};
    if (labName == undefined || labName == "" || labName == null) {
      error.LabName = true;
    }
    if (yearStablish == undefined || yearStablish == "") {
      error.yearStablish = true;
    }
    if (
      registrationNumber === undefined ||
      registrationNumber === "" ||
      registrationNumber === null
    ) {
      error.registrationNumber = true;
    }
    if (
      numberOfLocation === undefined ||
      numberOfLocation === "" ||
      numberOfLocation == null
    ) {
      error.numberOfLocation = true;
    }

    adminRows.forEach((row, index) => {
      if (!row.adminPhoneNumber || row.adminPhoneNumber.length !== 10) {
        adminErrors[index] = "Please enter a 10-digit phone number.";
      }
    });

    // Add row-specific errors to the main error object
    if (Object.keys(adminErrors).length > 0) {
      error.adminRows = adminErrors;
    }

    if (licenses.length === 0) {
      const isAnyRowValid = licenserows.some(
        (row) =>
          row.state &&
          row.expiryDate &&
          row.fileName &&
          row.branchName &&
          row.city
      );

      if (!isAnyRowValid) {
        error.noRows =
          "Please attach at least one license with all fields filled.";
      }
    }

    // Perform further validation on individual rows if needed
    // licenserows.forEach((row, index) => {
    //   if (!row.state) {
    //     error[`state${index}`] = "State is required.";
    //   }
    //   if (!row.expiryDate) {
    //     error[`expiryDate${index}`] = "Expiry Date is required.";
    //   }
    //   if (!row.fileName) {
    //     error[`fileName${index}`] = "File is required.";
    //   }
    // });
    if (serviceProvideWithSameAddress === undefined) {
      error.sampleCollectionService = true;
    }
    if (nablAccreditation === undefined) {
      error.nablAccreditation = true;
    }
    if (isoCertificate === undefined) {
      error.isoCertificate = true;
    }
    if (labAddress === undefined || labAddress === "" || labAddress == null) {
      error.labAddress = true;
    }
    if (pincode === undefined || pincode === "" || pincode === null) {
      error.pincode = true;
    }

    const isTimingsValid = Object.values(timings).some(
      (day) => day.from.trim() !== "" && day.to.trim() !== ""
    );

    if (!isTimingsValid) {
      error.timings = true;
    }
    if (serviceProvideWithSameAddress === true) {
      const isSampleTimingsValid = Object.values(sampleTimings).some(
        (day) => day.from.trim() !== "" && day.to.trim() !== ""
      );
      if (!isSampleTimingsValid) {
        error.sampleTimings = true;
      }
    }

    if (
      labPhoneNumber === undefined ||
      labPhoneNumber === "" ||
      labPhoneNumber === null
    ) {
      error.labPhoneNumber = true;
    }

    if (nablAccreditation && nablCertificates.length === 0) {
      const isAnyRowValid = nablrows.some(
        (row) =>
          row.state &&
          row.expiryDate &&
          row.fileName &&
          row.branchName &&
          row.city
      );

      if (!isAnyRowValid) {
        error.noNablRows =
          "Please attach at least one Nabl Certificate with all fields filled.";
      }
    }

    if (isoCertificate && isoCertificates.length === 0) {
      const isAnyRowValid = isorows.some(
        (row) =>
          row.state &&
          row.expiryDate &&
          row.fileName &&
          row.branchName &&
          row.city &&
          row.isoName
      );

      if (!isAnyRowValid) {
        error.noIsoRows =
          "Please attach at least one ISO Certificate with all fields filled.";
      }
    }
    if (ownerPhone?.length > 10 || ownerPhone?.length < 1) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Enter valid phone number",
      });
    }
    setFieldErrors(error);
    if (Object.keys(error).length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Fill all the Mandatory Fields!",
      });
    } else {
      const isRowEmpty = (row) => {
        return rows.length > 0 && rows[0].labDirectorName === "";
      };
      let combinedArray;

      if (isRowEmpty(rows)) {
        combinedArray = [...editedRows];
      } else {
        combinedArray = [...editedRows, ...rows];
      }

      const labTimingArray = transformTimingsToLabTiming(timings);
      const sampleTimingArray = transformTimingsToLabTiming(sampleTimings);
      const labMainAddress = {
        labPinCode: pincode, // Assuming this comes from another source
        labAddress: labAddress,
        labPhoneNumber: labPhoneNumber,
        labTiming: labTimingArray,
        coordinatorDetailsDto: adminRows,
        serviceProvideWithSameAddress: serviceProvideWithSameAddress, // Adjust as needed
        labServiceAreaPinCode: {
          serviceAreaPinCode: pincodeRow.map((row) => row.serviceAreaPinCode),
          sampleCollectionTiming: sampleTimingArray,
        },
      };
      const formData = {
        labName: generalInfoData?.labName ? generalInfoData?.labName : labName,
        establishedYear: generalInfoData?.establishedYear
          ? generalInfoData?.establishedYear
          : yearStablish,
        registrationNumber: generalInfoData?.registrationNumber
          ? generalInfoData?.registrationNumber
          : registrationNumber,
        numberOfLocation: numberOfLocation,
        labUsers: combinedArray,
        labNabl: nablAccreditation,
        labIso: isoCertificate,
        labMainAddress: labMainAddress,
        sundayIsHoliday: sundayIsHoliday,
      };
      setSubmitting(true);
      AxiosInstance.put("lab-partner/general-info", formData)
        .then((response) => {
          setLabName("");
          setRegistrationNumber("");
          setYearStablish("");
          toast.success("Information submitted successfully");
          setSubmitting(false);
          setTimeout(() => {
            // handleTabChange(null,1)
            // window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
          setSubmitting(false);
        });
    }
  };

  const handleMultipleChange = async (event) => {
    event.preventDefault();
    const fileArray = Array.from(event.target.files);
    // if (files.length < 8) {
    //   toast.error("Please select at least 8 files.");
    //   return;
    // }
    const invalidFiles = fileArray.filter((file) => {
      const fileCheck = file.name.split(".").pop().toLowerCase(); // Extract the file extension
      return ["gif", "jfif", "csv", "xls"].includes(fileCheck); // Invalid types
    });
    if (invalidFiles.length > 0) {
      toast.error("GIF, CSV, XLS, and JFIF files are not allowed");
      return;
    } else {
      setIsLoading(true);
      setselectedSampleTestReport([...selectedSampleTestReport, ...fileArray]);
      const formData = new FormData();
      for (const file of fileArray) {
        formData.append("file", file);
      }
      AxiosInstance.post("/lab-partner/sample-test-upload", formData)
        .then((response) => {
          toast.success("Document Uploaded Successfully");
          setIsOpen(false);
          setFileName(null);
        })
        .catch((error) => {
          //setError("An
        })
        .finally(() => {
          setIsLoading(false); // Stop loader
        });
    }
  };
  const GetLicenceInfo = async () => {
    await AxiosInstance.get(`${getLicenceInfo}`)
      .then((response) => {
        setLicenses(response?.labPartnerLicense || []);
        setLabPartnerSampleTest(response?.labPartnerSampleTest || []);
        setnablCertificates(response?.labNablCertificate || []);
        setisoCertificates(response?.labIsoCertificate || []);
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  };

  const GetGeneralInformation = async () => {
    setFormLoading(true);
    await AxiosInstance.get(`${getGeneralInformation}`)
      .then((response) => {
        setLabPhoneNumber(response?.labMainAddress?.labPhoneNumber);
        setPinCode(response?.labMainAddress?.labPinCode);
        setGeneralInfoData(response);
        setLabName(response?.labName);
        setYearStablish(response?.establishedYear);
        setRegistrationNumber(response?.registrationNumber);
        setNumberOfLocation(response?.numberOfLocation);
        setLabAddress(response?.labMainAddress?.labAddress);
        setLabPhoneNumber(response?.labMainAddress?.labPhoneNumber);
        setPinCode(response?.labMainAddress?.labPinCode);
        setServiceProvideWithSameAddress(
          response?.labMainAddress?.serviceProvideWithSameAddress
        );
        setAdminRows(
          response?.labMainAddress.coordinatorDetailsDto
            ? response.labMainAddress.coordinatorDetailsDto.map((row) => ({
                ...row,
                isNew: false, // Mark rows from API as not new
              }))
            : adminRows
        );
        setTimings(
          response.labMainAddress.labTiming
            ? transformLabTimingToTimings(response.labMainAddress.labTiming)
            : timings
        );
        if (response?.labMainAddress?.serviceProvideWithSameAddress) {
          setSampleTimings(
            response.labMainAddress.labServiceAreaPinCode
              ?.sampleCollectionTiming
              ? transformLabTimingToTimings(
                  response.labMainAddress.labServiceAreaPinCode
                    ?.sampleCollectionTiming
                )
              : sampleTimings
          );
          setServiceAreaPinCode(response?.labMainAddress);
        }
        setIsoCertificate(response?.labIso);
        setNablAccreditation(response?.labNabl);
        setSundayIsHoliday(response?.sundayIsHoliday);
        setFormLoading(false);
      })
      .catch((err) => {
        console.log("err: ", err);
        setFormLoading(false);
      });
  };
  useEffect(() => {
    GetGeneralInformation();
    GetLicenceInfo();
    setEmail(userDetails?.email);
    setPhoneNumber(userDetails?.phoneNumber);
    setFirstName(userDetails?.firstName);
    setLastName(userDetails?.lastName);
  }, []);
  useEffect(() => {
    GetLicenceInfo();
  }, []);

  const IOSCertificate = "ISOCertificate";

  const [pincodeRow, setPincodeRow] = useState([{ serviceAreaPinCode: "" }]);
  const addPincode = () => {
    setPincodeRow([...pincodeRow, { serviceAreaPinCode: "" }]);
  };

  // Function to handle pin code change
  const handlePincodeChange = (index, e) => {
    const updatedPincodeRow = [...pincodeRow];
    updatedPincodeRow[index].serviceAreaPinCode = e.target.value;
    setPincodeRow(updatedPincodeRow);
  };
  const [serviceProvideWithSameAddress, setServiceProvideWithSameAddress] =
    useState();

  console.log("ad,min rows", adminRows, fieldErrors);
  return (
    <>
      {formLoading ? (
        <Spinner />
      ) : (
        <>
          <div style={{ float: "inline-end", color: "red" }}>
            Note : If Number of Location is 0 or 1 then you have to set Sample
            Collection service as "Yes" and fill all the fields
          </div>
          <Form
            style={{
              border: "1px solid lightgrey",
              padding: "15px",
              marginTop: "25px",
              boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
              fontSize: "18px",
            }}
          >
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="exampleEmail">
                    <b>Name of the Lab</b>
                  </Label>
                  <Input
                    disabled={generalInfoData?.labName ? true : false}
                    id=""
                    name="name"
                    value={
                      generalInfoData?.labName
                        ? generalInfoData?.labName
                        : labName
                    }
                    onChange={(e) => {
                      handleLabName(e);
                    }}
                    placeholder="Enter name of your Lab"
                    type="text"
                    style={{
                      borderColor: fieldErrors.LabName ? "red" : "lightgrey",
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="examplePassword">
                    <b>Year Established</b>
                  </Label>
                  <Input
                    id=""
                    name="yearEstablished"
                    value={
                      generalInfoData?.establishedYear
                        ? generalInfoData?.establishedYear
                        : yearStablish
                    }
                    onChange={(e) => {
                      handleYearStablish(e);
                    }}
                    placeholder=""
                    type="date"
                    style={{
                      borderColor: fieldErrors.yearStablish
                        ? "red"
                        : "lightgrey",
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <hr style={{ border: "1px solid #000000" }}></hr>
            <Row className="mt-4">
              <Col md={6}>
                <FormGroup>
                  <Label for="exampleEmail">
                    <b>Registration Number</b>
                  </Label>
                  <Input
                    id=""
                    name="registrationNumber"
                    value={
                      generalInfoData?.registrationNumber
                        ? generalInfoData?.registrationNumber
                        : registrationNumber
                    }
                    onChange={(e) => {
                      handleRegistration(e);
                    }}
                    placeholder="Enter Registration Number"
                    disabled={
                      generalInfoData?.registrationNumber ? true : false
                    }
                    type="text"
                    style={{
                      borderColor: fieldErrors.registrationNumber
                        ? "red"
                        : "lightgrey",
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="examplePassword">
                    <b>No. of State Operations</b>
                  </Label>
                  <Input
                    id=""
                    name="NumofLocation"
                    value={numberOfLocation}
                    onChange={(e) => {
                      handleNumberofLocation(e);
                    }}
                    placeholder="Enter Number of Location"
                    type="number"
                    min={0}
                    style={{
                      borderColor: fieldErrors.numberOfLocation
                        ? "red"
                        : "lightgrey",
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={4}>
                <FormGroup>
                  <Label for={`labDirectorName`}>
                    <b>Administrator/Coordinator Name (Primary)</b>
                  </Label>
                  <Input
                    disabled
                    id={`labDirectorName`}
                    name="labDirectorName"
                    placeholder="Enter Name"
                    type="text"
                    value={firstName + " " + lastName}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for={`phoneNumber`}>
                    <b>Phone Number</b>
                  </Label>
                  <Input
                    disabled
                    id={`phoneNumber`}
                    name="phoneNumber"
                    placeholder="Enter Phone Number"
                    type="number"
                    value={phoneNumber}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for={`emailIndex`}>
                    <b>Email Id</b>
                  </Label>
                  <Input
                    disabled
                    id={`email`}
                    name="email"
                    placeholder="Enter Email Id"
                    type="email"
                    value={email}
                  />
                </FormGroup>
              </Col>
            </Row>
            <hr style={{ border: "1px solid #000000" }}></hr>
            {generalInfoData?.labUsers &&
              generalInfoData?.labUsers?.map((row, index) => (
                <>
                  <Row key={index} className="mt-4">
                    <Col md={3}>
                      <FormGroup>
                        <Label for={row.id}>
                          <b>Director / Founder / Manager Name</b>
                        </Label>
                        <Input
                          id={row.id}
                          name="labDirectorName"
                          placeholder="Enter Name"
                          type="text"
                          value={row.labDirectorName}
                          onChange={(e) => {
                            // handleChange(index, e);
                            // setOwnerName(e.target.value);
                            handleDirectorChange(row.id, e);
                            handleOwnerName(e);
                          }}
                          style={{
                            borderColor: fieldErrors.ownerName
                              ? "red"
                              : "lightgrey",
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <Label for={row.id}>
                          <b>Phone Number</b>
                        </Label>
                        <Input
                          id={row.id}
                          name="phoneNumber"
                          placeholder="Enter Phone Number"
                          type="number"
                          value={row.phoneNumber}
                          onChange={(e) => {
                            // handleChange(index, e);
                            handleDirectorChange(row.id, e);
                            setOwnerPhone(e.target.value);
                            handleOwnerPhone(e);
                          }}
                          style={{
                            borderColor: fieldErrors.ownerPhone
                              ? "red"
                              : "lightgrey",
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <Label for={row.id}>
                          <b>Email Id</b>
                        </Label>
                        <Input
                          id={row.id}
                          name="email"
                          placeholder="Enter Email Id"
                          type="email"
                          value={row.email}
                          onChange={(e) => {
                            // handleChange(index, e);
                            setOwnerEmail(e.target.value);
                            handleOwnerEmail(e);
                            handleDirectorChange(row.id, e);
                          }}
                          style={{
                            borderColor: fieldErrors.ownerEmail
                              ? "red"
                              : "lightgrey",
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Label for={`accessNeeded${index}`}>
                          <b>Does this person needs access to this account?</b>
                        </Label>
                        <br></br>
                        <Input
                          name={`accessNeeded${row.id}`}
                          type="radio"
                          checked={!row.accessNeeded}
                          value="false"
                          onChange={(e) => {
                            // handleChange(index, e);
                            setAccessNeeded(false);
                            handleDirector2Change(
                              row.id,
                              "accessNeeded",
                              false
                            );
                          }}
                        />
                        &nbsp;
                        <Label check className="ml-1">
                          No
                        </Label>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Input
                          name={`accessNeeded${row.id}`}
                          type="radio"
                          checked={row.accessNeeded}
                          value="true"
                          onChange={(e) => {
                            // handleChange(index, e);
                            setAccessNeeded(true);
                            handleDirector2Change(row.id, "accessNeeded", true);
                          }}
                        />
                        &nbsp;
                        <Label check className="ml-1">
                          Yes
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col md={1}>
                      <FormGroup></FormGroup>
                    </Col>
                  </Row>
                </>
              ))}
            <hr style={{ border: "1px solid #000000" }}></hr>
            <p style={{ color: "red" }}>
              Want to add more Directors then click on "Add Directors"{" "}
            </p>
            {rows &&
              rows?.map((row, index) => (
                <>
                  <Row key={index} className="mt-4">
                    <Col md={4}>
                      <FormGroup>
                        <Label for={`labDirectorName${index}`}>
                          <b>Director / Founder / Manager Name</b>
                        </Label>
                        <Input
                          id={`labDirectorName${index}`}
                          name="labDirectorName"
                          placeholder="Enter Name"
                          type="text"
                          value={row.labDirectorName}
                          onChange={(e) => {
                            handleChange(index, e);
                            setOwnerName(e.target.value);
                            handleOwnerName(e);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <Label for={`phoneNumber${index}`}>
                          <b>Phone Number</b>
                        </Label>
                        <Input
                          id={`phoneNumber${index}`}
                          name="phoneNumber"
                          placeholder="Enter Phone Number"
                          type="number"
                          value={row.phoneNumber}
                          onChange={(e) => {
                            handleChange(index, e);
                            setOwnerPhone(e.target.value);
                            handleOwnerPhone(e);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <Label for={`email${index}`}>
                          <b>Email Id</b>
                        </Label>
                        <Input
                          id={`email${index}`}
                          name="email"
                          placeholder="Enter Email Id"
                          type="email"
                          value={row.email}
                          onChange={(e) => {
                            handleChange(index, e);
                            setOwnerEmail(e.target.value);
                            handleOwnerEmail(e);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label for={`accessNeeded${index}`}>
                          <b>Does this person needs access to this account?</b>
                        </Label>
                        <br></br>
                        <Input
                          name={`accessNeededDirector${index}`}
                          type="radio"
                          checked={!row.accessNeeded}
                          // value={row.accessNeeded}
                          onChange={(e) => {
                            handleChange(index, e, false);
                            setAccessNeeded(false);
                          }}
                        />
                        &nbsp;
                        <Label check className="ml-1">
                          No
                        </Label>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Input
                          name={`accessNeeded${index}`}
                          type="radio"
                          checked={row.accessNeeded}
                          onChange={(e) => {
                            handleChange(index, e, true);
                            setAccessNeeded(true);
                          }}
                        />
                        &nbsp;
                        <Label check className="ml-1">
                          Yes
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col md={1}>
                      <FormGroup>
                        <Button
                          color="danger"
                          outline
                          style={{ marginTop: "33px", fontSize: "12px" }}
                          onClick={() => deleteDirectorRow(index)}
                        >
                          Delete
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </>
              ))}
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Button
                    color="danger"
                    outline
                    style={{ marginTop: "33px", fontSize: "12px" }}
                    onClick={addRow}
                  >
                    Add Directors
                  </Button>
                </FormGroup>
              </Col>
            </Row>
            <hr style={{ border: "1px solid #000000" }}></hr>
            {/* {labMainAddress &&
            Array.isArray(labMainAddress).map((index, row) => { */}
            <p style={{ color: "red" }}>
              Want to add more Administrator/Coordinator then click on "Add
              Administrator/Coordinator"
            </p>
            {adminRows &&
              adminRows?.map((adminRow, adminIndex) => (
                <>
                  <Row key={adminIndex} className="mt-4">
                    <Col md={4}>
                      <FormGroup>
                        <Label for={`adminName${adminIndex}`}>
                          <b>Administrator/Coordinator Name</b>
                        </Label>
                        <Input
                          id={`adminName${adminIndex}`}
                          name="adminName"
                          placeholder="Enter Name"
                          type="text"
                          value={adminRow.adminName}
                          onChange={(e) => {
                            handleAdminChange(adminIndex, e);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <Label for={`adminPhoneNumber${adminIndex}`}>
                          <b>Phone Number</b>
                        </Label>
                        <Input
                          id={`adminPhoneNumber${adminIndex}`}
                          name="adminPhoneNumber"
                          placeholder="Enter Phone Number"
                          type="text"
                          maxLength={10}
                          value={adminRow.adminPhoneNumber}
                          onChange={(e) => {
                            const value = e.target.value;
                            // Allow only digits and restrict to 10 characters
                            if (/^\d{0,10}$/.test(value)) {
                              handleAdminChange(adminIndex, e);
                            }
                          }}
                        />
                        {fieldErrors?.adminRows?.[adminIndex] && (
                          <span style={{ color: "red", fontSize: "12px" }}>
                            {fieldErrors.adminRows[adminIndex]}
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <Label for={`adminEmail${adminIndex}`}>
                          <b>Email Id</b>
                        </Label>
                        <Input
                          id={`adminEmail${adminIndex}`}
                          name="adminEmail"
                          placeholder="Enter Email Id"
                          type="email"
                          value={adminRow.adminEmail}
                          onChange={(e) => {
                            handleAdminChange(adminIndex, e);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label for={`adminAccessNeeded${adminIndex}`}>
                          <b>Does this person needs access to this account?</b>
                        </Label>
                        <br />
                        <Input
                          name={`adminAccessNeeded${adminIndex}`}
                          type="radio"
                          checked={!adminRow.accessNeeded}
                          onChange={(e) => {
                            handleAdminChange(adminIndex, e, false);
                          }}
                        />
                        &nbsp;
                        <Label check className="ml-1">
                          No
                        </Label>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Input
                          name={`adminAccessNeeded${adminIndex}`}
                          type="radio"
                          checked={adminRow.accessNeeded}
                          onChange={(e) => {
                            handleAdminChange(adminIndex, e, true);
                          }}
                        />
                        &nbsp;
                        <Label check className="ml-1">
                          Yes
                        </Label>
                      </FormGroup>
                    </Col>

                    <Col md={1}>
                      {adminRow.isNew && (
                        <FormGroup>
                          <Button
                            color="danger"
                            outline
                            style={{ marginTop: "33px", fontSize: "12px" }}
                            onClick={() => deleteAdminRow(adminIndex)}
                          >
                            Delete
                          </Button>
                        </FormGroup>
                      )}
                    </Col>
                  </Row>
                </>
              ))}

            <Row>
              <Col>
                <FormGroup>
                  <Button
                    color="danger"
                    outline
                    style={{ marginTop: "33px", fontSize: "12px" }}
                    onClick={addAdminRow}
                  >
                    Add Administrator/Coordinator
                  </Button>
                </FormGroup>
              </Col>
            </Row>

            <hr style={{ border: "1px solid #000000" }}></hr>

            <>
              <Row className="mt-4">
                <Col md={4}>
                  <FormGroup>
                    <Label>
                      <b>Main Office / Lab Address</b>
                    </Label>
                    <Input
                      name="labAddress"
                      placeholder="Enter Address"
                      value={labAddress}
                      type="text"
                      onChange={(e) => {
                        // handleAddressChange(addressIndex, e);
                        setLabAddress(e.target.value);
                        handleLabAddress(e);
                      }}
                      style={{
                        borderColor: fieldErrors.labAddress
                          ? "red"
                          : "lightgrey",
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label>
                      <b>Phone Number</b>
                    </Label>
                    <Input
                      name="labPhoneNumber"
                      placeholder="Enter Phone Number of Lab"
                      value={labPhoneNumber}
                      type="number"
                      onChange={(e) => {
                        // handleAddressChange(addressIndex, e);
                        setLabPhoneNumber(e.target.value);
                        handlelabPhoneNumber(e);
                      }}
                      style={{
                        borderColor: fieldErrors.labPhoneNumber
                          ? "red"
                          : "lightgrey",
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label>
                      <b>Pin Code</b>
                    </Label>
                    <Input
                      name="labPinCode"
                      placeholder="Enter PinCode"
                      value={pincode}
                      onChange={(e) => {
                        // handleAddressChange(addressIndex, e);
                        handlePinCode(e);
                      }}
                      type="number"
                      style={{
                        borderColor: fieldErrors.pincode ? "red" : "lightgrey",
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mt-5">
                <div>
                  <Label>
                    <b>Lab Working Timings</b>
                  </Label>
                  <Table
                    bordered
                    responsive
                    style={{ border: "1px solid #ddd" }}
                  >
                    <thead>
                      <tr
                        style={{
                          background:
                            "linear-gradient(90deg,royalblue, mediumvioletred)",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        <th>Day</th>
                        <th>From</th>
                        <th>To</th>
                      </tr>
                    </thead>
                    <tbody>
                      {daysOfWeek.map((day, index) => (
                        <tr
                          key={index}
                          style={{
                            backgroundColor:
                              index % 2 === 0 ? "#f9f9f9" : "#ffffff",
                            textAlign: "center",
                          }}
                        >
                          <td>
                            <b style={{ color: "#333" }}>{day}</b>
                          </td>
                          <td>
                            <Input
                              type="time"
                              value={timings[day]?.from || ""}
                              onChange={(e) =>
                                handleTimingChange(day, "from", e.target.value)
                              }
                              style={{
                                textAlign: "center",
                              }}
                            />
                          </td>
                          <td>
                            <Input
                              type="time"
                              value={timings[day]?.to || ""}
                              onChange={(e) =>
                                handleTimingChange(day, "to", e.target.value)
                              }
                              style={{
                                textAlign: "center",
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  {fieldErrors.timings && (
                    <p className="error">
                      Provide atleast any one working timings.
                    </p>
                  )}
                </div>
                <Row>
                  <Col>
                    <b
                      style={{
                        color: fieldErrors.sampleCollectionService
                          ? "red"
                          : "black",
                      }}
                    >
                      Provide sample collection service from here for different
                      pincode ?
                    </b>
                    &nbsp;&nbsp;&nbsp;
                    <Input
                      name="serviceProvideWithSameAddress"
                      type="radio"
                      checked={serviceProvideWithSameAddress === false}
                      onChange={(e) => {
                        setServiceProvideWithSameAddress(false);
                        setFieldErrors((prevErrors) => ({
                          ...prevErrors,
                          sampleCollectionService: false,
                        }));
                      }}
                    />
                    &nbsp;
                    <Label check className="ml-1">
                      No
                    </Label>{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Input
                      name="serviceProvideWithSameAddress"
                      type="radio"
                      checked={
                        // (numberOfLocation == 0 || numberOfLocation == 1) &&
                        serviceProvideWithSameAddress === true
                      }
                      onChange={(e) => {
                        setServiceProvideWithSameAddress(true);
                        setFieldErrors((prevErrors) => ({
                          ...prevErrors,
                          sampleCollectionService: false,
                        }));
                      }}
                    />
                    &nbsp;
                    <Label check className="ml-1">
                      Yes
                    </Label>
                  </Col>
                </Row>
                {serviceProvideWithSameAddress == true && (
                  <>
                    <Row>
                      <Col md={12} className="mt-3">
                        <FormGroup>
                          {/* <Label for={`LocPincode${addressIndex}`}> */}
                          <b>
                            List all PIN Codes where this location will provide
                            home collection service
                          </b>
                          {/* </Label> */}
                        </FormGroup>
                      </Col>
                    </Row>
                    {/* {Array.isArray(serviceAreaPinCode?.labServiceAreaPinCode?.serviceAreaPinCode) &&
                    serviceAreaPinCode?.labServiceAreaPinCode?.serviceAreaPinCode?.map((row, index) => (
                      <>
                        <Row key={index}> */}
                    {/* {console.log("row", row)}
                          <Col md="4"> */}
                    {/* <FormGroup> */}
                    {/* <Input
                                // id={`locPincode${index}`}
                                name={`locPincode${index}`}
                                placeholder="Enter Pincode"
                                value={row}
                                type="number"
                                onChange={(e) => {
                                  handlePincodeChange(index, e);
                                  setServiceAreaPinCode(e.target.value);
                                  // handleLocPinCode(e);
                                }}
                                style={{
                                  borderColor: fieldErrors ? "red" : "lightgrey",
                                }}
                              /> */}
                    {/* </FormGroup>
                          </Col>
                          <Col md={2}>
                            <FormGroup> */}
                    {/* <Button color="danger" outline style={{ fontSize: "15px", width: "75%" }} onClick={() => addPincode(addressIndex)}>
                              Add more pincode
                            </Button> */}
                    {/* </FormGroup>
                          </Col>
                        </Row>
                      </> */}
                    {/* // ))} */}
                    {pincodeRow &&
                      pincodeRow.map((row, index) => (
                        <>
                          <Row key={index}>
                            <Col md="4">
                              <FormGroup>
                                <Input
                                  id={`locPincode${index}`}
                                  name="locPincode"
                                  placeholder="Enter Pincode"
                                  value={row.pincode}
                                  type="number"
                                  onChange={(e) => {
                                    handlePincodeChange(index, e);
                                    setServiceAreaPinCode(e.target.value);
                                    // handleLocPinCode(e);
                                  }}
                                  style={{
                                    borderColor: fieldErrors.locPincode
                                      ? "red"
                                      : "lightgrey",
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col md={4}>
                              <FormGroup>
                                <Button
                                  color="danger"
                                  outline
                                  style={{ fontSize: "15px", width: "75%" }}
                                  onClick={addPincode}
                                >
                                  Add more pincode
                                </Button>
                              </FormGroup>
                            </Col>
                          </Row>
                        </>
                      ))}
                    <div>
                      {/* Display pin codes separated by comma */}
                      <b>Pin Codes where service is provided : </b>{" "}
                      {serviceAreaPinCode?.labServiceAreaPinCode?.serviceAreaPinCode.join(
                        ", "
                      )}
                    </div>
                    <Row>
                      <div style={{ marginTop: "20px" }}>
                        <Label>
                          <b>Sample Collection Timings</b>
                        </Label>
                        <Table
                          bordered
                          responsive
                          style={{ border: "1px solid #ddd" }}
                        >
                          <thead>
                            <tr
                              style={{
                                background:
                                  "linear-gradient(90deg,royalblue,mediumvioletred)",
                                color: "white",
                                textAlign: "center",
                              }}
                            >
                              <th>Day</th>
                              <th>From</th>
                              <th>To</th>
                            </tr>
                          </thead>
                          <tbody>
                            {daysOfWeek.map((day, index) => (
                              <tr
                                key={index}
                                style={{
                                  backgroundColor:
                                    index % 2 === 0 ? "#f9f9f9" : "#ffffff",
                                  textAlign: "center",
                                }}
                              >
                                <td>
                                  <b style={{ color: "#333" }}>{day}</b>
                                </td>
                                <td>
                                  <Input
                                    type="time"
                                    value={sampleTimings[day]?.from || ""}
                                    onChange={(e) =>
                                      handleSampleTimingChange(
                                        day,
                                        "from",
                                        e.target.value
                                      )
                                    }
                                    style={{
                                      borderColor: fieldErrors[day]?.from
                                        ? "red"
                                        : "#ddd",
                                      textAlign: "center",
                                    }}
                                  />
                                </td>
                                <td>
                                  <Input
                                    type="time"
                                    value={sampleTimings[day]?.to || ""}
                                    onChange={(e) =>
                                      handleSampleTimingChange(
                                        day,
                                        "to",
                                        e.target.value
                                      )
                                    }
                                    style={{
                                      borderColor: fieldErrors[day]?.to
                                        ? "red"
                                        : "#ddd",
                                      textAlign: "center",
                                    }}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        {fieldErrors.sampleTimings && (
                          <p className="error">
                            Provide atleast any one sample collection timings.
                          </p>
                        )}
                      </div>
                    </Row>
                  </>
                )}

                <Col>
                  <b>Sunday is Holiday?</b> &nbsp;&nbsp;&nbsp;&nbsp;
                  <Input
                    name="sundayHoliday"
                    type="radio"
                    checked={sundayIsHoliday === false}
                    onChange={(e) => {
                      setSundayIsHoliday(false);
                    }}
                  />
                  &nbsp;
                  <Label check className="ml-1">
                    No
                  </Label>{" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <Input
                    name="sundayHoliday"
                    type="radio"
                    checked={sundayIsHoliday === true}
                    onChange={(e) => {
                      setSundayIsHoliday(true);
                    }}
                  />
                  &nbsp;
                  <Label check className="ml-1">
                    Yes
                  </Label>
                </Col>
              </Row>
            </>
            {/* })} */}
            <hr style={{ border: "1px solid #000000" }}></hr>
            <Stack
              spacing={2}
              p={2}
              className="mt-4"
              bgcolor="#f9f9f9"
              borderRadius={2}
              boxShadow={3}
            >
              <Label>
                <b>What Licenses you carry for your Establishment:</b>
              </Label>
              {/* Add Row Button */}

              {/* License Rows */}
              {licenserows.map((row, index) => (
                <Grid
                  container
                  spacing={2}
                  key={index}
                  alignItems="center"
                  sx={{
                    padding: 2,
                    border: "1px solid #ddd",
                    borderRadius: 1,
                    backgroundColor: "#fff",
                    width: "100%",
                  }}
                >
                  <Grid item style={{ paddingTop: 0 }} xs={12}>
                    <b>License {index + 1}</b>
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      label="Branch Name"
                      variant="outlined"
                      fullWidth
                      value={row.branchName}
                      onChange={(e) => {
                        const updatedRows = [...licenserows];
                        updatedRows[index].branchName = e.target.value;
                        setLicenseRows(updatedRows);
                        if (e.target.value) {
                          setFieldErrors((prevErrors) => {
                            const updatedErrors = { ...prevErrors };
                            delete updatedErrors[`branchName${index}`];
                            return updatedErrors;
                          });
                        }
                      }}
                      error={Boolean(fieldErrors[`branchName${index}`])}
                      helperText={fieldErrors[`branchName${index}`] || ""}
                    />
                  </Grid>
                  {/* State Input */}
                  <Grid item xs={2}>
                    <TextField
                      label="City"
                      variant="outlined"
                      fullWidth
                      value={row.city}
                      onChange={(e) => {
                        const updatedRows = [...licenserows];
                        updatedRows[index].city = e.target.value;
                        setLicenseRows(updatedRows);
                        if (e.target.value) {
                          setFieldErrors((prevErrors) => {
                            const updatedErrors = { ...prevErrors };
                            delete updatedErrors[`city${index}`];
                            return updatedErrors;
                          });
                        }
                      }}
                      error={Boolean(fieldErrors[`city${index}`])}
                      helperText={fieldErrors[`city${index}`] || ""}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <Autocomplete
                      options={stateList}
                      value={row.state || ""} // Set the current state value
                      onChange={(event, newValue) => {
                        const updatedRows = [...licenserows];
                        updatedRows[index].state = newValue || ""; // Update the selected state
                        setLicenseRows(updatedRows);
                        // const isAnyRowValid = updatedRows.some(
                        //   (row) => row.state && row.expiryDate && row.fileName
                        // );

                        // // Clear the "noRows" error if a valid row is found
                        // const updatedErrors = { ...fieldErrors };
                        // if (isAnyRowValid) {
                        //   delete updatedErrors.noRows;
                        // }
                        // setFieldErrors(updatedErrors);

                        // Remove error if valid
                        if (newValue) {
                          setFieldErrors((prevErrors) => {
                            const updatedErrors = { ...prevErrors };
                            delete updatedErrors[`state${index}`];
                            return updatedErrors;
                          });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="State"
                          variant="outlined"
                          fullWidth
                          error={!!fieldErrors[`state${index}`]}
                          helperText={fieldErrors[`state${index}`]}
                        />
                      )}
                    />
                  </Grid>

                  {/* Expiry Date Input */}
                  <Grid item xs={2}>
                    <TextField
                      label="Expiry Date"
                      type="date"
                      variant="outlined"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      value={row.expiryDate}
                      onChange={(e) => {
                        const updatedRows = [...licenserows];
                        updatedRows[index].expiryDate = e.target.value;
                        setLicenseRows(updatedRows);
                        // const isAnyRowValid = updatedRows.some(
                        //   (row) => row.state && row.expiryDate && row.fileName
                        // );

                        // // Clear the "noRows" error if a valid row is found
                        // const updatedErrors = { ...fieldErrors };
                        // if (isAnyRowValid) {
                        //   delete updatedErrors.noRows;
                        // }
                        // setFieldErrors(updatedErrors);
                        // Remove error if valid
                        if (e.target.value) {
                          setFieldErrors((prevErrors) => {
                            const updatedErrors = { ...prevErrors };
                            delete updatedErrors[`expiryDate${index}`];
                            return updatedErrors;
                          });
                        }
                      }}
                      error={!!fieldErrors[`expiryDate${index}`]}
                      helperText={fieldErrors[`expiryDate${index}`]}
                    />
                  </Grid>

                  {/* File Upload Button */}
                  <Grid item xs={2}>
                    <Button
                      color="primary"
                      type="button"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                      onClick={() =>
                        document.getElementById(`fileInput-${index}`).click()
                      }
                      disabled={loadingIndex === index}
                    >
                      {loadingIndex === index ? (
                        <>
                          <CircularProgress
                            size={20}
                            style={{ marginRight: "8px" }}
                            color="inherit"
                          />
                          Uploading...
                        </>
                      ) : (
                        <>
                          <AttachFileIcon style={{ marginRight: "8px" }} />
                          Attach File
                        </>
                      )}
                      <input
                        id={`fileInput-${index}`}
                        type="file"
                        hidden
                        onChange={(e) => handleFileUpload(e, index)}
                      />
                    </Button>
                    {row.fileName && (
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        mt={1}
                        style={{ wordBreak: "break-all" }}
                      >
                        {row.fileName}
                      </Typography>
                    )}
                    {fieldErrors[`fileName${index}`] && (
                      <Typography variant="body2" color="error">
                        {fieldErrors[`fileName${index}`]}
                      </Typography>
                    )}
                  </Grid>

                  {/* Delete Row Button */}
                  <Grid item xs={1}>
                    <IconButton
                      color="error"
                      onClick={() => handleDeleteRow(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  outline
                  onClick={handleAddRow}
                  color="danger"
                  style={{ width: "fit-content" }}
                >
                  Add License
                </Button>
                {licenses.length > 0 && (
                  <div>
                    <Button
                      color="success"
                      onClick={() => setislicenseModalOpen(true)}
                    >
                      View Licenses
                    </Button>
                    <LabDocumentModal
                      open={licenseModalOpen}
                      onClose={() => setislicenseModalOpen(false)}
                      data={licenses}
                    />
                  </div>
                )}
              </div>

              {/* Validation Message for No Rows */}
              {fieldErrors.noRows && (
                <Typography color="error" sx={{ mb: 2 }}>
                  {fieldErrors.noRows}
                </Typography>
              )}
            </Stack>

            <hr style={{ border: "1px solid #000000" }}></hr>
            <Row style={{ marginTop: "30px" }} className="mt-4">
              <Col md={4}>
                <FormGroup>
                  <Label
                    for="examplePassword"
                    style={{
                      color: fieldErrors.nablAccreditation ? "red" : "inherit",
                    }}
                  >
                    <b>Do you have NABL Accreditation?</b>
                  </Label>
                </FormGroup>
              </Col>
              <Col md={8}>
                <div className="d-flex align-items-center">
                  <FormGroup check className="form-inline mr-3">
                    <Input
                      name="nablAccreditation"
                      type="radio"
                      checked={nablAccreditation === true}
                      onChange={() => {
                        setNablAccreditation(true);
                        handleNablAccreditationChange(true);
                      }}
                    />
                    <Label check className="mr-3 ml-1">
                      Yes
                    </Label>
                  </FormGroup>
                  <FormGroup
                    check
                    className="form-inline"
                    style={{ marginLeft: "5%" }}
                  >
                    <Input
                      name="nablAccreditation"
                      type="radio"
                      checked={nablAccreditation === false}
                      onChange={() => {
                        setNablAccreditation(false);
                        handleNablAccreditationChange(false);
                      }}
                    />
                    <Label check className="ml-1">
                      No
                    </Label>
                  </FormGroup>
                </div>
              </Col>
            </Row>
            {nablAccreditation && (
              <>
                <Stack
                  spacing={2}
                  p={2}
                  bgcolor="#f9f9f9"
                  borderRadius={2}
                  boxShadow={3}
                >
                  {nablrows.map((row, index) => (
                    <Grid
                      container
                      spacing={2}
                      key={index}
                      alignItems="center"
                      sx={{
                        padding: 2,
                        border: "1px solid #ddd",
                        borderRadius: 1,
                        backgroundColor: "#fff",
                        width: "100%",
                      }}
                    >
                      <Grid item xs={2}>
                        <TextField
                          label="Branch Name"
                          variant="outlined"
                          fullWidth
                          value={row.branchName}
                          onChange={(e) => {
                            const updatedRows = [...nablrows];
                            updatedRows[index].branchName = e.target.value;
                            setNablRows(updatedRows);
                            if (e.target.value) {
                              setFieldErrors((prevErrors) => {
                                const updatedErrors = { ...prevErrors };
                                delete updatedErrors[`nablbranchName${index}`];
                                return updatedErrors;
                              });
                            }
                          }}
                          error={Boolean(fieldErrors[`nablbranchName${index}`])}
                          helperText={
                            fieldErrors[`nablbranchName${index}`] || ""
                          }
                        />
                      </Grid>
                      {/* State Input */}
                      <Grid item xs={2}>
                        <TextField
                          label="City"
                          variant="outlined"
                          fullWidth
                          value={row.city}
                          onChange={(e) => {
                            const updatedRows = [...nablrows];
                            updatedRows[index].city = e.target.value;
                            setNablRows(updatedRows);
                            if (e.target.value) {
                              setFieldErrors((prevErrors) => {
                                const updatedErrors = { ...prevErrors };
                                delete updatedErrors[`nablcity${index}`];
                                return updatedErrors;
                              });
                            }
                          }}
                          error={Boolean(fieldErrors[`nablcity${index}`])}
                          helperText={fieldErrors[`nablcity${index}`] || ""}
                        />
                      </Grid>

                      <Grid item xs={3}>
                        <Autocomplete
                          options={stateList}
                          value={row.state || ""} // Set the current state value
                          onChange={(event, newValue) => {
                            const updatedRows = [...nablrows];
                            updatedRows[index].state = newValue || ""; // Update the selected state
                            setNablRows(updatedRows);
                            // const isAnyRowValid = updatedRows.some(
                            //   (row) => row.state && row.expiryDate && row.fileName
                            // );

                            // // Clear the "noRows" error if a valid row is found
                            // const updatedErrors = { ...fieldErrors };
                            // if (isAnyRowValid) {
                            //   delete updatedErrors.noRows;
                            // }
                            // setFieldErrors(updatedErrors);
                            // Remove error if valid

                            // Remove error if valid
                            if (newValue) {
                              setFieldErrors((prevErrors) => {
                                const updatedErrors = { ...prevErrors };
                                delete updatedErrors[`nablstate${index}`];
                                return updatedErrors;
                              });
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="State"
                              variant="outlined"
                              fullWidth
                              error={!!fieldErrors[`nablstate${index}`]}
                              helperText={fieldErrors[`nablstate${index}`]}
                            />
                          )}
                        />
                      </Grid>

                      {/* Expiry Date Input */}
                      <Grid item xs={2}>
                        <TextField
                          label="Expiry Date"
                          type="date"
                          variant="outlined"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          value={row.expiryDate}
                          onChange={(e) => {
                            const updatedRows = [...nablrows];
                            updatedRows[index].expiryDate = e.target.value;
                            setNablRows(updatedRows);
                            // const isAnyRowValid = updatedRows.some(
                            //   (row) => row.state && row.expiryDate && row.fileName
                            // );

                            // // Clear the "noRows" error if a valid row is found
                            // const updatedErrors = { ...fieldErrors };
                            // if (isAnyRowValid) {
                            //   delete updatedErrors.noRows;
                            // }
                            // setFieldErrors(updatedErrors);
                            // Remove error if valid
                            if (e.target.value) {
                              setFieldErrors((prevErrors) => {
                                const updatedErrors = { ...prevErrors };
                                delete updatedErrors[`nablexpiryDate${index}`];
                                return updatedErrors;
                              });
                            }
                          }}
                          error={!!fieldErrors[`nablexpiryDate${index}`]}
                          helperText={fieldErrors[`nablexpiryDate${index}`]}
                        />
                      </Grid>

                      {/* File Upload Button */}
                      <Grid item xs={2}>
                        <Button
                          color="primary"
                          type="button"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                          }}
                          onClick={() =>
                            document
                              .getElementById(`nablfileInput-${index}`)
                              .click()
                          }
                          disabled={nablloadingIndex === index}
                        >
                          {nablloadingIndex === index ? (
                            <>
                              <CircularProgress
                                size={20}
                                style={{ marginRight: "8px" }}
                                color="inherit"
                              />
                              Uploading...
                            </>
                          ) : (
                            <>
                              <AttachFileIcon style={{ marginRight: "8px" }} />
                              Attach File
                            </>
                          )}
                          <input
                            id={`nablfileInput-${index}`}
                            type="file"
                            hidden
                            onChange={(e) => handleNablFileUpload(e, index)}
                          />
                        </Button>
                        {row.fileName && (
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            mt={1}
                            style={{ wordBreak: "break-all" }}
                          >
                            {row.fileName}
                          </Typography>
                        )}
                        {fieldErrors[`nablfileName${index}`] && (
                          <Typography variant="body2" color="error">
                            {fieldErrors[`nablfileName${index}`]}
                          </Typography>
                        )}
                      </Grid>

                      {/* Delete Row Button */}
                      <Grid item xs={1}>
                        <IconButton
                          color="error"
                          onClick={() => handleNablDeleteRow(index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      outline
                      onClick={handleNablAddRow}
                      color="danger"
                      style={{ width: "fit-content" }}
                    >
                      Add NABL Certificate
                    </Button>
                    {nablCertificates.length > 0 && (
                      <div>
                        <Button
                          color="success"
                          onClick={() => setisNablModalOpen(true)}
                        >
                          View NABL Certificates
                        </Button>
                        <LabDocumentModal
                          open={nablModalOpen}
                          onClose={() => setisNablModalOpen(false)}
                          data={nablCertificates}
                        />
                      </div>
                    )}
                  </div>

                  {/* Validation Message for No Rows */}
                  {fieldErrors.noNablRows && (
                    <Typography color="error" sx={{ mb: 2 }}>
                      {fieldErrors.noNablRows}
                    </Typography>
                  )}
                </Stack>
              </>
            )}
            <hr style={{ border: "1px solid #000000" }}></hr>
            <Row className="mt-4">
              <Col md={4}>
                <FormGroup>
                  <Label
                    for="examplePassword"
                    style={{
                      color: fieldErrors.isoCertificate ? "red" : "inherit",
                    }}
                  >
                    <b>Do you have ISO Certification?</b>
                  </Label>
                </FormGroup>
              </Col>
              <Col md={8}>
                <div className="d-flex align-items-center">
                  <FormGroup check className="form-inline mr-3">
                    <Input
                      name="isoCertificate"
                      type="radio"
                      checked={isoCertificate === true}
                      onChange={() => {
                        setIsoCertificate(true);
                        handleIsoCertification(true);
                      }}
                    />
                    <Label check className="mr-3 ml-1">
                      Yes
                    </Label>
                  </FormGroup>
                  <FormGroup
                    check
                    className="form-inline"
                    style={{ marginLeft: "5%" }}
                  >
                    <Input
                      name="isoCertificate"
                      type="radio"
                      checked={isoCertificate === false}
                      onChange={() => {
                        setIsoCertificate(false);
                        handleIsoCertification(false);
                      }}
                    />
                    <Label check className="ml-1">
                      No
                    </Label>
                  </FormGroup>
                </div>
              </Col>
            </Row>
            {isoCertificate && (
              <Stack
                spacing={2}
                p={2}
                bgcolor="#f9f9f9"
                borderRadius={2}
                boxShadow={3}
              >
                {isorows.map((row, index) => (
                  <Grid
                    container
                    spacing={2}
                    key={index}
                    alignItems="center"
                    sx={{
                      padding: 2,
                      border: "1px solid #ddd",
                      borderRadius: 1,
                      backgroundColor: "#fff",
                      width: "100%",
                    }}
                  >
                    <Grid item xs={2}>
                      <TextField
                        label="ISO Name"
                        variant="outlined"
                        fullWidth
                        value={row.isoName}
                        onChange={(e) => {
                          const updatedRows = [...isorows];
                          updatedRows[index].isoName = e.target.value;
                          setIsoRows(updatedRows);
                          if (e.target.value) {
                            setFieldErrors((prevErrors) => {
                              const updatedErrors = { ...prevErrors };
                              delete updatedErrors[`isoName${index}`];
                              return updatedErrors;
                            });
                          }
                        }}
                        error={Boolean(fieldErrors[`isoName${index}`])}
                        helperText={fieldErrors[`isoName${index}`] || ""}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        label="Branch Name"
                        variant="outlined"
                        fullWidth
                        value={row.branchName}
                        onChange={(e) => {
                          const updatedRows = [...isorows];
                          updatedRows[index].branchName = e.target.value;
                          setIsoRows(updatedRows);
                          if (e.target.value) {
                            setFieldErrors((prevErrors) => {
                              const updatedErrors = { ...prevErrors };
                              delete updatedErrors[`isobranchName${index}`];
                              return updatedErrors;
                            });
                          }
                        }}
                        error={Boolean(fieldErrors[`isobranchName${index}`])}
                        helperText={fieldErrors[`isobranchName${index}`] || ""}
                      />
                    </Grid>
                    {/* State Input */}
                    <Grid item xs={2}>
                      <TextField
                        label="City"
                        variant="outlined"
                        fullWidth
                        value={row.city}
                        onChange={(e) => {
                          const updatedRows = [...isorows];
                          updatedRows[index].city = e.target.value;
                          setIsoRows(updatedRows);
                          if (e.target.value) {
                            setFieldErrors((prevErrors) => {
                              const updatedErrors = { ...prevErrors };
                              delete updatedErrors[`isocity${index}`];
                              return updatedErrors;
                            });
                          }
                        }}
                        error={Boolean(fieldErrors[`isocity${index}`])}
                        helperText={fieldErrors[`isocity${index}`] || ""}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <Autocomplete
                        options={stateList}
                        value={row.state || ""} // Set the current state value
                        onChange={(event, newValue) => {
                          const updatedRows = [...isorows];
                          updatedRows[index].state = newValue || ""; // Update the selected state
                          setIsoRows(updatedRows);

                          // Remove error if valid
                          if (newValue) {
                            setFieldErrors((prevErrors) => {
                              const updatedErrors = { ...prevErrors };
                              delete updatedErrors[`isostate${index}`];
                              return updatedErrors;
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="State"
                            variant="outlined"
                            fullWidth
                            error={!!fieldErrors[`isostate${index}`]}
                            helperText={fieldErrors[`isostate${index}`]}
                          />
                        )}
                      />
                    </Grid>

                    {/* Expiry Date Input */}
                    <Grid item xs={2}>
                      <TextField
                        label="Expiry Date"
                        type="date"
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        value={row.expiryDate}
                        onChange={(e) => {
                          const updatedRows = [...isorows];
                          updatedRows[index].expiryDate = e.target.value;
                          setIsoRows(updatedRows);
                          // const isAnyRowValid = updatedRows.some(
                          //   (row) => row.state && row.expiryDate && row.fileName
                          // );

                          // // Clear the "noRows" error if a valid row is found
                          // const updatedErrors = { ...fieldErrors };
                          // if (isAnyRowValid) {
                          //   delete updatedErrors.noRows;
                          // }
                          // setFieldErrors(updatedErrors);
                          // Remove error if valid
                          if (e.target.value) {
                            setFieldErrors((prevErrors) => {
                              const updatedErrors = { ...prevErrors };
                              delete updatedErrors[`isoexpiryDate${index}`];
                              return updatedErrors;
                            });
                          }
                        }}
                        error={!!fieldErrors[`isoexpiryDate${index}`]}
                        helperText={fieldErrors[`isoexpiryDate${index}`]}
                      />
                    </Grid>

                    {/* File Upload Button */}
                    <Grid item xs={2}>
                      <Button
                        color="primary"
                        type="button"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                        }}
                        onClick={() =>
                          document
                            .getElementById(`isofileInput-${index}`)
                            .click()
                        }
                        disabled={isoloadingIndex === index}
                      >
                        {isoloadingIndex === index ? (
                          <>
                            <CircularProgress
                              size={20}
                              style={{ marginRight: "8px" }}
                              color="inherit"
                            />
                            Uploading...
                          </>
                        ) : (
                          <>
                            <AttachFileIcon style={{ marginRight: "8px" }} />
                            Attach File
                          </>
                        )}
                        <input
                          id={`isofileInput-${index}`}
                          type="file"
                          hidden
                          onChange={(e) => handleIsoFileUpload(e, index)}
                        />
                      </Button>
                      {row.fileName && (
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          mt={1}
                          style={{ wordBreak: "break-all" }}
                        >
                          {row.fileName}
                        </Typography>
                      )}
                      {fieldErrors[`isofileName${index}`] && (
                        <Typography variant="body2" color="error">
                          {fieldErrors[`isofileName${index}`]}
                        </Typography>
                      )}
                    </Grid>

                    {/* Delete Row Button */}
                    <Grid item xs={1}>
                      <IconButton
                        color="error"
                        onClick={() => handleIsoDeleteRow(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    outline
                    onClick={handleIsoAddRow}
                    color="danger"
                    style={{ width: "fit-content" }}
                  >
                    Add ISO Certificate
                  </Button>
                  {isoCertificates.length > 0 && (
                    <div>
                      <Button
                        color="success"
                        onClick={() => setisIsoModalOpen(true)}
                      >
                        View ISO Certificates
                      </Button>
                      <LabDocumentModal
                        open={isoModalOpen}
                        onClose={() => setisIsoModalOpen(false)}
                        data={isoCertificates}
                        type="Iso"
                      />
                    </div>
                  )}
                </div>

                {/* Validation Message for No Rows */}
                {fieldErrors.noIsoRows && (
                  <Typography color="error" sx={{ mb: 2 }}>
                    {fieldErrors.noIsoRows}
                  </Typography>
                )}
              </Stack>
            )}
            <hr style={{ border: "1px solid #000000" }}></hr>
            <Row className="mt-4">
              <Col>
                <div
                  style={{
                    padding: "20px",
                    backgroundColor: "#f9f9f9",
                    borderRadius: "8px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <p
                    style={{
                      padding: "1%",
                      fontSize: "16px",
                      color: fieldErrors.reports ? "red" : "#333",
                    }}
                  >
                    Please submit <b>7 - 8 sample copies</b> of your past{" "}
                    <b>Test Reports</b> of your patients. We need these reports
                    to train our AI Model with your reports. You may <b>hide</b>{" "}
                    the patient information (Attach one at a time).
                  </p>

                  <div
                    style={{
                      margin: "15px 0",
                      border: "1px dashed #ccc",
                      borderRadius: "6px",
                      padding: "10px",
                      textAlign: "center",
                    }}
                  >
                    {isLoading ? (
                      // Show Circular Progress during upload
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "50px",
                        }}
                      >
                        <CircularProgress size={30} />
                      </div>
                    ) : (
                      <input
                        type="file"
                        accept=".jpg, .jpeg, .pdf, .png"
                        id="fileUpload"
                        className="file-upload-input"
                        name="file"
                        onChange={(event) => {
                          handleMultipleChange(event);
                        }}
                        multiple
                        style={{ padding: "10px" }}
                      />
                    )}
                  </div>

                  {/* Uploaded Reports Section */}

                  {selectedSampleTestReport.length > 0 && (
                    <Box
                      sx={{
                        mt: 2,
                      }}
                    >
                      <Typography variant="h6" gutterBottom>
                        Selected Files
                      </Typography>

                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "10px",
                        }}
                      >
                        {selectedSampleTestReport.map((file, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              padding: "10px",
                              border: "1px solid #ddd",
                              borderRadius: "5px",
                              backgroundColor: "#f9f9f9",
                              // maxWidth: "300px",
                              width: "100%",
                            }}
                          >
                            {/* File Name */}
                            <Typography variant="body2">{file.name}</Typography>
                          </div>
                        ))}
                      </div>
                    </Box>
                  )}

                  {labPartnerSampleTest.length === 0 &&
                    selectedSampleTestReport.length === 0 && (
                      <div style={{ marginTop: "20px" }}>
                        <b>Reports:</b> No Reports Available{" "}
                      </div>
                    )}
                  {labPartnerSampleTest.length > 0 && (
                    <div>
                      <Button
                        color="success"
                        onClick={() => setisSampleReportModalOpen(true)}
                      >
                        View Reports
                      </Button>
                      <SampleTestReportModal
                        open={SampleReportModalOpen}
                        onClose={() => setisSampleReportModalOpen(false)}
                        data={labPartnerSampleTest}
                      />
                    </div>
                  )}

                  {/* Note Section */}
                  <div
                    style={{
                      marginTop: "20px",
                      backgroundColor: "#e3f2fd",
                      padding: "15px",
                      borderRadius: "6px",
                      border: "1px solid #90caf9",
                    }}
                  >
                    <p style={{ margin: "0", fontSize: "14px", color: "#333" }}>
                      <b>Note:</b> If you have any query regarding this, please
                      reach out to our Marketing Manager at:{" "}
                      <span style={{ color: "blue", fontWeight: "bold" }}>
                        +91 9082950730
                      </span>
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
          <center>
            <Button
              onClick={handleSubmit}
              style={{
                marginTop: "4%",
                width: "15%",
                backgroundColor: "rgb(83, 83, 233)",
              }}
            >
              {submitting ? (
                <CircularProgress color="inherit" size={20}></CircularProgress>
              ) : (
                "Save"
              )}
            </Button>
          </center>
        </>
      )}
    </>
  );
};
export default GeneralInfo;
