import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { getAccessToken } from "../../configration/HandleCookies";
import Spinner from "../../shared/Spinner";
import RootLayOut from "./RootLayOut";
import axios from "axios";
const PrivateRoutes = () => {
  // let auth = { token: getAccessToken() };
  const urlParams = new URLSearchParams(window.location.search);
  const serializedObject = urlParams.get("token");
  // const familyLogin = urlParams.get("familyLogin");

  console.log("serializedObject", serializedObject);
  if (serializedObject) {
    const decodedObject = decodeURIComponent(serializedObject);
    const myObject = JSON.parse(decodedObject);
    console.log("myobject", myObject);
    sessionStorage.setItem("token", myObject.token);
    sessionStorage.setItem("userDetails", JSON.stringify(myObject));
  }
  let token = window.sessionStorage.getItem("token");
  let userDetail = window.sessionStorage.getItem("userDetails");
  userDetail = JSON.parse(userDetail);
  const userType = userDetail?.userType;
  const [isSubscribed, setIsSubscribed] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const getSubscriptions = async () => {
    // return await axios.get(`subscription/get-detail`)
    //   .then((response) => {
    //     console.log("response" , response.data);
    //     setIsSubscribed(response?.subscriptionStatus != "NO_SUBSCRIPTION");
    //     setIsLoading(false);
    //   })
    //   .catch((error) => {
    //     console.log("error", error);
    //   });
    if (!token) {
      navigate("/homeBeforeLogin");
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}subscription/get-detail`,
        {
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token"),
          },
        }
      );

      if (response.status === 200) {
        setIsSubscribed(
          response?.data?.subscriptionStatus !== "NO_SUBSCRIPTION"
        );
        setIsLoading(false);
      } else {
        console.log("Unexpected response status:", response.status);
        setIsLoading(false);
        // Handle other status codes if necessary
      }
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
      // Handle errors if needed
    }
  };

  useEffect(() => {
    getSubscriptions();
  }, []);

  // return <> {isLoading ? <Spinner /> : auth.token ? isSubscribed ? <RootLayOut /> : <Navigate to="/buy-subscription" /> : <Navigate to="/" />}</>;
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : userType == "TESTLAB" || userType == "PSYCHIATRIST" ? (
        <RootLayOut />
      ) : isSubscribed ? (
        <RootLayOut />
      ) : (
        <Navigate to="/SubscriptionPageBeforeLogin" />
      )}
    </>
  );
};

export default PrivateRoutes;
