import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  Grid,
  Typography,
  Checkbox,
  Box,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { toast } from "react-toastify";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";
import timezone from "dayjs/plugin/timezone";
import Spinner from "../../shared/Spinner";

const TrainerCalendar = () => {
  const location = useLocation();
  const rowData = location.state?.rowData;

  const currentMonth = dayjs().month() + 1;
  const currentYear = dayjs().year();
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [selectedSlots, setSelectedSlots] = useState({});
  const [schedule, setSchedule] = useState([]);
  const [apiSlots, setApiSlots] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isApiLoaded, setIsApiLoaded] = useState(false);

  useEffect(() => {
    if (rowData) {
      setLoading(true);
      AxiosInstance.get(
        `trainer/get-trainer-slot/${rowData.className}/${rowData.mode}/${rowData.session}/${rowData.timingFrom}/${rowData.timingTo}`
      )
        .then((response) => {
          if (response) {
            setApiSlots(response);
          }
        })
        .catch((error) => {
          console.log("Error:", error);
        })
        .finally(() => {
          setLoading(false);
          setIsApiLoaded(true); // Mark API as completed (successful or failed)
        });
    }
  }, []);

  dayjs.extend(customParseFormat);
  dayjs.extend(utc);
  // dayjs.extend(timezone);

  const getUpcomingDates = (weekdays, month, year) => {
    let firstDay = dayjs(`${year}-${month}-01`);
    let lastDay = firstDay.endOf("month");
    let upcomingDates = [];

    for (
      let date = firstDay;
      date.isBefore(lastDay) || date.isSame(lastDay, "day");
      date = date.add(1, "day")
    ) {
      let dayName = date.format("dddd").toLowerCase();
      let matchedDay = weekdays.find((d) => d.code === dayName);
      if (matchedDay) {
        upcomingDates.push({
          name: matchedDay.name,
          date: date.format("DD/MM/YYYY"),
        });
      }
    }
    return upcomingDates;
  };

  useEffect(() => {
    if (rowData && isApiLoaded) {
      setSchedule([]);
      setTimeout(() => {
        const upcomingDates = getUpcomingDates(
          rowData.weekdays,
          selectedMonth,
          currentYear
        );
        const newSchedule = upcomingDates.map((item, index) => {
          const matchedSlot = apiSlots.find(
            (slot) =>
              slot.slotDate ===
              dayjs(item.date, "DD/MM/YYYY").format("YYYY-MM-DD")
          );
          return {
            id: matchedSlot ? matchedSlot.slotId : null,
            className: rowData.className,
            time: `${rowData.timingFrom} - ${rowData.timingTo}`,
            day: item.name,
            date: item.date,
            mode: rowData.mode,
            session: rowData.session,
            availableSlots: matchedSlot ? matchedSlot.maxCapacity : "",
          };
        });
        // .filter((schedule) => schedule.availableSlots !== "");
        console.log("newSchedule", newSchedule);
        const uniqueSchedule = [
          ...new Map(newSchedule.map((item) => [item.date, item])).values(),
        ];
        console.log("Unique Schedule:", uniqueSchedule.length);

        setSchedule(newSchedule);
      }, 0);
    }
  }, [selectedMonth, isApiLoaded]);

  const handleSlotChange = (date, value) => {
    setSchedule((prev) =>
      prev.map((slot) =>
        slot.date === date ? { ...slot, availableSlots: value } : slot
      )
    );
  };

  const handleUpdateSlots = () => {
    console.log("Updated Slots:", schedule);
    const formattedSlots = schedule
      .filter((slot) => slot.availableSlots !== "" && slot.id === null) // Exclude empty fields
      .map((slot) => ({
        slotId: slot.id ? slot.id : null, // Default slotId to 0 if not present
        slotDate: dayjs(slot.date, "DD/MM/YYYY")
          .add(5, "hour") // Add 5 hours
          .add(30, "minute") // Add 30 minutes to match IST
          .toISOString(), // Convert date to ISO format
        startTime: slot.time.split(" - ")[0], // Extract start time
        endTime: slot.time.split(" - ")[1], // Extract end time
        className: slot.className,
        maxCapacity: parseInt(slot.availableSlots, 10), // Convert to number
        session: slot.session,
        mode: slot.mode,
      }));

    if (formattedSlots.length === 0) {
      console.warn("No slots to update. Skipping API call.");
      return;
    }

    console.log("Sending Slots Data:", formattedSlots);

    AxiosInstance.post("trainer/create-slot", formattedSlots)
      .then((response) => {
        toast.success("Slots Uploaded Successfully");
      })
      .catch((error) => {
        console.error("Error updating slots:", error);
      });
  };

  console.log("schedule", schedule, rowData);

  return (
    <div style={{ padding: 20 }}>
      {rowData ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h4">Trainer Availability Calendar</Typography>
            <FormControl sx={{ minWidth: 200 }}>
              <InputLabel> Select Month </InputLabel>
              <Select
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(e.target.value)}
                sx={{
                  backgroundColor: "white",
                  borderRadius: "8px",
                  boxShadow: 2,
                }}
              >
                {[...Array(12)].map((_, i) => (
                  <MenuItem key={i + 1} value={i + 1}>
                    {dayjs().month(i).format("MMMM")}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          {loading ? (
            <Spinner />
          ) : (
            <>
              <Grid container spacing={2} sx={{ marginTop: 2 }}>
                {schedule.map((slot) => (
                  <Grid item xs={12} sm={6} md={4} key={slot.id}>
                    <Card
                      sx={{
                        backgroundColor: "#F8F9FA",
                        border: "1px solid #CED4DA",
                        boxShadow: 3,
                      }}
                    >
                      <CardContent>
                        <Typography variant="h6">{slot.className}</Typography>
                        <Typography variant="body1">{slot.time}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          Mode: {slot.mode}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          Day: {slot.day}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          Date: {slot.date}
                        </Typography>
                        {/* <Typography variant="body2" color="textSecondary">
                  Max Capacity: 15
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Available Slots: 14
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Booked Slots: 1
                </Typography> */}
                        <TextField
                          fullWidth
                          label="No. of Slots"
                          variant="outlined"
                          size="small"
                          margin="normal"
                          value={slot.availableSlots}
                          onChange={(e) =>
                            handleSlotChange(slot.date, e.target.value)
                          }
                        />
                        {/* <TextField
                  fullWidth
                  label="No. of Slots Available"
                  variant="outlined"
                  size="small"
                  margin="normal"
                  value={slot.availableSlots}
                  onChange={(e) => handleSlotChange(slot.date, e.target.value)}
                /> */}
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>

              <Button
                variant="contained"
                color="primary"
                sx={{ mt: 3 }}
                onClick={handleUpdateSlots}
              >
                Update Slots
              </Button>
            </>
          )}
        </>
      ) : (
        <Box>
          <Typography variant="h6" align="center">
            Please come from General Info to book schedule
          </Typography>
        </Box>
      )}
    </div>
  );
};

export default TrainerCalendar;
