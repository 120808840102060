import {
  Box,
  Button,
  Card,
  Chip,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Modal,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { getColorByLabel } from "../../util/Helper";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import ThingsYouCanChange from "../LifeStyleRiskAssessment/components/ThingsYouCanChange.jpg";
import ThingsYouCantChange from "../LifeStyleRiskAssessment/components/ThingsYouCantChange.jpg";
import SocialFactors from "../LifeStyleRiskAssessment/components/SocialFactors.jpg";
import Spinner from "../../shared/Spinner";
import { toast } from "react-toastify";

const DietitianDiagnosis = ({ order }) => {
  const [diagnosis, setDiagnosis] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [fieldsToReview, setFieldsToReview] = useState([]);
  const [reviewData, setReviewData] = useState(null);

  const handleClose = () => setOpen(false);
  const allFields = [
    "diagnosis",
    "nutrition",
    "Physical Exercise",
    "Environment",
    "LifeStyle",
  ];

  const capitalize = (str) => {
    return (
      str.charAt(0).toUpperCase() + str.slice(1).replace(/([A-Z])/g, " $1")
    );
  };

  useEffect(() => {
    setLoading(true);
    AxiosInstance.get(`consultation/getPatientNutrition/${order.patientId}`)
      .then((response) => {
        if (response) {
          setDiagnosis(response.lifeStyleModificationResponse);
        }
        setLoading(false);
        AxiosInstance.get(
          `consultation/get-review-and-understood/${order.orderId}`
        )
          .then((response) => {
            setReviewData(response);
            let falseFields = [];
            if (!response || response.diagnosis === null) {
              // If response is null, show all fields
              falseFields = allFields;
            } else {
              // Check for fields that are false
              falseFields = Object.entries(response).reduce(
                (acc, [key, value]) => {
                  if (!value) acc.push(key);
                  return acc;
                },
                []
              );
            }

            if (falseFields.length > 0) {
              setFieldsToReview(falseFields);
              setOpen(true); // Open modal if there are fields to review
            }
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);
  const ReviewAndUnderstood = (e) => {
    e.preventDefault();
    var formData;
    if (reviewData) {
      formData = {
        ...reviewData,
        orderId: order.orderId,
        diagnosis: true,
      };
    } else {
      formData = {
        orderId: order.orderId,
        diagnosis: true,
      };
    }
    AxiosInstance.post("consultation/create-review-and-understood", formData)
      .then((response) => {
        toast.success("Reviewed Patient Diagnosis");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  console.log("diet", fieldsToReview);

  return (
    <div>
      {/* {!loading && ( */}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Review Required
          </Typography>
          <Typography variant="body1" gutterBottom>
            Please review the following sections:
          </Typography>
          <List>
            {fieldsToReview.map((field) => (
              <ListItem
                key={field}
                sx={{
                  bgcolor: "#f5f5f5", // Background color for list item
                  color: "#000", // Text color
                  borderRadius: 1, // Slightly rounded corners
                  mb: 1, // Margin between list items
                  p: 0, // Remove default padding
                }}
              >
                <ListItemText
                  // primary={field.replace(/([A-Z])/g, " $1")}
                  primary={capitalize(field)}
                  sx={{ pl: 2, py: 1 }}
                />
              </ListItem>
            ))}
          </List>
          <Typography variant="body2" sx={{ mt: 2, mb: 2 }}>
            After reviewing, click the "Review and Understood" button at the
            bottom of the page.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClose}
            fullWidth
          >
            Close
          </Button>
        </Box>
      </Modal>
      {loading ? (
        <Spinner></Spinner>
      ) : diagnosis && Object.entries(diagnosis).length > 0 ? (
        <>
          {/* <div style={{ animationDelay: "0.5s" }}>
            <span
              style={{
                color: "red",
                display: "inline-block",
                animation: "moveLeftRight 15s infinite",
              }}
            >
              <b>Note :</b> Patient is having Allergy from this food, reaction
              is swealing and effect is Hypertension.
            </span>
          </div> */}
          <Row className="d-flex">
            {diagnosis?.listOfRisk?.map((disease, index) => (
              <Col lg={6} className="d-flex" key={disease.diseaseName}>
                <Card
                  className="flex-fill"
                  style={{
                    borderRadius: "16px",
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                    padding: "10px",
                    background: "linear-gradient(to right, #f7f9fc, #ffffff)",
                  }}
                  elevation={5}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: "bold",
                      color: "#1976d2",
                      marginBottom: "16px",
                    }}
                  >
                    Factors Used to Calculate {disease.diseaseName}
                  </Typography>

                  <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                    <Table
                      sx={{
                        borderCollapse: "separate",
                        borderSpacing: 0,
                        "& th, & td": {
                          borderRight: "1px solid #e0e0e0",
                          borderBottom: "1px solid #e0e0e0",
                        },
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{
                              fontWeight: "bold",
                              fontSize: "16px",
                              backgroundColor: "#f7f9fc",
                              color: "#333",
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            Risk Category
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: "bold",
                              fontSize: "16px",
                              backgroundColor: "#f7f9fc",
                              color: "#333",
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            Risk Factors
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: "bold",
                              fontSize: "16px",
                              backgroundColor: "#f7f9fc",
                              color: "#333",
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            Intensity
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <React.Fragment key={index}>
                          {/* Things You Can't Change */}
                          {disease.unmodifiableDiseaseMappingValues.length >
                            0 && (
                            <>
                              <TableRow>
                                <TableCell
                                  rowSpan={
                                    disease.unmodifiableDiseaseMappingValues
                                      .length + 1
                                  }
                                  sx={{
                                    fontWeight: "bold",
                                    fontSize: "16px",
                                    verticalAlign: "middle",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                >
                                  Things You Can't Change
                                  <Box mt={1}>
                                    <img
                                      src={ThingsYouCantChange}
                                      alt="Things You Can't Change"
                                      style={{
                                        width: "60%",
                                        borderRadius: "8px",
                                      }}
                                    />
                                  </Box>
                                </TableCell>
                              </TableRow>
                              {disease.unmodifiableDiseaseMappingValues.map(
                                (item, idx) => (
                                  <TableRow key={idx}>
                                    <TableCell
                                      sx={{
                                        textAlign: "center",
                                        padding: "8px",
                                      }}
                                    >
                                      {item.riskFactor}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        textAlign: "center",
                                        padding: "8px",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          width: "20px",
                                          height: "20px",
                                          borderRadius: "50%",
                                          backgroundColor: getColorByLabel(
                                            item.riskValue
                                          ),
                                          display: "inline-block",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          color: "#fff",
                                          fontWeight: "bold",
                                          border: "2px solid black",
                                        }}
                                      ></Box>
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                              {disease.modifiableDiseaseMappingValues.length >
                                0 && (
                                <TableRow>
                                  <TableCell
                                    colSpan={3}
                                    style={{
                                      borderBottom: "2px solid #000",
                                      padding: "0",
                                    }}
                                  />
                                </TableRow>
                              )}
                            </>
                          )}

                          {/* Things You Can Change */}
                          {disease.modifiableDiseaseMappingValues.length >
                            0 && (
                            <>
                              <TableRow>
                                <TableCell
                                  rowSpan={
                                    disease.modifiableDiseaseMappingValues
                                      .length + 1
                                  }
                                  sx={{
                                    fontWeight: "bold",
                                    fontSize: "16px",
                                    verticalAlign: "middle",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                >
                                  Things You Can Change
                                  <Box mt={1}>
                                    <img
                                      src={ThingsYouCanChange}
                                      alt="Things You Can Change"
                                      style={{
                                        width: "60%",
                                        borderRadius: "8px",
                                      }}
                                    />
                                  </Box>
                                </TableCell>
                              </TableRow>
                              {disease.modifiableDiseaseMappingValues.map(
                                (item, idx) => (
                                  <TableRow key={idx}>
                                    <TableCell
                                      sx={{
                                        textAlign: "center",
                                        padding: "8px",
                                      }}
                                    >
                                      {item.riskFactor}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        textAlign: "center",
                                        padding: "8px",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          width: "20px",
                                          height: "20px",
                                          borderRadius: "50%",
                                          backgroundColor: getColorByLabel(
                                            item.riskValue
                                          ),
                                          display: "inline-block",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          color: "#fff",
                                          fontWeight: "bold",
                                          border: "2px solid black",
                                        }}
                                      ></Box>
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                              {disease.socialFactorsValues.length > 0 && (
                                <TableRow>
                                  <TableCell
                                    colSpan={3}
                                    style={{
                                      borderBottom: "2px solid #000",
                                      padding: "0",
                                    }}
                                  />
                                </TableRow>
                              )}
                            </>
                          )}

                          {/* Social Factors */}
                          {disease.socialFactorsValues.length > 0 && (
                            <>
                              <TableRow>
                                <TableCell
                                  rowSpan={
                                    disease.socialFactorsValues.length + 1
                                  }
                                  sx={{
                                    fontWeight: "bold",
                                    fontSize: "16px",
                                    verticalAlign: "middle",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                >
                                  Social Factors
                                  <Box mt={1}>
                                    <img
                                      src={SocialFactors}
                                      alt="Social Factors"
                                      style={{
                                        width: "60%",
                                        borderRadius: "8px",
                                      }}
                                    />
                                  </Box>
                                </TableCell>
                              </TableRow>
                              {disease.socialFactorsValues.map((item, idx) => (
                                <TableRow key={idx}>
                                  <TableCell
                                    sx={{ textAlign: "center", padding: "8px" }}
                                  >
                                    {item.riskFactor}
                                  </TableCell>
                                  <TableCell
                                    sx={{ textAlign: "center", padding: "8px" }}
                                  >
                                    <Box
                                      sx={{
                                        width: "20px",
                                        height: "20px",
                                        borderRadius: "50%",
                                        backgroundColor: getColorByLabel(
                                          item.riskValue
                                        ),
                                        display: "inline-block",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        color: "#fff",
                                        fontWeight: "bold",
                                        border: "2px solid black",
                                      }}
                                    ></Box>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </>
                          )}
                        </React.Fragment>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Card>
              </Col>
            ))}
            <div style={{ display: "flex", gap: "15px", marginTop: "10px" }}>
              <p>
                ( <b>No-Risk : </b>{" "}
                <span
                  className="risk-level-circle"
                  style={{
                    background: getColorByLabel("No Risk"),
                    border: "2px solid black",
                  }}
                ></span>{" "}
                )
              </p>
              <p>
                ( <b>None: </b>{" "}
                <span
                  className="risk-level-circle"
                  style={{
                    background: getColorByLabel("None"),
                    border: "2px solid black",
                  }}
                ></span>{" "}
                )
              </p>
              <p>
                ( <b>Very Low Risk : </b>{" "}
                <span
                  className="risk-level-circle"
                  style={{
                    background: getColorByLabel("Very Low"),
                    border: "2px solid black",
                  }}
                ></span>{" "}
                )
              </p>
              <p>
                ( <b>Low Risk : </b>{" "}
                <span
                  className="risk-level-circle"
                  style={{
                    background: getColorByLabel("Low"),
                    border: "2px solid black",
                  }}
                ></span>{" "}
                )
              </p>
              <p>
                ( <b>Medium Risk : </b>{" "}
                <span
                  className="risk-level-circle"
                  style={{
                    background: getColorByLabel("Medium"),
                    border: "2px solid black",
                  }}
                ></span>{" "}
                )
              </p>
              <p>
                ( <b>Moderate Risk : </b>{" "}
                <span
                  className="risk-level-circle"
                  style={{
                    background: getColorByLabel("Moderate"),
                    border: "2px solid black",
                  }}
                ></span>{" "}
                )
              </p>
              <p>
                ( <b>High Risk : </b>{" "}
                <span
                  className="risk-level-circle"
                  style={{
                    background: getColorByLabel("High"),
                    border: "2px solid black",
                  }}
                ></span>{" "}
                )
              </p>
            </div>
            {diagnosis?.listOfRisk?.length > 1 ? (
              <Col lg={12}>
                <div
                  className="meter-content-wrapper mb-5"
                  style={{ padding: "15px" }}
                >
                  <div className="meter-content-inner summery">
                    <div className="meter-head">
                      <h4>Common Risk Factors</h4>
                    </div>
                    <div className="meter-content">
                      <p>
                        The following are common risk factors for both the
                        diseases for which you are at risk because of Lifestyle
                        Habits.
                      </p>
                      <div style={{ marginTop: "10px" }}>
                        <ul style={{ listStyle: "disc" }}>
                          {diagnosis?.commonRiskFactors?.map(
                            (commonRiskFactor, index) => (
                              <li key={index}>
                                <h6>{commonRiskFactor}</h6>
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                      <p style={{ marginTop: "5px", marginBottom: "5px" }}>
                        These are modifiable risk factors and you should take
                        concrete steps to work on these to reduce your risk for
                        both the diseases.
                      </p>
                      <p
                        style={{
                          color: "green",
                          fontSize: "inherit",
                          fontWeight: "600",
                        }}
                      >
                        Please remember prevention is 100 times better than cure
                        .
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            ) : (
              <Col lg={6}>
                <div
                  className="meter-content-wrapper"
                  style={{ height: "100%", marginTop: "0px", padding: "15px" }}
                >
                  <div className="meter-content-inner summery">
                    <div className="meter-head">
                      <h4> Risk Factors</h4>
                    </div>
                    <div className="meter-content">
                      <p>
                        The following are risk factors for disease for which you
                        are at risk because of Lifestyle Habits.
                      </p>
                      <div style={{ marginTop: "10px" }}>
                        <ul style={{ listStyle: "disc" }}>
                          {diagnosis?.commonRiskFactors?.map(
                            (commonRiskFactor, index) => (
                              <li key={index}>
                                <h6>{commonRiskFactor}</h6>
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                      <p style={{ marginTop: "5px", marginBottom: "5px" }}>
                        These are modifiable risk factors and you should take
                        concrete steps to work on these to reduce your risk for
                        disease.
                      </p>
                      <p
                        style={{
                          color: "green",
                          fontSize: "inherit",
                          fontWeight: "600",
                        }}
                      >
                        Please remember prevention is 100 times better than cure
                        .
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            )}
          </Row>
          {fieldsToReview.includes("diagnosis") && (
            <div style={{ textAlign: "center" }}>
              <Button
                onClick={ReviewAndUnderstood}
                sx={{
                  background: "#5353e9",
                  color: "#fff",
                  minWidth: "180px",

                  "&:hover": { background: "#c60176" },
                }}
              >
                Reviewed and Understood
              </Button>
            </div>
          )}
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            padding: 2,
          }}
        >
          <div>
            <Typography variant="h6" sx={{ color: "green" }}>
              No Diagnosis Available
            </Typography>
          </div>
        </Box>
      )}
    </div>
  );
};

export default DietitianDiagnosis;
