import React from "react";
import { Modal, Box, Typography, Button, Grid } from "@mui/material";
import { AxiosInstance } from "../../configration/AxiosConfiguration";

const PriceModal = ({
  open,
  setOpen,
  slotIds,
  membershipFormData,
  userId,
  trainerData,
}) => {
  const { selectedPlan, price, gstAmount } = membershipFormData;
  function getPaymentSuccessBaseUrl() {
    const baseUrl = window.location.href;
    let arr = baseUrl.split("/");
    let newPath = arr[0] + "//" + arr[2] + `/login-email`;
    return newPath;
  }
  const handlePayment = () => {
    const formData = {
      // amount: membershipFormData.price,  // Use price here instead of total
      partnerUserId: userId,
      partnerType: "TRAINER",
      ClassNames: "Excerise",
      planId: membershipFormData.id,
      slotIds: slotIds,
    };
    AxiosInstance.post(
      `payment/create-subscription-gym-and-trainer?successURL=${encodeURIComponent(
        getPaymentSuccessBaseUrl()
      )}`,
      formData
    )
      .then((response) => {
        window.location.href = response;
        membershipFormData({
          selectedPlan: "",
          price: null,
          errors: {
            plan: false,
            price: false,
          },
        });
        setPaymentModal(false);
        onClose();
        // Assuming the response is a URL or location to redirect
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  console.log("membership", membershipFormData);
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      centered
      modalClassName="custom-modal"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "50%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          textAlign: "center",
        }}
      >
        <Typography
          variant="h6"
          component="h2"
          style={{
            backgroundColor: "black",
            color: "white",
            border: "1px solid lightgrey",
          }}
        >
          Complete your Payment
        </Typography>
        <div className="modal-body-centered">
          <Typography
            variant="h5"
            sx={{
              color: "purple",
              fontWeight: "600",
              fontSize: "1.75rem",
            }}
            component="h5"
          >
            Uncurl: Health Services
          </Typography>
          <Typography
            variant="h6"
            // sx={{ color: "purple" }}
            component="h6"
          >
            Training Services
          </Typography>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={6} sx={{ textAlign: "right" }}>
              <Typography variant="h6">Plan Duration:</Typography>
              <Typography variant="h6">Order Value:</Typography>
              {gstAmount && <Typography variant="body1">Gst:</Typography>}
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "left" }}>
              <Typography variant="h6">{selectedPlan}</Typography>
              <Typography variant="h6">₹{price}</Typography>
              {gstAmount && (
                <Typography variant="body1">₹{parseInt(gstAmount)}</Typography>
              )}
            </Grid>
          </Grid>

          <Box
            sx={{
              borderTop: "2px solid lightgrey",
              mt: 2,
              mb: 2,
              width: "100%",
            }}
          />

          <Grid container spacing={2}>
            <Grid item xs={6} sx={{ textAlign: "right" }}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                Total:
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "left" }}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                ₹{gstAmount && price + parseInt(gstAmount)}
              </Typography>
            </Grid>
          </Grid>

          {/* Line below the Total */}
          <Box
            sx={{
              borderTop: "2px solid lightgrey",
              mt: 2,
              mb: 2,
              width: "100%",
            }}
          />
          <Typography variant="body1" sx={{ mb: 3 }}>
            This amount will be charged to your account.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{
              backgroundColor: "#5353e9",
              "&:hover": { backgroundColor: "#c60176" },
            }}
            onClick={() => handlePayment()} // Add your payment handling function here
          >
            Confirm & Pay
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default PriceModal;
