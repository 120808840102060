import { useEffect, useState } from "react";
import { Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import {
  Button,
  Select,
  Checkbox,
  FormControlLabel,
  FormControl,
  Grid,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  MenuItem,
  CircularProgress,
  Box,
  Typography,
} from "@mui/material";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { MultiSelect } from "primereact/multiselect";
import { useNavigate } from "react-router-dom";
import ModalForFileView from "../../shared/ModalForFileView";
import Spinner from "../../shared/Spinner";

const DieticianGeneralInfo = ({ onBack }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [gymAreaPincode, setGymAreaPincode] = useState([""]);
  const [certificateName, setCertificateName] = useState([""]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [aboutYourself, setAboutYourself] = useState("");
  const [expertise, setExpertise] = useState("");
  const [registeredBusiness, setRegisteredBusiness] = useState(null);
  const [organizationName, setOrganizationName] = useState("");
  const [organizationPhoneNumber, setOrganizationPhoneNumber] = useState("");
  const [organizationBusinessTime, setOrganizationBusinessTime] = useState("");
  const [organizationAddress, setOrganizationAddress] = useState("");
  const [yearsWorkingAsTrainer, setYearsWorkingAsTrainer] = useState();
  const [currentEngagement, setCurrentEngagement] = useState("");
  const [googleRating, setGoogleRating] = useState("");
  const [getDemo, setGetDemo] = useState("");
  const [getDemoTimeFrom, setGetDemoTimeFrom] = useState("");
  const [getDemoTimeTo, setGetDemoTimeTo] = useState("");
  const [demoTimeNote, setDemoTimeNote] = useState("");
  const [classes, setClasses] = useState([
    {
      className: "",
      mode: "",
      session: "",
      gender: "",
      weekdays: [],
      timingFrom: "",
      timingTo: "",
      ageGroup: [],
    },
  ]);
  const [
    childrenFromThreeToThirteenYearsAge,
    setChildrenFromThreeToThirteenYearsAge,
  ] = useState("");
  const [
    childrenFromThirteenToTwentyOneYearsAge,
    setChildrenFromThirteenToTwentyOneYearsAge,
  ] = useState("");
  const [adultMaleFromTwentyOneYearsAge, setAdultMaleFromTwentyOneYearsAge] =
    useState("");
  const [freeSessionMorning, setFreeSessionMorning] = useState(null);
  const [freeSessionEvening, setFreeSessionEvening] = useState(null);
  const [sharingReferences, setSharingReferences] = useState(null);
  const [outstandingTestimonialsFileList, setOutstandingTestimonialsFileList] =
    useState([]);
  const [testimonialfileUploading, settestimonialfileUploading] =
    useState(false);
  const [testimonialModalOpen, setTestimonialModalOpen] = useState(false);
  const [selectedTestimonialFile, setselectedTestimonialFile] = useState([]);
  const [certificationsFileList, setCertificationsFileList] = useState([]);
  const [certificationsfileUploading, setcertificationsfileUploading] =
    useState(false);
  const [certificationsModalOpen, setcertificationsModalOpen] = useState(false);
  const [selectedcertificationsFile, setselectedcertificationsFile] = useState(
    []
  );
  const [classAttachmentsFileList, setClassAttachmentsFileList] = useState([]);
  const [classAttachmentsfileUploading, setclassAttachmentsfileUploading] =
    useState(false);
  const [classAttachmentsModalOpen, setclassAttachmentsModalOpen] =
    useState(false);
  const [selectedclassAttachmentsFile, setselectedclassAttachmentsFile] =
    useState([]);
  const [trainerImageList, setTrainerImageList] = useState([]);
  const [trainerImagefileUploading, settrainerImagefileUploading] =
    useState(false);
  const [trainerImageModalOpen, settrainerImageModalOpen] = useState(false);
  const [selectedtrainerImageFile, setselectedtrainerImageFile] = useState([]);

  const [referenceDetail, setReferenceDetail] = useState({
    reference1: {
      name: "",
      mobile: "",
    },
    reference2: {
      name: "",
      mobile: "",
    },
    reference3: {
      name: "",
      mobile: "",
    },
  });

  const [freeDemoTimingsMorning, setFreeDemoTimingsMorning] = useState({
    timing: {
      from: "",
      to: "",
      note: "",
    },
  });

  const [freeDemoTimingsEvening, setFreeDemoTimingsEvening] = useState({
    timing: {
      from: "",
      to: "",
      note: "",
    },
  });

  const [fieldErrors, setFieldErrors] = useState({});
  const [showRegisteredOrganization, setShowRegisteredOrganization] =
    useState();

  const [showReferences, setShowReferences] = useState();
  const [testimonials, setTestimonials] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [LifestyleStep, setLifestyleStep] = useState(0);

  const handleRegisteredBusiness = (event) => {
    const isBusinessRegistered = event.target.value === "true";
    setRegisteredBusiness(isBusinessRegistered);
    setShowRegisteredOrganization(isBusinessRegistered);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      registeredBusiness: false,
    }));
  };

  const handlesharingReferences = (event) => {
    const isBusinessRegistered = event.target.value === "true";
    setSharingReferences(event.target.value === "true");
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      sharingReferences: false,
    }));
    if (!isBusinessRegistered) {
      setReferenceDetail({
        reference1: {
          name: "",
          mobile: "",
        },
        reference2: {
          name: "",
          mobile: "",
        },
        reference3: {
          name: "",
          mobile: "",
        },
      });
    }
  };

  const handleDemoSession = (event) => {
    const isFreeSession = event.target.value === "true";
    setFreeSessionMorning(isFreeSession);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      freeSessionMorning: false,
    }));
    if (!isFreeSession) {
      setFreeDemoTimingsMorning({
        timing: {
          from: "",
          to: "",
          note: "",
        },
      });
    }
  };

  const handleDemoSessionEvening = (event) => {
    const isFreeSession = event.target.value === "true";
    setFreeSessionEvening(isFreeSession);
    if (!isFreeSession) {
      setFreeDemoTimingsEvening({
        timing: {
          from: "",
          to: "",
          note: "",
        },
      });
    }
  };

  const handleAboutYourself = (event) => {
    setAboutYourself(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      aboutYourself: false,
    }));
  };

  const handleExpertise = (event) => {
    setExpertise(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      expertise: false,
    }));
  };

  const handleAddress = (event) => {
    setAddress(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      address: false,
    }));
  };

  const handleYearsWorkingAsTrainer = (event) => {
    setYearsWorkingAsTrainer(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      yearsWorkingAsTrainer: false,
    }));
  };

  const handleCurrentEngagement = (event) => {
    setCurrentEngagement(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      currentEngagement: false,
    }));
  };

  const handleGoogleRating = (event) => {
    setGoogleRating(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      googleRating: false,
    }));
  };

  const handleReferenceUpdate = (reference, field, value) => {
    setReferenceDetail((prevReferences) => ({
      ...prevReferences,
      [reference]: {
        ...(prevReferences?.[reference] ?? {}),
        [field]: value,
      },
    }));
    if (reference === "reference1" && field === "name") {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        reference1Name: false,
      }));
    }

    if (reference === "reference1" && field === "mobile") {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        reference1Mobile: false,
      }));
    }
  };

  const handleDemoSessionUpdate = (field, value) => {
    setFreeDemoTimingsMorning((prevTimings) => ({
      timing: {
        ...(prevTimings?.timing || {}),
        [field]: value, // Dynamically update the correct field
      },
    }));
  };

  const handleDemoSessionUpdateEvening = (field, value) => {
    setFreeDemoTimingsEvening((prevTimings) => ({
      timing: {
        ...(prevTimings?.timing || {}),
        [field]: value, // Dynamically update the correct field
      },
    }));
  };

  const handleOrganizationName = (event) => {
    setOrganizationName(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      organizationName: false,
    }));
  };
  const handleOrganizationPhoneNumber = (event) => {
    const phoneNumber = event.target.value;
    setOrganizationPhoneNumber(phoneNumber);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      organizationPhoneNumber: false,
    }));
  };

  const handleOrganizationBusinessTime = (event) => {
    setOrganizationBusinessTime(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      organizationBusinessTime: false,
    }));
  };
  const handleOrganizationAddress = (event) => {
    setOrganizationAddress(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      organizationAddress: false,
    }));
  };

  const handleLifestyleStepBack = () => {
    setLifestyleStep((prev) => prev - 1);
  };

  const handleNext = async (e) => {
    e.preventDefault();
    const commaSeparatedPincodes = gymAreaPincode.join(",");
    const commaSeperatedCertification = certificateName.join(", ");
    let error = {};

    if (registeredBusiness == null) {
      error.registeredBusiness = true;
    }
    if (registeredBusiness == true) {
      if (organizationName == null || organizationName == "") {
        error.organizationName = true;
      }
      if (organizationPhoneNumber == null || organizationPhoneNumber == "") {
        error.organizationPhoneNumber = true;
      }
      if (organizationPhoneNumber != null) {
        if (organizationPhoneNumber.length !== 10) {
          error.organizationPhoneNumber = true;
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Enter a valid 10-digit Phone Number",
          });
        }
      }
      if (organizationBusinessTime == null || organizationBusinessTime == "") {
        error.organizationBusinessTime = true;
      }
      if (organizationAddress == null || organizationAddress == "") {
        error.organizationAddress = true;
      }
    }
    if (aboutYourself == null || aboutYourself == "") {
      error.aboutYourself = true;
    }
    if (yearsWorkingAsTrainer == null || yearsWorkingAsTrainer == "") {
      error.yearsWorkingAsTrainer = true;
    }
    // console.log("sharing", sharingReferences)
    //     if (sharingReferences == null || sharingReferences == "") {
    //       error.sharingReferences = true;
    //     }

    //     if (sharingReferences === true) {
    //       if (
    //         !referenceDetail.reference1.name ||
    //         referenceDetail.reference1.name.trim() === ""
    //       ) {
    //         error.reference1Name = true;
    //       }

    //   if (
    //     !referenceDetail.reference1.mobile ||
    //     referenceDetail.reference1.mobile.trim() === ""
    //   ) {
    //     error.reference1Mobile = true;
    //   }
    //   if (
    //     referenceDetail.reference1.name &&
    //     referenceDetail.reference1.name.trim() !== "" &&
    //     referenceDetail.reference1.mobile &&
    //     referenceDetail.reference1.mobile.trim() !== ""
    //   ) {
    //   }
    // }
    console.log("error", error);
    setFieldErrors(error);
    if (Object.keys(error).length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Fill all the Mandatory Fields!",
      });
    } else {
      const formDataCheck = new FormData();

      formDataCheck.append("firstName", firstName);
      formDataCheck.append("lastName", lastName);
      formDataCheck.append("email", email);
      formDataCheck.append("phoneNumber", phoneNumber);
      formDataCheck.append("registeredBusiness", registeredBusiness);
      formDataCheck.append("organizationName", organizationName);
      formDataCheck.append("organizationPhoneNumber", organizationPhoneNumber);
      formDataCheck.append(
        "organizationBusinessTime",
        organizationBusinessTime
      );
      formDataCheck.append("organizationAddress", organizationAddress);
      formDataCheck.append("describeYourself", aboutYourself);
      formDataCheck.append("currentEngagements", currentEngagement);
      formDataCheck.append("googleRatings", googleRating);
      formDataCheck.append("expertise", expertise);
      formDataCheck.append("registeredAddress", address);
      formDataCheck.append("pincode", commaSeparatedPincodes);
      formDataCheck.append("certifications", commaSeperatedCertification);
      formDataCheck.append("yearsWorkingAsTrainer", yearsWorkingAsTrainer);

      formDataCheck.append(
        "childrenFromThreeToThirteenYearsAge",
        childrenFromThreeToThirteenYearsAge
      );
      formDataCheck.append(
        "childrenFromThirteenToTwentyOneYearsAge",
        childrenFromThirteenToTwentyOneYearsAge
      );
      formDataCheck.append(
        "adultMaleFromTwentyOneYearsAge",
        adultMaleFromTwentyOneYearsAge
      );
      formDataCheck.append("sharingReferences", sharingReferences);
      formDataCheck.append("referenceDetail", JSON.stringify(referenceDetail));
      formDataCheck.append("freeSessionMorning", freeSessionMorning);
      formDataCheck.append(
        "freeDemoTimingsMorning",
        JSON.stringify(freeDemoTimingsMorning)
      );
      formDataCheck.append(
        "freeSessionEvening",
        freeSessionEvening == null ? false : true
      );
      formDataCheck.append(
        "freeDemoTimingsEvening",
        JSON.stringify(freeDemoTimingsEvening)
      );
      formDataCheck.append("workDetail", JSON.stringify(classes));
      try {
        const response = await AxiosInstance.post(
          "trainer/add-trainer",
          formDataCheck,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setLifestyleStep((prev) => prev + 1);
      } catch (error) {
        toast.error("Failed to submit information", error);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const commaSeparatedPincodes = gymAreaPincode.join(",");
    const commaSeperatedCertification = certificateName.join(", ");
    let error = {};

    const formDataCheck = new FormData();
    formDataCheck.append("firstName", firstName);
    formDataCheck.append("lastName", lastName);
    formDataCheck.append("email", email);
    formDataCheck.append("phoneNumber", phoneNumber);
    formDataCheck.append("registeredBusiness", registeredBusiness);
    formDataCheck.append("organizationName", organizationName);
    formDataCheck.append("organizationPhoneNumber", organizationPhoneNumber);
    formDataCheck.append("organizationBusinessTime", organizationBusinessTime);
    formDataCheck.append("organizationAddress", organizationAddress);
    formDataCheck.append("describeYourself", aboutYourself);
    formDataCheck.append("currentEngagements", currentEngagement);
    formDataCheck.append("googleRatings", googleRating);
    formDataCheck.append("expertise", expertise);
    formDataCheck.append("registeredAddress", address);
    formDataCheck.append("pincode", commaSeparatedPincodes);
    formDataCheck.append("certifications", commaSeperatedCertification);
    formDataCheck.append("yearsWorkingAsTrainer", yearsWorkingAsTrainer);

    formDataCheck.append(
      "childrenFromThreeToThirteenYearsAge",
      childrenFromThreeToThirteenYearsAge
    );
    formDataCheck.append(
      "childrenFromThirteenToTwentyOneYearsAge",
      childrenFromThirteenToTwentyOneYearsAge
    );
    formDataCheck.append(
      "adultMaleFromTwentyOneYearsAge",
      adultMaleFromTwentyOneYearsAge
    );
    formDataCheck.append("sharingReferences", sharingReferences);
    formDataCheck.append("referenceDetail", JSON.stringify(referenceDetail));
    formDataCheck.append("freeSessionMorning", freeSessionMorning);
    formDataCheck.append(
      "freeDemoTimingsMorning",
      JSON.stringify(freeDemoTimingsMorning)
    );
    formDataCheck.append(
      "freeSessionEvening",
      freeSessionEvening == null ? false : true
    );
    formDataCheck.append(
      "freeDemoTimingsEvening",
      JSON.stringify(freeDemoTimingsEvening)
    );
    formDataCheck.append("workDetail", JSON.stringify(classes));
    try {
      const response = await AxiosInstance.post(
        "trainer/add-trainer",
        formDataCheck,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Your Form Submitted Successfully!",
      });

      setTimeout(() => {
        navigate("/trainer-home-page");
      }, 1000);
    } catch (error) {
      toast.error("Failed to submit information", error);
    }
    // }
  };
  const getDietitianData = () => {
    setIsLoading(true);
    AxiosInstance.get("/trainer/getTrainerDetails")
      .then((response) => {
        setFirstName(response?.firstName);
        setLastName(response?.lastName);
        setEmail(response?.email);
        setPhoneNumber(response?.phoneNumber);
        setRegisteredBusiness(response?.registeredBusiness);

        setOrganizationAddress(response?.organizationAddress);
        setOrganizationBusinessTime(response?.organizationBusinessTime);

        setOrganizationName(response?.organizationName);
        setOrganizationPhoneNumber(response?.organizationPhoneNumber);
        setAboutYourself(response?.describeYourself);

        setYearsWorkingAsTrainer(response?.yearsWorkingAsTrainer);
        setExpertise(response?.expertise);
        setAddress(response?.registeredAddress);

        setRegisteredBusiness(response?.registeredBusiness);
        setOrganizationName(response?.organizationName);
        setOrganizationPhoneNumber(response?.organizationPhoneNumber);

        setOrganizationBusinessTime(response?.organizationBusinessTime);
        setOrganizationAddress(response?.organizationAddress);
        setYearsWorkingAsTrainer(response?.yearsWorkingAsTrainer);
        setCertificationsFileList(response?.certificationsFileList || []);
        setClassAttachmentsFileList(response?.classAttachmentsFileList || []);
        setTrainerImageList(response?.trainerImageList || []);
        setOutstandingTestimonialsFileList(
          response?.outstandingTestimonialsFileList || []
        );
        setGymAreaPincode(response?.pincode.split(", "));
        setCertificateName(response?.certifications.split(", "));
        setCurrentEngagement(response?.currentEngagements);
        setGoogleRating(response?.googleRatings);
        setGetDemo(response?.freeSessionMorning);

        setChildrenFromThreeToThirteenYearsAge(
          response?.childrenFromThreeToThirteenYearsAge
        );
        setChildrenFromThirteenToTwentyOneYearsAge(
          response?.childrenFromThirteenToTwentyOneYearsAge
        );

        setAdultMaleFromTwentyOneYearsAge(
          response?.adultMaleFromTwentyOneYearsAge
        );
        setSharingReferences(response?.sharingReferences);

        setReferenceDetail(JSON.parse(response?.referenceDetail));
        setFreeSessionMorning(response?.freeSessionMorning);
        setFreeDemoTimingsMorning(JSON.parse(response?.freeDemoTimingsMorning));
        setFreeSessionEvening(response?.freeSessionEvening);
        setFreeDemoTimingsEvening(JSON.parse(response?.freeDemoTimingsEvening));
        setClasses(JSON.parse(response?.workDetail));
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getDietitianData();
  }, []);

  const handleTrainerPhoto = async (event, fileTypeName) => {
    event.preventDefault();

    const fileArray = Array.from(event.target.files);
    if (fileArray.length === 0) {
      toast.error("No files selected.");
      return;
    }

    let fileCheck = fileArray[0]?.name?.split(".")?.pop();
    if (
      fileCheck === "gif" ||
      fileCheck === "jfif" ||
      fileCheck === "csv" ||
      fileCheck === "xls"
    ) {
      toast.error("GIF, CSV, XLS, and JFIF files are not allowed");
      return;
    }

    if (fileTypeName === "TESTIMONIAL") {
      settestimonialfileUploading(true);
      setselectedTestimonialFile([...selectedTestimonialFile, ...fileArray]);
    } else if (fileTypeName === "TRAINER_IMAGE") {
      settrainerImagefileUploading(true);
      setselectedtrainerImageFile([...selectedtrainerImageFile, ...fileArray]);
    } else if (fileTypeName === "CERTIFICATION") {
      setcertificationsfileUploading(true);
      setselectedcertificationsFile([
        ...selectedcertificationsFile,
        ...fileArray,
      ]);
    } else {
      setclassAttachmentsfileUploading(true);
      setselectedclassAttachmentsFile([
        ...selectedclassAttachmentsFile,
        ...fileArray,
      ]);
    }

    const formData = new FormData();

    // Append files to formData
    formData.append("fileType", fileTypeName);
    for (const file of fileArray) {
      formData.append("file", file); // Ensure the key matches backend expectations
    }

    try {
      const response = await AxiosInstance.post(
        "trainer/trainer-files",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      toast.success("Documents Uploaded Successfully");
      settestimonialfileUploading(false);
      settrainerImagefileUploading(false);
      setcertificationsfileUploading(false);
      setclassAttachmentsfileUploading(false);
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("An error occurred while uploading the files.");
      settestimonialfileUploading(false);
      settrainerImagefileUploading(false);
      setcertificationsfileUploading(false);
      setclassAttachmentsfileUploading(false);
    }
  };

  const weekdays = [
    { name: "Monday", code: "monday" },
    { name: "Tuesday", code: "tuesday" },
    { name: "Wednesday", code: "wednesday" },
    { name: "Thursday", code: "thursday" },
    { name: "Friday", code: "friday" },
    { name: "Saturday", code: "saturday" },
    { name: "Sunday", code: "sunday" },
  ];

  const handleSelectChange = (e, index) => {
    const updatedClasses = [...classes];
    updatedClasses[index].weekdays = e.value;
    setClasses(updatedClasses);
  };

  const addTestimonial = () => {
    if (testimonials?.length < 3) {
      setTestimonials([...testimonials, ""]);
    }
  };

  const handleInputChange = (e, index, field) => {
    const updatedClasses = [...classes];
    updatedClasses[index][field] = e.target.value;
    setClasses(updatedClasses);
  };

  useEffect(() => {
    if (testimonials?.length >= 3) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  }, [testimonials]);

  const isChecked = (index, value) => {
    return classes[index]?.ageGroup?.includes(value) || false;
  };

  const handleAddAreaPincode = () => {
    const newRow = { areaPincode: "" };
    setGymAreaPincode([...gymAreaPincode, ""]);
  };

  const handleAddCertificateName = () => {
    const newRow = { certificate: "" };
    setCertificateName([...certificateName, ""]);
  };

  const handleAreaPincode = (e, index) => {
    const { value } = e.target;
    setGymAreaPincode((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[index] = value;
      return updatedRows;
    });
  };

  const handleCertificateName = (e, index) => {
    const { value } = e.target;
    setCertificateName((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[index] = value;
      return updatedRows;
    });
  };

  const handleDeleteAreaPincode = (index) => {
    const updatedRows = gymAreaPincode.filter((_, i) => i !== index);
    setGymAreaPincode(updatedRows);
  };

  const handleDeleteCertificateName = (index) => {
    const updatedRows = certificateName.filter((_, i) => i !== index);
    setCertificateName(updatedRows);
  };

  const handleGetDemo = (event) => {
    const value = event.target.value === "true";
    setGetDemo(value);
  };
  const handleGetDemoTimeFrom = (event) => {
    setGetDemoTimeFrom(event.target.value);
  };
  const handleDemoTimeTo = (event) => {
    setGetDemoTimeTo(event.target.value);
  };
  const handleDemoTimeNote = (event) => {
    setDemoTimeNote(event.target.value);
  };

  const handleAgeGroupChange = (category, value, index) => {
    const updatedClasses = [...classes];
    const classData = updatedClasses[index];

    // If the checkbox is checked, add the value to the age group array
    if (classData.ageGroup.includes(value)) {
      classData.ageGroup = classData.ageGroup.filter(
        (group) => group !== value
      );
    } else {
      classData.ageGroup.push(value);
    }

    setClasses(updatedClasses);
  };

  const handleClassDelete = (index) => {
    const updatedClasses = classes.filter((_, i) => i !== index);
    setClasses(updatedClasses);
  };

  const handleAddClass = () => {
    setClasses([
      ...classes,
      {
        className: "",
        mode: "",
        session: "",
        gender: "",
        weekdays: [],
        timingFrom: "",
        timingTo: "",
        ageGroup: [],
      },
    ]);
  };

  const handleDownload = (filePath) => {
    // if (!filePath) return;

    console.log("Downloading file from:", filePath);

    const downloadApi = "document/download-partner-document/TRAINER";
    const encodedUrl = encodeURIComponent(filePath);
    const authToken = window.sessionStorage.getItem("token");
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_SERVER_URL}${downloadApi}?path=${encodedUrl}`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }
        const contentDispositionHeader = response.headers.get(
          "Content-Disposition"
        );
        if (contentDispositionHeader) {
          const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
            contentDispositionHeader
          );
          if (matches != null && matches[1]) {
            const filename = matches[1].replace(/['"]/g, "");
            response.blob().then((blobData) => {
              const blobUrl = window.URL.createObjectURL(blobData);
              const downloadLink = document.createElement("a");
              downloadLink.href = blobUrl;
              downloadLink.download = filename;
              document.body.appendChild(downloadLink);
              downloadLink.click();
              document.body.removeChild(downloadLink);
              window.URL.revokeObjectURL(blobUrl);
            });
          }
        }
      })
      .catch((error) => {
        toast.error(`Error fetching or downloading the file: ${error}`);
      });
  };

  const handleDelete = (filePath, gymPhoto) => {
    console.log("filePath", gymPhoto);
    const id = gymPhoto.id;

    const downloadApi = "document/delete-partners-document";
    const encodedUrl = encodeURIComponent(filePath);
    const authToken = window.sessionStorage.getItem("token");
    const requestOptions = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_SERVER_URL}${downloadApi}/${id}/TRAINER`,
      requestOptions
    )
      .then((response) => {
        if (response.ok) {
          Swal.fire({
            title: "Deleted Successfully!",
            text: "The document was deleted successfully.",
            icon: "success",
            confirmButtonText: "OK",
          });
        }
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }

        const contentDispositionHeader = response.headers.get(
          "Content-Disposition"
        );
        if (contentDispositionHeader) {
          const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
            contentDispositionHeader
          );
          if (matches != null && matches[1]) {
            const filename = matches[1].replace(/['"]/g, "");
            response.blob().then((blobData) => {
              const blobUrl = window.URL.createObjectURL(blobData);
              const downloadLink = document.createElement("a");
              downloadLink.href = blobUrl;
              downloadLink.download = filename;
              document.body.appendChild(downloadLink);
              downloadLink.click();
              document.body.removeChild(downloadLink);
              window.URL.revokeObjectURL(blobUrl);
            });
          } else {
            toast.error("Filename not found in Content-Disposition header");
          }
        }
      })
      .catch((error) => {
        toast.error(`Error fetching or downloading the file: ${error}`);
      });
  };

  console.log("sharingReferences", sharingReferences);
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {LifestyleStep == 0 && (
            <>
              <h4>TRAINER PARTNER QUESTIONNAIRE </h4>
              <Form
                style={{
                  border: "1px solid lightgrey",
                  padding: "15px",
                  marginTop: "25px",
                  boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                  fontSize: "18px",
                }}
              >
                <Row>
                  <Col md={3}>
                    <FormGroup>
                      <Label for="exampleEmail">First Name of Trainer</Label>
                      <Input
                        disabled
                        id=""
                        name="name"
                        value={firstName}
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label for="exampleEmail">Last Name of Trainer</Label>
                      <Input
                        id=""
                        disabled
                        name="name"
                        value={lastName}
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label for="examplePassword">Email</Label>
                      <Input
                        disabled
                        id=""
                        name="email"
                        value={email}
                        type="email"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label for="exampleEmail">Phone Number</Label>
                      <Input
                        disabled
                        id="phoneNumber"
                        name="registrationNumber"
                        value={phoneNumber}
                        type="number"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <hr style={{ border: "1px solid #000000" }}></hr>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label
                        style={{
                          color: fieldErrors?.address ? "red" : "inherit",
                        }}
                      >
                        Enter your Address.
                      </Label>
                      <Input
                        id=""
                        name="name"
                        value={address}
                        onChange={(e) => {
                          handleAddress(e);
                        }}
                        placeholder="Address"
                        type="textarea"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <hr style={{ border: "1px solid #000000" }}></hr>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label
                        for="exampleEmail"
                        style={{
                          color: fieldErrors?.aboutYourself ? "red" : "inherit",
                        }}
                      >
                        Please narrate briefly about yourself.
                      </Label>
                      <Input
                        id=""
                        name="name"
                        value={aboutYourself}
                        onChange={(e) => {
                          handleAboutYourself(e);
                        }}
                        placeholder="Narrate about yourself"
                        type="textarea"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <hr style={{ border: "1px solid #000000" }}></hr>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label
                        style={{
                          color: fieldErrors?.expertise ? "red" : "inherit",
                        }}
                      >
                        Enter your Expertise.
                      </Label>
                      <Input
                        id=""
                        name="name"
                        value={expertise}
                        onChange={(e) => {
                          handleExpertise(e);
                        }}
                        placeholder="Expertise"
                        type="textarea"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <hr style={{ border: "1px solid #000000" }}></hr>
                <Row className="mt-4">
                  <Col md={12}>
                    <FormGroup>
                      <span
                        style={{
                          color: fieldErrors?.registeredBusiness
                            ? "red"
                            : "inherit",
                        }}
                      >
                        Do you have a registered practice/organization for your
                        business ?
                      </span>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <Input
                        name="registeredOrganization"
                        type="radio"
                        value="true"
                        checked={registeredBusiness === true}
                        onChange={(e) => {
                          handleRegisteredBusiness(e);
                        }}
                        style={{ cursor: "pointer" }}
                      />
                      &nbsp;
                      <Label check className="ml-1">
                        Yes
                      </Label>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <Input
                        name="registeredOrganization"
                        type="radio"
                        value="false"
                        checked={registeredBusiness === false}
                        onChange={(e) => {
                          handleRegisteredBusiness(e);
                        }}
                        style={{ cursor: "pointer" }}
                      />
                      &nbsp;
                      <Label check className="ml-1">
                        No
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
                {(showRegisteredOrganization || registeredBusiness) && (
                  <>
                    <Row>
                      <Col md={4}>
                        <FormGroup>
                          <Label for="organizationName">Name</Label>
                          <Input
                            id={`organizationName`}
                            name="organizationName"
                            placeholder="Enter Name"
                            type="text"
                            value={organizationName}
                            onChange={(e) => {
                              handleOrganizationName(e);
                            }}
                            style={{
                              borderColor: fieldErrors?.organizationName
                                ? "red"
                                : "inherit",
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Label for={`phoneNumber`}>Phone Number</Label>
                          <Input
                            id={`phoneNumber`}
                            name="phoneNumber"
                            placeholder="Enter Phone Number"
                            type="number"
                            maxLength={10}
                            onChange={(e) => {
                              handleOrganizationPhoneNumber(e);
                            }}
                            value={organizationPhoneNumber}
                            style={{
                              borderColor: fieldErrors?.organizationPhoneNumber
                                ? "red"
                                : "lightgrey",
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Label for={`organizationBusinessTime`}>
                            Business Time
                          </Label>
                          <Input
                            id={`organizationBusinessTime`}
                            name="organizationBusinessTime"
                            placeholder="Enter Email Id"
                            type="time"
                            onChange={(e) => {
                              handleOrganizationBusinessTime(e);
                            }}
                            value={organizationBusinessTime}
                            style={{
                              borderColor: fieldErrors?.organizationBusinessTime
                                ? "red"
                                : "inherit",
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup>
                          <Label for={`organizationAddress`}>Address</Label>
                          <Input
                            id={`organizationAddress`}
                            name="phoneNumber"
                            placeholder="Enter Address"
                            type="textarea"
                            onChange={(e) => {
                              handleOrganizationAddress(e);
                            }}
                            value={organizationAddress}
                            style={{
                              borderColor: fieldErrors?.organizationAddress
                                ? "red"
                                : "inherit",
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </>
                )}
                <hr style={{ border: "1px solid #000000" }}></hr>
                <Row className="mt-4">
                  <Col md={6}>
                    <Label
                      style={{
                        color: fieldErrors?.yearsWorkingAsTrainer
                          ? "red"
                          : "inherit",
                      }}
                    >
                      How long (years) you have now worked in this capacity?
                    </Label>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Input
                        id=""
                        name="name"
                        value={yearsWorkingAsTrainer}
                        onChange={(e) => {
                          handleYearsWorkingAsTrainer(e);
                        }}
                        placeholder="Enter years"
                        type="number"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <hr style={{ border: "1px solid #000000" }}></hr>
                <Row className="mt-4">
                  <Col md={6}>
                    <FormControl fullWidth>
                      <Label
                        style={{
                          color: fieldErrors.gymAreaPincode ? "red" : "inherit",
                        }}
                      >
                        List all the pin codes from where your members come
                        from.
                      </Label>
                      {gymAreaPincode?.map((row, index) => (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "10px",
                          }}
                          key={index}
                        >
                          <input
                            id={`gymAreaPincode${index}`}
                            type="text"
                            className="form-control"
                            value={row}
                            onChange={(e) => handleAreaPincode(e, index)}
                            style={{ marginRight: "10px" }}
                          />
                          {index === gymAreaPincode.length - 1 && (
                            <>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={handleAddAreaPincode}
                                style={{ marginRight: "10px" }}
                              >
                                Add
                              </Button>
                              {gymAreaPincode.length > 1 && (
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  onClick={() => handleDeleteAreaPincode(index)}
                                >
                                  Delete
                                </Button>
                              )}
                            </>
                          )}
                        </div>
                      ))}
                    </FormControl>
                  </Col>
                  <Col md={6}>
                    <FormControl fullWidth>
                      <Label
                        style={{
                          color: fieldErrors.certificateName
                            ? "red"
                            : "inherit",
                        }}
                      >
                        List all your Certificates / Recognition.
                      </Label>
                      {certificateName?.map((row, index) => (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "10px",
                          }}
                          key={index}
                        >
                          <input
                            id={`certificate${index}`}
                            type="text"
                            className="form-control"
                            value={row}
                            onChange={(e) => handleCertificateName(e, index)}
                            style={{ marginRight: "10px" }}
                          />
                          {index === certificateName.length - 1 && (
                            <>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={handleAddCertificateName}
                                style={{ marginRight: "10px" }}
                              >
                                Add
                              </Button>
                              {certificateName.length > 1 && (
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  onClick={() =>
                                    handleDeleteCertificateName(index)
                                  }
                                >
                                  Delete
                                </Button>
                              )}
                            </>
                          )}
                        </div>
                      ))}
                    </FormControl>
                  </Col>
                </Row>
                <hr style={{ border: "1px solid #000000" }}></hr>
                <Row className="mt-4">
                  <Col md={6}>
                    <FormControl fullWidth>
                      <Label
                        style={{
                          color: fieldErrors?.currentEngagement
                            ? "red"
                            : "inherit",
                        }}
                      >
                        Enter your Current Engagements
                      </Label>
                      <Input
                        id=""
                        name="name"
                        value={currentEngagement}
                        onChange={(e) => {
                          handleCurrentEngagement(e);
                        }}
                        placeholder="Current Engagements"
                        type="text"
                      />
                    </FormControl>
                  </Col>
                  <Col md={6}>
                    <FormControl fullWidth>
                      <Label
                        style={{
                          color: fieldErrors?.googleRating ? "red" : "inherit",
                        }}
                      >
                        Enter Google Rating
                      </Label>
                      <Input
                        id=""
                        name="name"
                        value={googleRating}
                        onChange={(e) => {
                          handleGoogleRating(e);
                        }}
                        placeholder="google rating"
                        type="text"
                      />
                    </FormControl>
                  </Col>
                </Row>
                <hr style={{ border: "1px solid #000000" }}></hr>
                <Row>
                  <Col md={6}>
                    <Label
                      style={{
                        color: fieldErrors.freeSessionMorning
                          ? "red"
                          : "inherit",
                      }}
                    >
                      Can I get the free demo session (morning)
                    </Label>
                    <br></br>
                    <Input
                      name="serviceProvideWithSameAddress"
                      type="radio"
                      value="true"
                      checked={freeSessionMorning === true}
                      onChange={(e) => {
                        handleDemoSession(e);
                      }}
                      style={{ cursor: "pointer" }}
                    />
                    &nbsp;
                    <Label check className="ml-1">
                      Yes
                    </Label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Input
                      name="serviceProvideWithSameAddress"
                      type="radio"
                      value="false"
                      checked={freeSessionMorning === false}
                      onChange={(e) => {
                        handleDemoSession(e);
                      }}
                      style={{ cursor: "pointer" }}
                    />
                    &nbsp;
                    <Label check className="ml-1">
                      No
                    </Label>
                    <br></br>
                    {(freeSessionMorning == true ||
                      freeSessionMorning == "true") && (
                      <>
                        <Label
                          className="mt-3"
                          style={{ color: "black", fontSize: "large" }}
                        >
                          Timing for the demo session.
                        </Label>
                        <div className="mt-3">
                          <Label
                            style={{
                              marginBottom: "0px",
                              color: "black",
                              fontSize: "large",
                            }}
                          >
                            From
                          </Label>
                          <Input
                            value={freeDemoTimingsMorning?.timing?.from || ""}
                            onChange={(e) => {
                              handleDemoSessionUpdate("from", e.target.value);
                            }}
                            type="time"
                            className="mt-2"
                            style={{}}
                          />
                        </div>

                        {/* To Time Field */}
                        <div className="mt-3">
                          <Label
                            style={{
                              marginBottom: "0px",
                              color: "black",
                              fontSize: "large",
                            }}
                          >
                            To
                          </Label>
                          <Input
                            value={freeDemoTimingsMorning?.timing?.to || ""}
                            onChange={(e) => {
                              handleDemoSessionUpdate("to", e.target.value);
                            }}
                            type="time"
                            className="mt-2"
                            style={{
                              borderColor: fieldErrors?.to ? "red" : "inherit",
                            }}
                          />
                        </div>

                        {/* Textbox Below */}
                        <div className="mt-3">
                          <Label
                            style={{
                              marginBottom: "0px",
                              color: "black",
                              fontSize: "large",
                            }}
                          >
                            Additional Notes
                          </Label>
                          <Input
                            value={freeDemoTimingsMorning?.timing?.note || ""}
                            onChange={(e) => {
                              handleDemoSessionUpdate("note", e.target.value);
                            }}
                            placeholder="Enter note"
                            type="text"
                            className="mt-2"
                            style={{
                              borderColor: fieldErrors?.note
                                ? "red"
                                : "inherit",
                            }}
                          />
                        </div>
                      </>
                    )}
                  </Col>
                  <Col md={6}>
                    <Label>Can I get the free demo session (evening)</Label>
                    <br></br>
                    <Input
                      name="eveningSession"
                      type="radio"
                      value="true"
                      checked={freeSessionEvening === true}
                      onChange={(e) => {
                        handleDemoSessionEvening(e);
                      }}
                      style={{ cursor: "pointer" }}
                    />
                    &nbsp;
                    <Label check className="ml-1">
                      Yes
                    </Label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Input
                      name="eveningSession"
                      type="radio"
                      value="false"
                      checked={freeSessionEvening === false}
                      onChange={(e) => {
                        handleDemoSessionEvening(e);
                      }}
                      style={{ cursor: "pointer" }}
                    />
                    &nbsp;
                    <Label check className="ml-1">
                      No
                    </Label>
                    <br></br>
                    {(freeSessionEvening == true ||
                      freeSessionEvening == "true") && (
                      <>
                        <Label
                          className="mt-3"
                          style={{ color: "black", fontSize: "large" }}
                        >
                          Timing for the demo session.
                        </Label>
                        <div className="mt-3">
                          <Label
                            style={{
                              marginBottom: "0px",
                              color: "black",
                              fontSize: "large",
                            }}
                          >
                            From
                          </Label>
                          <Input
                            value={freeDemoTimingsEvening?.timing?.from || ""}
                            onChange={(e) => {
                              handleDemoSessionUpdateEvening(
                                "from",
                                e.target.value
                              );
                            }}
                            type="time"
                            className="mt-2"
                          />
                        </div>

                        {/* To Time Field */}
                        <div className="mt-3">
                          <Label
                            style={{
                              marginBottom: "0px",
                              color: "black",
                              fontSize: "large",
                            }}
                          >
                            To
                          </Label>
                          <Input
                            value={freeDemoTimingsEvening?.timing?.to || ""}
                            onChange={(e) => {
                              handleDemoSessionUpdateEvening(
                                "to",
                                e.target.value
                              );
                            }}
                            type="time"
                            className="mt-2"
                          />
                        </div>

                        {/* Textbox Below */}
                        <div className="mt-3">
                          <Label
                            style={{
                              marginBottom: "0px",
                              color: "black",
                              fontSize: "large",
                            }}
                          >
                            Additional Notes
                          </Label>
                          <Input
                            value={freeDemoTimingsEvening?.timing?.note || ""}
                            onChange={(e) => {
                              handleDemoSessionUpdateEvening(
                                "note",
                                e.target.value
                              );
                            }}
                            placeholder="Enter note"
                            type="text"
                            className="mt-2"
                          />
                        </div>
                      </>
                    )}
                  </Col>
                </Row>
                <hr style={{ border: "1px solid #000000" }}></hr>
                <Grid container spacing={2} mb={2}>
                  <Grid item>
                    <Label>
                      Based on your past experiences, rate your own performance
                      in terms of positive outcome for your clients, on a scale
                      of 1 to 5 (5= Outstanding, 4= Excellent, 3= Good 2= Fair
                      1= Not Sure, 0= No experience)
                    </Label>
                  </Grid>
                </Grid>

                <Row>
                  <Col lg={5}></Col>
                  <Col lg={7}>
                    <div style={{ display: "flex", gap: "85px" }}>
                      <div>0</div>
                      <div>1</div>
                      <div>2</div>
                      <div>3</div>
                      <div>4</div>
                      <div>5</div>
                    </div>
                  </Col>
                </Row>
                <FormControl style={{ width: "-webkit-fill-available" }}>
                  <Row>
                    <Col lg={5}>
                      <Label
                        id="demo-radio-buttons-group-label"
                        style={{
                          color: fieldErrors.childrenFromThreeToThirteenYearsAge
                            ? "red"
                            : "inherit",
                        }}
                      >
                        1. Children (3-13 years)
                      </Label>
                    </Col>
                    <Col lg={7}>
                      <div style={{ display: "flex", gap: "75px" }}>
                        <Input
                          name="3-13"
                          value="0"
                          checked={childrenFromThreeToThirteenYearsAge === "0"}
                          control={<Radio />}
                          onChange={(e) => {
                            setChildrenFromThreeToThirteenYearsAge("0");
                            setFieldErrors((prevErrors) => ({
                              ...prevErrors,
                              childrenFromThreeToThirteenYearsAge: false,
                            }));
                          }}
                          type="radio"
                        />
                        <Input
                          name="3-13"
                          value="1"
                          checked={childrenFromThreeToThirteenYearsAge === "1"}
                          control={<Radio />}
                          onChange={(e) => {
                            setChildrenFromThreeToThirteenYearsAge("1");
                            setFieldErrors((prevErrors) => ({
                              ...prevErrors,
                              childrenFromThreeToThirteenYearsAge: false,
                            }));
                          }}
                          type="radio"
                        />
                        <Input
                          name="3-13"
                          value="2"
                          checked={childrenFromThreeToThirteenYearsAge === "2"}
                          control={<Radio />}
                          onChange={(e) => {
                            setChildrenFromThreeToThirteenYearsAge("2");
                            setFieldErrors((prevErrors) => ({
                              ...prevErrors,
                              childrenFromThreeToThirteenYearsAge: false,
                            }));
                          }}
                          type="radio"
                        />
                        <Input
                          name="3-13"
                          value="3"
                          checked={childrenFromThreeToThirteenYearsAge === "3"}
                          control={<Radio />}
                          onChange={(e) => {
                            setChildrenFromThreeToThirteenYearsAge("3");
                            setFieldErrors((prevErrors) => ({
                              ...prevErrors,
                              childrenFromThreeToThirteenYearsAge: false,
                            }));
                          }}
                          type="radio"
                        />
                        <Input
                          name="3-13"
                          value="4"
                          checked={childrenFromThreeToThirteenYearsAge === "4"}
                          control={<Radio />}
                          onChange={(e) => {
                            setChildrenFromThreeToThirteenYearsAge("4");
                            setFieldErrors((prevErrors) => ({
                              ...prevErrors,
                              childrenFromThreeToThirteenYearsAge: false,
                            }));
                          }}
                          type="radio"
                        />
                        <Input
                          name="3-13"
                          value="5"
                          checked={childrenFromThreeToThirteenYearsAge === "5"}
                          control={<Radio />}
                          onChange={(e) => {
                            setChildrenFromThreeToThirteenYearsAge("5");
                            setFieldErrors((prevErrors) => ({
                              ...prevErrors,
                              childrenFromThreeToThirteenYearsAge: false,
                            }));
                          }}
                          type="radio"
                        />
                      </div>
                    </Col>
                  </Row>
                </FormControl>
                <FormControl style={{ width: "-webkit-fill-available" }}>
                  <Row>
                    <Col lg={5}>
                      <Label
                        id="demo-radio-buttons-group-label"
                        style={{
                          color:
                            fieldErrors?.childrenFromThirteenToTwentyOneYearsAge
                              ? "red"
                              : "inherit",
                        }}
                      >
                        2. Children (13-21 years)
                      </Label>
                    </Col>
                    <Col lg={7}>
                      <div style={{ display: "flex", gap: "75px" }}>
                        <Input
                          name="13-21"
                          value="0"
                          checked={
                            childrenFromThirteenToTwentyOneYearsAge === "0"
                          }
                          control={<Radio />}
                          onChange={(e) => {
                            setChildrenFromThirteenToTwentyOneYearsAge("0");
                            setFieldErrors((prevErrors) => ({
                              ...prevErrors,
                              childrenFromThirteenToTwentyOneYearsAge: false,
                            }));
                          }}
                          type="radio"
                        />
                        <Input
                          name="13-21"
                          value="1"
                          checked={
                            childrenFromThirteenToTwentyOneYearsAge === "1"
                          }
                          control={<Radio />}
                          onChange={(e) => {
                            setChildrenFromThirteenToTwentyOneYearsAge("1");
                            setFieldErrors((prevErrors) => ({
                              ...prevErrors,
                              childrenFromThirteenToTwentyOneYearsAge: false,
                            }));
                          }}
                          type="radio"
                        />
                        <Input
                          name="13-21"
                          value="2"
                          checked={
                            childrenFromThirteenToTwentyOneYearsAge === "2"
                          }
                          control={<Radio />}
                          onChange={(e) => {
                            setChildrenFromThirteenToTwentyOneYearsAge("2");
                            setFieldErrors((prevErrors) => ({
                              ...prevErrors,
                              childrenFromThirteenToTwentyOneYearsAge: false,
                            }));
                          }}
                          type="radio"
                        />
                        <Input
                          name="13-21"
                          value="3"
                          checked={
                            childrenFromThirteenToTwentyOneYearsAge === "3"
                          }
                          control={<Radio />}
                          onChange={(e) => {
                            setChildrenFromThirteenToTwentyOneYearsAge("3");
                            setFieldErrors((prevErrors) => ({
                              ...prevErrors,
                              childrenFromThirteenToTwentyOneYearsAge: false,
                            }));
                          }}
                          type="radio"
                        />
                        <Input
                          name="13-21"
                          value="4"
                          checked={
                            childrenFromThirteenToTwentyOneYearsAge === "4"
                          }
                          control={<Radio />}
                          onChange={(e) => {
                            setChildrenFromThirteenToTwentyOneYearsAge("4");
                            setFieldErrors((prevErrors) => ({
                              ...prevErrors,
                              childrenFromThirteenToTwentyOneYearsAge: false,
                            }));
                          }}
                          type="radio"
                        />
                        <Input
                          name="13-21"
                          value="5"
                          checked={
                            childrenFromThirteenToTwentyOneYearsAge === "5"
                          }
                          control={<Radio />}
                          onChange={(e) => {
                            setChildrenFromThirteenToTwentyOneYearsAge("5");
                            setFieldErrors((prevErrors) => ({
                              ...prevErrors,
                              childrenFromThirteenToTwentyOneYearsAge: false,
                            }));
                          }}
                          type="radio"
                        />
                      </div>
                    </Col>
                  </Row>
                </FormControl>
                <FormControl style={{ width: "-webkit-fill-available" }}>
                  <Row>
                    <Col lg={5}>
                      <Label
                        id="demo-radio-buttons-group-label"
                        style={{
                          color: fieldErrors?.adultMaleFromTwentyOneYearsAge
                            ? "red"
                            : "inherit",
                        }}
                      >
                        4. Adults (Age &gt;21 years)
                      </Label>
                    </Col>
                    <Col lg={7}>
                      <div style={{ display: "flex", gap: "75px" }}>
                        <Input
                          name=">21"
                          value="0"
                          checked={adultMaleFromTwentyOneYearsAge === "0"}
                          control={<Radio />}
                          onChange={(e) => {
                            setAdultMaleFromTwentyOneYearsAge("0");
                            setFieldErrors((prevErrors) => ({
                              ...prevErrors,
                              adultMaleFromTwentyOneYearsAge: false,
                            }));
                          }}
                          type="radio"
                        />
                        <Input
                          name=">21"
                          value="1"
                          checked={adultMaleFromTwentyOneYearsAge === "1"}
                          control={<Radio />}
                          onChange={(e) => {
                            setAdultMaleFromTwentyOneYearsAge("1");
                            setFieldErrors((prevErrors) => ({
                              ...prevErrors,
                              adultMaleFromTwentyOneYearsAge: false,
                            }));
                          }}
                          type="radio"
                        />
                        <Input
                          name=">21"
                          value="2"
                          checked={adultMaleFromTwentyOneYearsAge === "2"}
                          control={<Radio />}
                          onChange={(e) => {
                            setAdultMaleFromTwentyOneYearsAge("2");
                            setFieldErrors((prevErrors) => ({
                              ...prevErrors,
                              adultMaleFromTwentyOneYearsAge: false,
                            }));
                          }}
                          type="radio"
                        />
                        <Input
                          name=">21"
                          value="3"
                          checked={adultMaleFromTwentyOneYearsAge === "3"}
                          control={<Radio />}
                          onChange={(e) => {
                            setAdultMaleFromTwentyOneYearsAge("3");
                            setFieldErrors((prevErrors) => ({
                              ...prevErrors,
                              adultMaleFromTwentyOneYearsAge: false,
                            }));
                          }}
                          type="radio"
                        />
                        <Input
                          name=">21"
                          value="4"
                          checked={adultMaleFromTwentyOneYearsAge === "4"}
                          control={<Radio />}
                          onChange={(e) => {
                            setAdultMaleFromTwentyOneYearsAge("4");
                            setFieldErrors((prevErrors) => ({
                              ...prevErrors,
                              adultMaleFromTwentyOneYearsAge: false,
                            }));
                          }}
                          type="radio"
                        />
                        <Input
                          name=">21"
                          value="5"
                          checked={adultMaleFromTwentyOneYearsAge === "5"}
                          control={<Radio />}
                          onChange={(e) => {
                            setAdultMaleFromTwentyOneYearsAge("5");
                            setFieldErrors((prevErrors) => ({
                              ...prevErrors,
                              adultMaleFromTwentyOneYearsAge: false,
                            }));
                          }}
                          type="radio"
                        />
                      </div>
                    </Col>
                  </Row>
                </FormControl>

                <hr style={{ border: "1px solid #000000" }}></hr>
                <Row>
                  <Col>
                    <span>
                      Please attach the most outstanding comments/testimonials
                      you have received from your clients in the past for the
                      services you have provided to them.
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <div
                      style={{
                        marginTop: "10px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        variant="contained"
                        component="label"
                        color="primary"
                        style={{ color: "white", maxWidth: "fit-content" }}
                      >
                        {testimonialfileUploading ? (
                          <CircularProgress
                            size={24}
                            color="inherit"
                          ></CircularProgress>
                        ) : (
                          "Attach Testimonials"
                        )}
                        <input
                          type="file"
                          multiple
                          hidden
                          onChange={(event) =>
                            handleTrainerPhoto(event, "TESTIMONIAL")
                          }
                        />
                      </Button>
                      {outstandingTestimonialsFileList.length > 0 && (
                        <div>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => setTestimonialModalOpen(true)}
                            style={{ maxWidth: "fit-content" }}
                          >
                            View Testimonials
                          </Button>
                          <ModalForFileView
                            open={testimonialModalOpen}
                            onClose={() => setTestimonialModalOpen(false)}
                            data={outstandingTestimonialsFileList}
                            partnerType="TRAINER"
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                  {selectedTestimonialFile.length > 0 && (
                    <Box
                      sx={{
                        mt: 2,
                      }}
                    >
                      <Typography variant="h6" gutterBottom>
                        Selected Files
                      </Typography>

                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "10px",
                        }}
                      >
                        {selectedTestimonialFile.map((file, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              padding: "10px",
                              border: "1px solid #ddd",
                              borderRadius: "5px",
                              backgroundColor: "#f9f9f9",
                              // maxWidth: "300px",
                              width: "100%",
                            }}
                          >
                            {/* File Name */}
                            <Typography
                              variant="body2"
                              style={{ wordBreak: "break-all" }}
                            >
                              {file.name}
                            </Typography>
                          </div>
                        ))}
                      </div>
                    </Box>
                  )}
                </Row>
                {selectedTestimonialFile.length === 0 &&
                  outstandingTestimonialsFileList.length === 0 && (
                    <div>
                      <b>Testimonials: </b>{" "}
                      <span>No Testimonials available.</span>
                    </div>
                  )}
                {/* {testimonials?.length < 3 ? (
          <Row>
            <Col md={12}>
              <Label>
                If you would rather mention the testimonials, then do so in the
                space provided below. Just cut and paste up to three (3)
                testimonials. Click on “Add testimonial” below.
              </Label>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col md={12}>
              <br />
              <Label>
                <b>Testimonials : </b>
              </Label>
            </Col>
          </Row>
        )}
        <Row>
          <Col md={4}>
            {testimonials?.length < 3 && (
              <Button
                type="button"
                className="btn btn-secondary"
                onClick={addTestimonial}
              >
                Add Testimonial
              </Button>
            )}
          </Col>
          <Row>
            {}
            {testimonials?.map((testimonial, index) => (
              <Col md={12} key={index}>
                <Input
                  id={`testimonial-${index}`}
                  name={`testimonial-${index}`}
                  value={testimonial}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                  placeholder="Enter Testimonial"
                  type="textarea"
                  className="mt-2"
                  style={{
                    borderColor: "lightgrey",
                  }}
                />
              </Col>
            ))}
          </Row>
        </Row> */}
                <hr style={{ border: "1px solid #000000" }}></hr>
                <h4>Reference</h4>
                <hr
                  style={{ width: "10%", marginTop: "3px", height: "2px" }}
                ></hr>
                <Row>
                  <Col>
                    <span>
                      Would you be open to sharing 3 references of your past or
                      current clients who can give us honest feedback of their
                      experiences?
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Input
                        name="sharingReferences"
                        type="radio"
                        value="true"
                        checked={sharingReferences === true}
                        onChange={handlesharingReferences}
                        style={{ cursor: "pointer" }}
                      />
                      &nbsp;
                      <Label check className="ml-1">
                        Yes
                      </Label>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <Input
                        name="serviceProvideWithSameAddress"
                        type="radio"
                        value="false"
                        checked={sharingReferences === false}
                        onChange={handlesharingReferences}
                        style={{ cursor: "pointer" }}
                      />
                      &nbsp;
                      <Label check className="ml-1">
                        No
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
                <br></br>
                {(showReferences || sharingReferences) && (
                  <>
                    <Label>
                      Please provide below their contact information and let
                      them know that will be receiving a call from Living Scope
                      Health Marketing team member. It will be a quick
                      conversation only and we won’t take much of their time.
                    </Label>
                    <h5>Reference # 1</h5>
                    <Row style={{ marginTop: "20px" }}>
                      <Col md={4}>
                        <Label style={{ marginBottom: "0px" }}>Name : </Label>
                        <Input
                          id=""
                          name="name"
                          value={referenceDetail?.reference1?.name}
                          onChange={(e) => {
                            handleReferenceUpdate(
                              "reference1",
                              "name",
                              e.target.value
                            );
                          }}
                          placeholder="Enter Name"
                          type="text"
                          className="mt-2"
                          style={{
                            borderColor: fieldErrors?.reference1Name
                              ? "red"
                              : "inherit",
                          }}
                        />
                      </Col>
                      <Col md={4}>
                        <Label style={{ marginBottom: "0px" }}>Mobile : </Label>
                        <Input
                          id=""
                          name="name"
                          value={referenceDetail?.reference1?.mobile}
                          onChange={(e) => {
                            handleReferenceUpdate(
                              "reference1",
                              "mobile",
                              e.target.value
                            );
                          }}
                          placeholder="Enter Mobile"
                          type="number"
                          maxLength={10}
                          className="mt-2"
                          style={{
                            borderColor: fieldErrors?.reference1Mobile
                              ? "red"
                              : "inherit",
                          }}
                        />
                      </Col>
                    </Row>
                    <hr style={{ width: "80%", height: "3px" }}></hr>
                    <h5>Reference # 2</h5>
                    <Row style={{ marginTop: "20px" }}>
                      <Col md={4}>
                        <Label style={{ marginBottom: "0px" }}>Name : </Label>
                        <Input
                          id=""
                          name="name"
                          value={referenceDetail?.reference2?.name}
                          onChange={(e) => {
                            handleReferenceUpdate(
                              "reference2",
                              "name",
                              e.target.value
                            );
                          }}
                          placeholder="Enter Name"
                          type="text"
                          className="mt-2"
                        />
                      </Col>
                      <Col md={4}>
                        <Label style={{ marginBottom: "0px" }}>Mobile : </Label>
                        <Input
                          id=""
                          name="name"
                          value={referenceDetail?.reference2?.mobile}
                          onChange={(e) => {
                            handleReferenceUpdate(
                              "reference2",
                              "mobile",
                              e.target.value
                            );
                          }}
                          type="number"
                          maxLength={10}
                          placeholder="Enter Mobile"
                          className="mt-2"
                        />
                      </Col>
                    </Row>
                    <hr style={{ width: "80%", height: "3px" }}></hr>
                    <h5>Reference # 3</h5>
                    <Row style={{ marginTop: "20px" }}>
                      <Col md={4}>
                        <Label style={{ marginBottom: "0px" }}>Name : </Label>
                        <Input
                          id=""
                          name="name"
                          value={referenceDetail?.reference3?.name}
                          onChange={(e) => {
                            handleReferenceUpdate(
                              "reference3",
                              "name",
                              e.target.value
                            );
                          }}
                          placeholder="Enter Name"
                          type="text"
                          className="mt-2"
                        />
                      </Col>
                      <Col md={4}>
                        <Label style={{ marginBottom: "0px" }}>Mobile : </Label>
                        <Input
                          id=""
                          name="name"
                          value={referenceDetail?.reference3?.mobile}
                          onChange={(e) => {
                            handleReferenceUpdate(
                              "reference3",
                              "mobile",
                              e.target.value
                            );
                          }}
                          placeholder="Enter Mobile"
                          type="number"
                          maxLength={10}
                          className="mt-2"
                        />
                      </Col>
                    </Row>
                  </>
                )}
                <hr style={{ border: "1px solid #000000" }}></hr>
                <Row>
                  <Col>
                    <Label>
                      Please attach below copies of your credentials like
                      Diplomas, course certificates, awards, newspaper cuttings
                      etc. that adds to your credentials as a Trainer.
                    </Label>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <span>
                      <b>Attach Trainer's photo</b>
                    </span>

                    <div
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      <Button
                        variant="contained"
                        component="label"
                        fullWidth
                        color="primary"
                        style={{ color: "white" }}
                      >
                        {trainerImagefileUploading ? (
                          <CircularProgress
                            size={24}
                            color="inherit"
                          ></CircularProgress>
                        ) : (
                          "Attach Photos"
                        )}
                        <input
                          type="file"
                          multiple
                          hidden
                          onChange={(event) =>
                            handleTrainerPhoto(event, "TRAINER_IMAGE")
                          }
                        />
                      </Button>
                    </div>
                    <div
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      {trainerImageList.length > 0 && (
                        <div>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => settrainerImageModalOpen(true)}
                            fullWidth
                          >
                            View Photos
                          </Button>
                          <ModalForFileView
                            open={trainerImageModalOpen}
                            onClose={() => settrainerImageModalOpen(false)}
                            data={trainerImageList}
                            partnerType="TRAINER"
                          />
                        </div>
                      )}
                    </div>
                    {selectedtrainerImageFile.length > 0 && (
                      <Box
                        sx={{
                          mt: 2,
                        }}
                      >
                        <Typography variant="h6" gutterBottom>
                          Selected Files
                        </Typography>

                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "10px",
                          }}
                        >
                          {selectedtrainerImageFile.map((file, index) => (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                padding: "10px",
                                border: "1px solid #ddd",
                                borderRadius: "5px",
                                backgroundColor: "#f9f9f9",
                                // maxWidth: "300px",
                                width: "100%",
                              }}
                            >
                              {/* File Name */}
                              <Typography
                                variant="body2"
                                style={{ wordBreak: "break-all" }}
                              >
                                {file.name}
                              </Typography>
                            </div>
                          ))}
                        </div>
                      </Box>
                    )}
                    {selectedtrainerImageFile.length === 0 &&
                      trainerImageList.length === 0 && (
                        <div className="mt-2">
                          <b>Photo: </b>

                          <span>No Photos available.</span>
                        </div>
                      )}
                  </Col>
                  <Col md={4}>
                    <span>
                      <b> Attach Awards / Certificates / Recognitions</b>
                    </span>
                    <div
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      <Button
                        variant="contained"
                        component="label"
                        color="primary"
                        fullWidth
                        style={{ color: "white" }}
                      >
                        {certificationsfileUploading ? (
                          <CircularProgress
                            size={24}
                            color="inherit"
                          ></CircularProgress>
                        ) : (
                          "Attach Certificates"
                        )}
                        <input
                          type="file"
                          multiple
                          hidden
                          onChange={(event) =>
                            handleTrainerPhoto(event, "CERTIFICATION")
                          }
                        />
                      </Button>
                    </div>
                    <div
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      {certificationsFileList.length > 0 && (
                        <div>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => setcertificationsModalOpen(true)}
                            fullWidth
                          >
                            View Certificates
                          </Button>
                          <ModalForFileView
                            open={certificationsModalOpen}
                            onClose={() => setcertificationsModalOpen(false)}
                            data={certificationsFileList}
                            partnerType="TRAINER"
                          />
                        </div>
                      )}
                    </div>
                    {selectedcertificationsFile.length > 0 && (
                      <Box
                        sx={{
                          mt: 2,
                        }}
                      >
                        <Typography variant="h6" gutterBottom>
                          Selected Files
                        </Typography>

                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "10px",
                          }}
                        >
                          {selectedcertificationsFile.map((file, index) => (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                padding: "10px",
                                border: "1px solid #ddd",
                                borderRadius: "5px",
                                backgroundColor: "#f9f9f9",
                                // maxWidth: "300px",
                                width: "100%",
                              }}
                            >
                              {/* File Name */}
                              <Typography
                                variant="body2"
                                style={{ wordBreak: "break-all" }}
                              >
                                {file.name}
                              </Typography>
                            </div>
                          ))}
                        </div>
                      </Box>
                    )}
                    {selectedcertificationsFile.length === 0 &&
                      certificationsFileList.length === 0 && (
                        <div className="mt-2">
                          <b>Certificates: </b>

                          <span>No Certificates available.</span>
                        </div>
                      )}
                  </Col>
                  <Col md={4}>
                    <span>
                      <b>Attach some online/offline classes photos/videos</b>
                    </span>
                    <div
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      <Button
                        variant="contained"
                        component="label"
                        color="primary"
                        fullWidth
                        style={{ color: "white" }}
                      >
                        {classAttachmentsfileUploading ? (
                          <CircularProgress
                            size={24}
                            color="inherit"
                          ></CircularProgress>
                        ) : (
                          "Attach Photos/Videos"
                        )}
                        <input
                          type="file"
                          multiple
                          hidden
                          onChange={(event) =>
                            handleTrainerPhoto(event, "CLASS_FILES")
                          }
                        />
                      </Button>
                    </div>
                    <div
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      {classAttachmentsFileList.length > 0 && (
                        <div>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => setclassAttachmentsModalOpen(true)}
                            fullWidth
                          >
                            View Photos/Videos
                          </Button>
                          <ModalForFileView
                            open={classAttachmentsModalOpen}
                            onClose={() => setclassAttachmentsModalOpen(false)}
                            data={classAttachmentsFileList}
                            partnerType="TRAINER"
                          />
                        </div>
                      )}
                    </div>
                    {selectedclassAttachmentsFile.length > 0 && (
                      <Box
                        sx={{
                          mt: 2,
                        }}
                      >
                        <Typography variant="h6" gutterBottom>
                          Selected Files
                        </Typography>

                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "10px",
                          }}
                        >
                          {selectedclassAttachmentsFile.map((file, index) => (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                padding: "10px",
                                border: "1px solid #ddd",
                                borderRadius: "5px",
                                backgroundColor: "#f9f9f9",
                                // maxWidth: "300px",
                                width: "100%",
                              }}
                            >
                              {/* File Name */}
                              <Typography
                                variant="body2"
                                style={{ wordBreak: "break-all" }}
                              >
                                {file.name}
                              </Typography>
                            </div>
                          ))}
                        </div>
                      </Box>
                    )}
                    {selectedclassAttachmentsFile.length === 0 &&
                      classAttachmentsFileList.length === 0 && (
                        <div className="mt-2">
                          <b>Class Photos: </b>

                          <span>No Class Photos/Videos available.</span>
                        </div>
                      )}
                  </Col>
                </Row>
              </Form>
              <Button
                onClick={handleNext}
                style={{
                  marginTop: "4%",
                  width: "15%",
                  backgroundColor: "rgb(83, 83, 233)",
                  float: "inline-end",
                  color: "white",
                }}
              >
                Next
              </Button>
            </>
          )}

          {LifestyleStep == 1 && (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddClass}
                style={{ marginBottom: "10px" }}
              >
                Add Class
              </Button>
              <TableContainer>
                {classes.map((row, index) => (
                  <>
                    <Table
                      style={{ border: "1px solid black", borderRadius: "5px" }}
                    >
                      <TableHead
                        style={{
                          backgroundColor: "lightgray",
                          border: "2px solid black",
                        }}
                      >
                        <TableRow>
                          <TableCell style={{ border: "1px solid black" }}>
                            Class Name
                          </TableCell>
                          <TableCell style={{ border: "1px solid black" }}>
                            Mode
                          </TableCell>
                          <TableCell style={{ border: "1px solid black" }}>
                            Session
                          </TableCell>
                          <TableCell style={{ border: "1px solid black" }}>
                            Gender
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key={index}>
                          <TableCell
                            style={{ borderBottom: "1px solid black" }}
                          >
                            <TextField
                              label="Class Name"
                              value={row.className}
                              onChange={(e) =>
                                handleInputChange(e, index, "className")
                              }
                              fullWidth
                              InputProps={{
                                style: {
                                  color: "#000",
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: "#888",
                                },
                              }}
                            />
                          </TableCell>
                          <TableCell
                            style={{ borderBottom: "1px solid black" }}
                          >
                            <Select
                              value={row.mode}
                              onChange={(e) =>
                                handleInputChange(e, index, "mode")
                              }
                              fullWidth
                            >
                              <MenuItem value="Online">Online</MenuItem>
                              <MenuItem value="Offline">Offline</MenuItem>
                            </Select>
                          </TableCell>
                          <TableCell
                            style={{ borderBottom: "1px solid black" }}
                          >
                            <Select
                              value={row.session}
                              onChange={(e) =>
                                handleInputChange(e, index, "session")
                              }
                              fullWidth
                            >
                              <MenuItem value="Morning">Morning</MenuItem>
                              <MenuItem value="Afternoon">Afternoon</MenuItem>
                              <MenuItem value="Evening">Evening</MenuItem>
                            </Select>
                          </TableCell>
                          <TableCell
                            style={{ borderBottom: "1px solid black" }}
                          >
                            <Select
                              value={row.gender}
                              onChange={(e) =>
                                handleInputChange(e, index, "gender")
                              }
                              fullWidth
                            >
                              <MenuItem value="male">Male</MenuItem>
                              <MenuItem value="female">Female</MenuItem>
                              <MenuItem value="both">Both</MenuItem>
                            </Select>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                      <TableHead>
                        <TableCell style={{ border: "1px solid black" }}>
                          Week Days
                        </TableCell>
                        <TableCell style={{ border: "1px solid black" }}>
                          Timing From
                        </TableCell>
                        <TableCell style={{ border: "1px solid black" }}>
                          Timing To
                        </TableCell>
                        <TableCell style={{ border: "1px solid black" }}>
                          Age Group
                        </TableCell>
                      </TableHead>
                      <TableBody>
                        <TableRow key={index}>
                          <TableCell
                            style={{ borderBottom: "1px solid black" }}
                          >
                            <FormControl fullWidth>
                              <MultiSelect
                                id="weekdays"
                                value={classes[index].weekdays}
                                onChange={(e) => handleSelectChange(e, index)}
                                options={weekdays}
                                optionLabel="name"
                                display="chip"
                                style={{
                                  padding: "15px",
                                  backgroundColor: "#fff",
                                  borderRadius: 4,
                                  border: "1px solid rgba(0, 0, 0, 0.23)",
                                }}
                                panelStyle={{
                                  backgroundColor: "#fff",
                                  border: "1px solid black",
                                  borderRadius: "5px",
                                  paddingLeft: "8px",
                                }}
                                itemStyle={{
                                  backgroundColor: "#fff",
                                  paddingLeft: "10px",
                                }}
                                itemClassName="multiselect-item"
                              />
                            </FormControl>
                          </TableCell>
                          <TableCell
                            style={{ borderBottom: "1px solid black" }}
                          >
                            <TextField
                              type="time"
                              value={row.timingFrom}
                              onChange={(e) =>
                                handleInputChange(e, index, "timingFrom")
                              }
                              fullWidth
                              InputProps={{
                                style: {
                                  color: "#000",
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: "#888",
                                },
                              }}
                              // required
                            />
                          </TableCell>
                          <TableCell
                            style={{ borderBottom: "1px solid black" }}
                          >
                            <TextField
                              type="time"
                              value={row.timingTo}
                              onChange={(e) =>
                                handleInputChange(e, index, "timingTo")
                              }
                              fullWidth
                              InputProps={{
                                style: {
                                  color: "#000",
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: "#888",
                                },
                              }}
                              // required
                            />
                          </TableCell>
                          <TableCell
                            style={{ borderBottom: "1px solid black" }}
                          >
                            <Row>
                              <Col>
                                <div className="age-group-label">3-13</div>
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        value="3-13"
                                        checked={isChecked(index, "3-13")}
                                        sx={{
                                          color: fieldErrors?.CVD?.ageGroup
                                            ? "red"
                                            : "default",
                                          "&.Mui-checked": {
                                            color: fieldErrors?.CVD?.ageGroup
                                              ? "red"
                                              : "blue", // Ensure checked state is blue
                                          },
                                        }}
                                      />
                                    }
                                    label=""
                                    onChange={(e) =>
                                      handleAgeGroupChange("CVD", "3-13", index)
                                    }
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <div className="age-group-label">13-21</div>
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        value="13-21"
                                        checked={isChecked(index, "13-21")}
                                        sx={{
                                          color: fieldErrors?.CVD?.ageGroup
                                            ? "red"
                                            : "default",
                                          "&.Mui-checked": {
                                            color: fieldErrors?.CVD?.ageGroup
                                              ? "red"
                                              : "blue", // Ensure checked state is blue
                                          },
                                        }}
                                      />
                                    }
                                    label=""
                                    onChange={(e) =>
                                      handleAgeGroupChange(
                                        "CVD",
                                        "13-21",
                                        index
                                      )
                                    }
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="age-group-label">&gt;21</div>
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        value=">21"
                                        checked={isChecked(index, ">21")}
                                        sx={{
                                          color: fieldErrors?.CVD?.ageGroup
                                            ? "red"
                                            : "default",
                                          "&.Mui-checked": {
                                            color: fieldErrors?.CVD?.ageGroup
                                              ? "red"
                                              : "blue", // Ensure checked state is blue
                                          },
                                        }}
                                      />
                                    }
                                    label=""
                                    onChange={(e) =>
                                      handleAgeGroupChange("CVD", ">21", index)
                                    }
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              navigate("/trainer-manage-schedule", {
                                state: { rowData: row },
                              })
                            }
                            className="m-2"
                          >
                            Manage Schedule
                          </Button>
                        </TableRow>
                      </TableBody>
                    </Table>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleClassDelete(index)}
                      style={{
                        marginBottom: "5%",
                        marginTop: "1%",
                        float: "inline-end",
                      }}
                    >
                      Delete
                    </Button>
                  </>
                ))}
              </TableContainer>
              <Button
                onClick={handleLifestyleStepBack}
                style={{
                  marginTop: "4%",
                  width: "15%",
                  backgroundColor: "rgb(83, 83, 233)",
                  color: "white",
                }}
              >
                Back
              </Button>
              <Button
                onClick={handleSubmit}
                style={{
                  marginTop: "4%",
                  width: "15%",
                  backgroundColor: "rgb(83, 83, 233)",
                  float: "inline-end",
                  color: "white",
                }}
              >
                Submit
              </Button>
            </>
          )}
        </>
      )}
    </>
  );
};

export default DieticianGeneralInfo;
