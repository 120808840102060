import React, { useEffect, useState } from "react";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import "./style.css";
import { Box, Typography } from "@mui/material";
const DieticianInvoice = () => {
  const rows = [
    {
      orderId: "LSH12DFD",
      orderDate: "27-12-2024",
      totalAmount: 2000,
    },
    {
      orderId: "LSH12DTH",
      orderDate: "22-1-2025",
      totalAmount: 2050,
    },
  ];

  // Calculate total amount
  const totalAmount = rows.reduce((sum, row) => sum + row.totalAmount, 0);

  // Function to get upcoming Friday's date
  const getUpcomingFriday = () => {
    const today = new Date();
    const dayOfWeek = today.getDay(); // 0 (Sunday) to 6 (Saturday)
    const daysUntilFriday = (5 - dayOfWeek + 7) % 7 || 7; // If today is Friday, move to next Friday
    const nextFriday = new Date(today);
    nextFriday.setDate(today.getDate() + daysUntilFriday);
    return nextFriday.toLocaleDateString("en-GB"); // Format as dd/mm/yyyy
  };
  return (
    <>
      <TableContainer>
        <Table style={{ border: "1px solid lightgrey", marginTop: "20px" }}>
          <TableHead>
            <TableRow>
              <TableCell
                className="tableCell"
                style={{ backgroundColor: "rgb(83, 83, 233)" }}
              >
                LSH Order ID
              </TableCell>
              <TableCell
                className="tableCell"
                style={{ backgroundColor: "rgb(83, 83, 233)" }}
              >
                Date
              </TableCell>
              <TableCell
                className="tableCell"
                style={{ backgroundColor: "rgb(83, 83, 233)" }}
              >
                Total Amount
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell style={{ border: "1px solid lightgrey" }}>
                  {row.orderId}
                </TableCell>
                <TableCell style={{ border: "1px solid lightgrey" }}>
                  {row.orderDate}
                </TableCell>
                <TableCell style={{ border: "1px solid lightgrey" }}>
                  {row.totalAmount}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Total Amount & Payment Date Box */}
      <Box
        mt={3}
        p={3}
        sx={{
          backgroundColor: "#f0f8ff",
          borderRadius: "10px",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          textAlign: "center",
        }}
      >
        <Typography variant="h6" fontWeight="bold" color="green">
          ₹{totalAmount} will be credited to your account on Friday,{" "}
          {getUpcomingFriday()}.
        </Typography>
      </Box>
    </>
  );
};
export default DieticianInvoice;
